import React from "react";

function Avatar(props) {
  const { name } = props;
  const firstLetter = name ? name.charAt(0).toUpperCase() : "U";
  let margin = props.margin || "";
  const size = props.size ?? "w-8 h-8";
  const textSize = props.textSize ?? "text-sm";
  const isMultiple = props.isMultiple ?? false;
  margin = isMultiple ? "mr-[-10px]" : margin;
  return (
    <span
      className={`inline-flex items-center justify-center rounded-full bg-indigo-800 ${margin} ${size}`}
    >
      <span className={`font-medium leading-none text-white ${textSize}`}>
        {firstLetter}
      </span>
    </span>
  );
}

export default Avatar;
