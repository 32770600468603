import { db, firebase } from "../firebase";

export function createCollection(workspaceId, notespaceId, userInfo, obj) {
  //Create workspaceStream in WorkspaceService
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const { title, description } = obj;
  const streamRefs = obj.streamRefs ?? [];
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("collections")
    .add({
      title,
      description,
      workspaceId,
      notespaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      streamRefs,
    });
}
