import { Bars2Icon } from "@heroicons/react/20/solid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function ModalSreamPositionsDrag(props) {
  const {
    orderedRefs,
    streams,
    handleUpdate,
    handleSelect,
    streamOrderRefs,
    title,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const handleUpdateStoryOrder = (param) => {
    const orderedStreamRefs = orderedRefs;
    const oldIndex = param.source.index;
    const newIndex = param.destination.index;
    const moved = orderedStreamRefs.splice(oldIndex, 1);
    orderedStreamRefs.splice(newIndex, 0, moved[0]);
    const validStoryRefs = orderedStreamRefs.filter(
      (item) => streams.find((stream) => stream.id === item) !== undefined
    );
    const obj = { prop: "streamOrderRefs", value: validStoryRefs };
    handleUpdate(obj);
  };

  let orderedStreams = streams;
  if (streamOrderRefs) {
    orderedStreams = orderedRefs.map((ref) =>
      streams.find((item) => item.id === ref)
    );
    orderedStreams = orderedStreams.filter((item) => item !== undefined);
  }

  return (
    <DragDropContext onDragEnd={(param) => handleUpdateStoryOrder(param)}>
      <div
        style={{
          paddingTop: !isVisible ? ".5em" : 0,
          opacity: isVisible ? 1 : 0,
        }}
        className="relative min-h-[90vh] md:min-h-[80vh] flex-col flex items-center justify-center"
      >
        <div>
          <h2 className="text-center font-medium text-xl mb-2">
            Rearrange streams in {title}
          </h2>
        </div>
        <div className="w-full flex-1 flex items-center justify-center">
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="w-full"
              >
                <ul className="max-w-2xl m-auto w-full">
                  {orderedStreams.map((item, i) => (
                    <Draggable
                      key={item.id}
                      draggableId={"draggable-" + item.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <li
                          key={item.ref}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging
                              ? "0 0 .5em rgba(0,0,0,.2)"
                              : "none",
                          }}
                          //onClick={() => handleSelect(item)}
                          className={`p-2 flex items-center text-xl ${
                            i % 2 === 1 ? "bg-slate-100" : "bg-white"
                          }`}
                        >
                          <button {...provided.dragHandleProps} className="">
                            <Bars2Icon className="h-6 w-6 mr-4" />
                          </button>
                          <button
                            onClick={() => handleSelect(item.id)}
                            className=""
                          >
                            {item.title}
                          </button>
                        </li>
                      )}
                    </Draggable>
                  ))}
                </ul>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
}

export default ModalSreamPositionsDrag;
