import { getIcon } from "../../utils/getIcons";
import EmptyState from "../shared/empty-state/EmptyState";

export default function InputCheckboxStream(props) {
  const {
    legend,
    inputs,
    entity,
    emptyMessage,
    emptyButtonMessage,
    handleEmptyButtonClick,
    paddingTop,
  } = props;

  return inputs.length < 1 ? (
    <EmptyState
      entity={entity}
      message={emptyMessage}
      buttonMessage={emptyButtonMessage}
      isTextSmall={true}
      handleClick={handleEmptyButtonClick}
      paddingTop={paddingTop}
    />
  ) : (
    <fieldset className="mb-2 flex-col flex-wrap">
      <legend className="sr-only">{legend}</legend>
      {inputs.map((input) => (
        <div
          key={input.name}
          style={{ opacity: input.disabled ? 0.4 : 1, transition: "all .5s" }}
          className="relative flex items-start py-2 text-left"
        >
          <div className="flex h-5 items-center">
            <input
              disabled={input.disabled}
              checked={input.value}
              onChange={() => input.setValue(!input.value)}
              id={input.name}
              aria-describedby={input.name}
              name={input.name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div className="ml-2 text-sm whitespace-nowrap">
            <div className="flex items-center">
              <label htmlFor={input.name} className="font-medium text-gray-700">
                {input.label}
              </label>
              {input.icon && input.icon && getIcon(input.icon, "h-3 w-3 mx-1")}
            </div>
            {input.description && entity !== "Hashtags" && (
              <span
                id={`${input.name}-description`}
                className="text-gray-500 whitespace-normal"
              >
                <span className="sr-only">{input.label} </span>
                {input.description}
              </span>
            )}
          </div>
        </div>
      ))}
    </fieldset>
  );
}
