import React from "react";
import InputCheckbox from "../inputs/InputCheckboxStream";
import InputRadio from "../inputs/InputRadio";
import FilterSectionHeading from "./FilterSectionHeading";

function FilterSectionMembers(props) {
  const {
    isOpen,
    handleToggle,
    userTypeValue,
    setUserTypeValue,
    users,
    conditions,
  } = props;

  const radioOptions = [
    { label: "Assigned to", value: "assignees" },
    { label: "Created by", value: "createdBy" },
  ];

  const handleToggleCheckbox = (value) => {
    const obj = { value, operation: "=", prop: userTypeValue };
    props.handleToggleCheckbox(obj);
  };

  const userInputs = users.map((user) => ({
    label: `@@${user.tag}`,
    name: user.email,
    setValue: () => handleToggleCheckbox(user.userId),
    value: conditions.some(
      (c) => c.value === user.userId && c.prop === userTypeValue
    ),
  }));

  return (
    <div className={`border-b ${isOpen && "pb-5"}`}>
      <FilterSectionHeading
        isOpen={isOpen}
        handleToggle={handleToggle}
        title="Members"
      />
      {isOpen && (
        <>
          <div className="pb-2">
            <InputRadio
              setValue={setUserTypeValue}
              value={userTypeValue}
              options={radioOptions}
            />
          </div>
          <div className="">
            <InputCheckbox
              legend={"Users"}
              entity={"Users"}
              inputs={userInputs}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default FilterSectionMembers;
