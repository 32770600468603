import React from "react";

function SettingsHeading(props) {
  const { heading } = props;
  return (
    <div className="text-left pl-5 mt-5">
      <h2 className="text-slate-600 text-sm font-bold">{heading}</h2>
    </div>
  );
}

export default SettingsHeading;
