import { CheckCircleIcon } from "@heroicons/react/20/solid";
import CheckCircleIconOutlineIcon from "@heroicons/react/24/outline/CheckCircleIcon";

import React, { useState } from "react";

function CompleteButton(props) {
  const { isComplete, noteId, note } = props;
  const [isActioned, setIsActioned] = useState(!isComplete);
  const checkIcon =
    isComplete || !isActioned ? (
      <CheckCircleIcon className="w-5 h-5 mr-1 text-green-700" />
    ) : (
      <CheckCircleIconOutlineIcon className="w-5 h-5 mr-1 text-gray-700" />
    );

  const handleComplete = (e) => {
    e.stopPropagation();
    const obj = { prop: "isComplete", value: !isComplete };
    setIsActioned(!isActioned);
    setTimeout(() => {
      props.handleUpdate(noteId, obj, note);
    }, 500);
  };

  return (
    <button className="z-10" onClick={(e) => handleComplete(e)}>
      {checkIcon}
    </button>
  );
}

export default CompleteButton;
