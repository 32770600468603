import React, { useState } from "react";
import { getColors } from "../../utils/getColor";

function Button(props) {
  const { message, handleClick, disabled, icon } = props;
  const type = props.type ?? "button";
  const bg = props.bg ?? "violet";
  const { color, colorHover, focusColor } = getColors(bg);
  const opacity = disabled ? "opacity-20" : "";
  const label = props.label ?? "button";
  const hoverMessage = props.hoverMessage ?? false;
  const [hover, setHover] = useState(false);

  return !hoverMessage || !disabled ? (
    <button
      disabled={disabled}
      onClick={handleClick}
      type={type}
      aria-label={label}
      className={`flex w-full items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-oƒffset-2 ${focusColor} ${color} ${colorHover} ${opacity}`}
    >
      {icon && icon}
      {message}
    </button>
  ) : (
    <div
      className="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && <DisabledHoverMessage hoverMessage={hoverMessage} />}
      <button
        onClick={handleClick}
        type={type}
        aria-label={label}
        className={`flex w-full items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-oƒffset-2 ${focusColor} ${color} ${colorHover} ${opacity}`}
      >
        {icon && icon}
        {message}
      </button>
    </div>
  );
}

export default Button;

const DisabledHoverMessage = ({ hoverMessage }) => {
  return (
    <div className="absolute z-20 top-10 right-0 bg-red bg-opacity-50 p-2 whitespace-nowrap">
      <p className="text-red-600 text-sm">{hoverMessage}</p>
    </div>
  );
};
