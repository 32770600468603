import React from "react";
import InputToggle from "../inputs/InputToggle";
import Dropdown from "./dropdowns/Dropdown";

function HeadingToggle(props) {
  const { title, description, enabled, setEnabled, options, isInModal } = props;
  const color = props.color ?? "violet";

  const margin = props.margin ?? "my-4";
  return (
    <div className={`sm:flex sm:items-center text-left ${margin}`}>
      <div className="pl-2 flex flex-col justify-center flex-1">
        <h1 className="text-lg font-semibold text-gray-700">{title}</h1>
        {description && <p className=" text-sm text-gray-500">{description}</p>}
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none mr-2">
        {!isInModal && (
          <div className="flex items-center">
            <InputToggle
              color={color}
              enabled={enabled}
              setEnabled={setEnabled}
            />
            {options && <Dropdown options={options} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeadingToggle;
