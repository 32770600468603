import React from "react";
import { useState } from "react";
import InputCheckbox from "../../components/inputs/InputCheckboxStream";
import InputSingle from "../../components/inputs/InputSingle";
import { getIcon } from "../../utils/getIcons";
import { getString } from "../../utils/getStrings";
import Fuse from "fuse.js";
import EmptyState from "../../components/shared/empty-state/EmptyState";
import FilterSection from "../../components/filter-section/FilterSection";
import {
  checkIfObjectIsSelected,
  contextOptions,
} from "../../components/filter-section/FilterSectionContext";

function WorkspaceAsideSelection(props) {
  const {
    handleUpdateSet,
    userInfo,
    users,
    hashtags,
    collections,
    active,
    setActive,
    setIsCreateOpen,
    tempStreamConditions,
    setTempStreamConditions,
    openSections,
    setOpenSections,
    showAllHashtags,
    setShowAllHashtags,
  } = props;
  const streams = props.streams.concat(props.userStreams ?? []);
  const [activeUser, setActiveUser] = useState(false);
  const [query, setQuery] = useState("");
  const userActiveStreams = props.userActiveStreams ?? [];
  const currentUserActiveStreams = userActiveStreams.filter(
    (stream) => stream.userId === userInfo.id
  );
  const currentUserActiveStreamIds = currentUserActiveStreams.map(
    (stream) => stream.streamId
  );

  const handleToggleCheckbox = (obj) => {
    const itemIndex = tempStreamConditions.findIndex(
      (c) =>
        c.value === obj.value &&
        c.operation === obj.operation &&
        c.prop === obj.prop
    );

    if (itemIndex === -1) {
      setTempStreamConditions([...tempStreamConditions, obj]);
    } else {
      setTempStreamConditions(
        tempStreamConditions.filter((c, i) => i !== itemIndex)
      );
    }
  };

  const allOptions = getAllOptions(
    hashtags,
    users,
    contextOptions,
    tempStreamConditions,
    handleToggleCheckbox
  );

  const allFuzzy = new Fuse(allOptions, {
    keys: ["title", "id", "locators", "description", "label"],
    includeScore: true,
    threshold: 0.3,
  });

  const streamFuzzy = new Fuse(streams, {
    keys: ["title", "description", "conditions.value"],
    includeScore: true,
    threshold: 0.3,
  });

  const collectionFuzzy = new Fuse(collections, {
    keys: ["title", "description"],
    includeScore: true,
    threshold: 0.3,
  });

  //Fix this unneccessary to do fuzzy for all 3

  let filteredResults =
    query === "" ? [] : allFuzzy.search(query).map((item) => item.item);

  let filteredStreams =
    query === "" ? streams : streamFuzzy.search(query).map((item) => item.item);
  let filteredCollections =
    query === ""
      ? collections
      : collectionFuzzy.search(query).map((item) => item.item);

  const handleToggle = (id, type) => {
    setActiveUser(false);
    props.handleToggle(id, type);
  };

  const streamInputs = filteredStreams.map((stream) => ({
    name: stream.id,
    label: stream.title,
    description: stream.description,
    value: currentUserActiveStreamIds.includes(stream.id),
    conditions: stream.conditions,
    setValue: () => handleToggle(stream.id, "stream"),
    icon: stream.streamType === "personal" ? "user" : "team",
  }));

  //Only neccessarry to sort the active ones

  const views = [
    {
      icon: getIcon("filter"),
      id: "filter",
    },
    {
      icon: getIcon("hashtag", "h-4 w-4"),
      id: "hashtag",
      isHidden: true,
    },
    {
      icon: getIcon("stream"),
      id: "stream",
    },
    {
      icon: getIcon("collection"),
      id: "collection",
      isHidden: false,
    },
    {
      icon: getIcon("user"),
      id: "user",
      isHidden: true,
    },
  ];

  const handleSelectUser = (id) => {
    const streamsWithoutUser = userActiveStreams.filter((stream) => {
      return stream.userId !== userInfo.id;
    });
    const selectedUserStreams = userActiveStreams.filter(
      (stream) => stream.userId === id
    );
    const newUserStreams = selectedUserStreams.map((stream) => {
      return { ...stream, userId: userInfo.id };
    });
    const newStreams = [...streamsWithoutUser, ...newUserStreams];
    return handleUpdateSet(newStreams).then(() => setActiveUser(id));
  };

  const handleSelectCollection = (collection) => {
    const { streamRefs } = collection;
    const streamsWithoutUser = userActiveStreams.filter((stream) => {
      return stream.userId !== userInfo.id;
    });
    const currentUserStreams = userActiveStreams.filter(
      (stream) => stream.userId === userInfo.id
    );
    const currentUserStreamRefs = currentUserStreams.map((stream) => {
      return stream.streamId;
    });
    const newStreamRefs = streamRefs.filter((ref) => {
      return !currentUserStreamRefs.includes(ref);
    });
    const newStreams = [...streamsWithoutUser, ...newStreamRefs];
    return handleUpdateSet(newStreams);
  };

  const otherUsers = users.filter((user) => user.userId !== userInfo.id);
  const getView = () => {
    switch (active) {
      case "filter":
        return (
          <>
            <FilterSection
              sections={["hashtags", "members", "context"]}
              users={users}
              conditions={tempStreamConditions}
              hashtags={hashtags}
              openSections={openSections}
              setOpenSections={setOpenSections}
              handleToggleCheckbox={handleToggleCheckbox}
              showAllHashtags={showAllHashtags}
              setShowAllHashtags={setShowAllHashtags}
            />
          </>
        );
      case "stream":
        return (
          <div className="px-5">
            <InputCheckbox
              entity={"Streams"}
              emptyMessage={getString("empty-stream")}
              emptyButtonMessage={"Create Stream"}
              handleEmptyButtonClick={() => setIsCreateOpen("stream")}
              inputs={streamInputs}
            />
          </div>
        );
      case "user":
        return (
          <menu>
            {otherUsers.map((item) => (
              <UserItem
                key={item.userId}
                user={item}
                handleSelect={handleSelectUser}
                activeUser={activeUser}
              />
            ))}
          </menu>
        );
      case "collection":
        return (
          <CollectionMenu
            handleSelect={handleSelectCollection}
            handleEmptyButtonClick={() => setIsCreateOpen("collection")}
            collections={filteredCollections}
          />
        );
      default:
        return (
          <>
            <InputCheckbox inputs={streamInputs} />
          </>
        );
    }
  };

  return (
    <div className="">
      <>
        <menu className="flex mb-2">
          {views
            .filter((item) => !item.isHidden)
            .map((view) => (
              <MenuItem
                key={view.id}
                icon={view.icon}
                id={view.id}
                active={active}
                setActive={setActive}
              />
            ))}
        </menu>
        <p className="text-gray-600 text-left m-2 text-sm">
          {getString(`workspace-stream-view-${active}`)}
        </p>
        <div className="px-2 mb-2">
          <InputSingle
            value={query}
            setValue={setQuery}
            placeholder={`Search...`}
            label={`${active} search`}
            isLabelHidden={true}
          />
        </div>

        {filteredResults.length > 0 && (
          <div className="pb-5 px-5">
            <InputCheckbox inputs={filteredResults} />
          </div>
        )}
        {getView()}
      </>
    </div>
  );
}

export default WorkspaceAsideSelection;

const MenuItem = ({ icon, id, active, setActive }) => {
  return (
    <li
      className={`${
        active === id
          ? "bg-indigo-50 text-indigo-700"
          : "text-gray-900 hover:bg-gray-50 hover:text-gray-900"
      } cursor-pointer flex items-center px-2 py-3 text-sm font-medium flex-1 justify-center`}
      onClick={() => setActive(id)}
    >
      <div className="flex-shrink-0">{icon}</div>
    </li>
  );
};

const UserItem = ({ user, activeUser, handleSelect }) => {
  return (
    <li
      onClick={() => handleSelect(user.userId)}
      className={`${
        activeUser === user.userId
          ? "bg-indigo-50 text-indigo-700"
          : "text-gray-900 hover:bg-indigo-50 hover:text-indigo-400"
      } cursor-pointer flex items-center px-2 py-5 text-sm font-medium flex-1`}
    >
      {user.name}
    </li>
  );
};

const CollectionItem = ({ collection, handleSelect }) => {
  return (
    <li
      onClick={() => handleSelect(collection)}
      className="relative flex-col items-start px-5 py-3 text-left hover:bg-indigo-50 pointer"
    >
      <div className="flex h-5 items-center">
        <span className="medium text-gray-900 text-sm">{collection.title}</span>
      </div>
      <div className="text-sm">
        {collection.description && (
          <span
            id={`${collection.title}-description`}
            className="text-gray-500"
          >
            {collection.description}
          </span>
        )}
      </div>
    </li>
  );
};

const CollectionMenu = ({
  collections,
  handleSelect,
  handleEmptyButtonClick,
}) => {
  return collections.length < 1 ? (
    <EmptyState
      entity={"Collection"}
      message={getString("empty-collection")}
      handleClick={handleEmptyButtonClick}
      isTextSmall={true}
    />
  ) : (
    <>
      <menu>
        {collections.map((item) => (
          <CollectionItem
            key={item.id}
            collection={item}
            handleSelect={handleSelect}
          />
        ))}
      </menu>
    </>
  );
};

function getAllOptions(hashtags, users, contexts, conditions, handleToggle) {
  const options = [];
  hashtags.forEach((hashtag) => {
    const obj = {
      value: hashtag.title,
      prop: "tags",
      operation: "=",
    };
    options.push({
      label: hashtag.title,
      type: "hashtag",
      description: hashtag.description,
      value: conditions.some((condition) => condition.value === hashtag.title),
      setValue: () => handleToggle(obj),
      id: hashtag.id,
    });
  });
  users.forEach((user) => {
    const assigneeObj = {
      value: user.userId,
      prop: "assignees",
      operation: "=",
    };
    const creatorObj = {
      value: user.userId,
      prop: "createdBy",
      operation: "=",
    };
    options.push(
      {
        id: user.userId,
        label: `@@${user.tag}`,
        type: "user",
        description: "- Assigned to",
        value: conditions.some(
          (c) => c.value === user.userId && c.prop === "assignees"
        ),
        setValue: () => handleToggle(assigneeObj),
        locators: [user.name, user.email],
      },
      {
        id: user.userId,
        label: `@@${user.tag}`,
        type: "user",
        description: "- Created by",
        value: conditions.some(
          (c) => c.value === user.userId && c.prop === "createdBy"
        ),
        setValue: () => handleToggle(creatorObj),
        locators: [user.name, user.email],
      }
    );
  });
  contexts.forEach((context) => {
    options.push({
      id: context.id,
      label: context.label,
      type: "context",
      description: context.description,
      value: checkIfObjectIsSelected(context.id, conditions),
      setValue: () => handleToggle(context.obj),
      locators: context.locators,
    });
  });
  return options;
}
