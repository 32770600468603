import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import InputSingle from "../../inputs/InputSingle";
import Button from "../../buttons/Button";
import ButtonText from "../../buttons/ButtonText";
import { isEmailValid, isStringValid } from "../../../utils/checkStrings";
import Steps from "../../steps/Steps";
import InputTextarea from "../../inputs/InputTextarea";
import InputSelection from "../../inputs/InputSelection";
import InvitesImage from "../../../images/onboarding/invites.svg";
import HashtagsImage from "../../../images/onboarding/hashtags.svg";
import NotesImage from "../../../images/onboarding/notes.svg";

export default function ModalOnboarding(props) {
  const {
    isOpen,
    handleClose,
    handleCreateHashtag,
    handleCreateInvite,
    handleUpdateWorkspace,
    hashtags,
    invites,
  } = props;
  const [pageIndex, setPageIndex] = useState(-1);
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [role, setRole] = useState("Member");

  const resetValues = () => {
    setValue("");
    setDescription("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pageIndex === 0) {
      const obj = { email: value, type: role };
      handleCreateInvite(obj).then(() => resetValues());
    } else if (pageIndex === 1) {
      const hashtagTitle = value.charAt(0) !== "#" ? `#${value}` : value;
      const noHashtagTitle = value.charAt(0) === "#" ? value.slice(1) : value;
      const hashtagDescription = description
        ? description
        : `This hashtag should be used for any note, task or decision related to ${noHashtagTitle}`;
      const obj = { title: hashtagTitle, description: hashtagDescription };
      handleCreateHashtag(obj).then(() => resetValues());
    } else {
      const obj = { prop: "isOnboarded", value: "true" };
      handleUpdateWorkspace(obj).then(() => resetValues());
    }
  };

  const handleComplete = () => {
    const obj = { prop: "isOnboarded", value: "true" };
    handleUpdateWorkspace(obj).then(() => resetValues());
  };

  const handleContinue = () => {
    pageIndex === onboardingData.length - 1 ? handleComplete() : goToNextStep();
  };

  const goToNextStep = () => {
    setPageIndex((...prev) => parseInt(prev) + 1);
  };

  const placeholderForHashtag = () => {
    let placeholderTag = "marketing";
    if (value.length > 0) {
      placeholderTag = value.charAt(0) === "#" ? value.slice(1) : value;
    }
    return `e.g. This hashtag should be used for any note, task or decision related to ${placeholderTag}.`;
  };

  const onboardingData = [
    {
      id: "1",
      title: "Let's invite some colleagues.",
      description: (
        <p className="text-gray-600 text-center text-sm">
          Invite your colleagues to join your workspace and start collaborating.
        </p>
      ),
      inputType: "email",
      buttonMessage: "Invite",
      buttonDisabled:
        (pageIndex === 0 && !isEmailValid(value)) ||
        invites.some((item) => item.email === value),
      placeholder: "e.g. colleague@work.com",
      label: "Add team",
      image: InvitesImage,
      createdItems: invites.map((item) => item.email),
    },
    {
      id: "2",
      title: "Let's create some hashtags.",
      description: (
        <p className="text-gray-600 text-center text-sm">
          Hashtags are topics to discuss...like Slack channels but for{" "}
          <span className="font-bold">notes, tasks and decisions</span> rather
          than conversations.
        </p>
      ),
      inputType: "text",
      buttonMessage: "Create",
      buttonDisabled: pageIndex === 1 && !isStringValid(value),
      placeholder: "e.g. #marketing",
      label: "Create hashtags",
      image: HashtagsImage,
      additionalInput: (
        <InputTextarea
          placeholder={placeholderForHashtag()}
          value={description}
          setValue={setDescription}
        />
      ),
      createdItems: hashtags.map((item) => item.title),
    },
    {
      id: "3",
      title: "Let's get started.",
      image: NotesImage,
      description: (
        <p className="text-gray-600 text-center max-w-xl text-sm">
          Get started by completing the onboarding and pressing{" "}
          <span className="px-3 py-2 mx-1 bg-blue-500 text-white">
            Create Note
          </span>{" "}
          in the bottom right corner.
        </p>
      ),
      inputType: "none",
      placeholder: "e.g. #marketing",
      label: "Get started",
    },
  ];

  const activePage = onboardingData[pageIndex];

  const getStepStatus = (index) => {
    if (index < pageIndex) {
      return "complete";
    } else if (index === pageIndex) {
      return "current";
    } else {
      return "upciming";
    }
  };

  const steps = onboardingData.map((item, index) => {
    return {
      id: item.id,
      name: item.label,
      status: getStepStatus(index),
      handleClick: () => setPageIndex(index),
    };
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full md:max-w-4xl sm:my-8 sm:max-w-sm sm:p-6">
                {pageIndex < 0 ? (
                  <WelcomePage
                    handleContinue={handleContinue}
                    handleComplete={handleComplete}
                  />
                ) : (
                  <section>
                    <div className="flex justify-end">
                      <div>
                        {pageIndex === 2 ? (
                          <Button
                            handleClick={() => handleComplete()}
                            message={"Complete Onboarding"}
                          />
                        ) : (
                          <ButtonText
                            handleClick={() => handleComplete()}
                            message={"Skip Onboarding"}
                          />
                        )}
                      </div>
                    </div>
                    <OnboardingItem
                      value={value}
                      setValue={setValue}
                      handleSubmit={handleSubmit}
                      {...activePage}
                    />
                    <div className="flex justify-start">
                      <div>
                        <ButtonText
                          handleClick={() => handleContinue()}
                          color={"text-blue-600"}
                          message={
                            pageIndex < 2
                              ? "Go to next step"
                              : "Go to workspace"
                          }
                        />
                      </div>
                    </div>
                    <Steps steps={steps} />
                  </section>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const OnboardingItem = ({
  title,
  description,
  image,
  buttonMessage,
  handleSubmit,
  value,
  setValue,
  inputType,
  buttonDisabled,
  placeholder,
  additionalInput,
  createdItems,
}) => {
  return (
    <div className="flex flex-col items-center justify-center px-10 pb-10 pt-5">
      <h3 className="text-lg font-bold text-gray-800">{title}</h3>
      {description}
      <div className="mt-10 w-full">
        {inputType !== "none" && (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex-col items-center w-full"
          >
            <div className="flex-1 flex items-center">
              <div className="flex-1">
                <InputSingle
                  placeholder={placeholder}
                  type={inputType}
                  value={value}
                  setValue={setValue}
                />
              </div>
            </div>
            <div>{additionalInput}</div>
            <div className="flex justify-end mt-2">
              <div className="min-w-[10em]">
                <Button
                  disabled={buttonDisabled}
                  type={"submit"}
                  message={buttonMessage}
                />
              </div>
            </div>
          </form>
        )}
        {createdItems && createdItems.length > 0 && (
          <ol className="mt-2">
            {createdItems.map((item) => (
              <span className="text-xs px-2 py-1 mr-1 bg-slate-100 text-slate-600">
                {item}
              </span>
            ))}
          </ol>
        )}
      </div>
    </div>
  );
};

const WelcomePage = ({ handleComplete, handleContinue }) => {
  return (
    <section className="p-20">
      <h1 className="text-gray-800 text-center text-2xl text-bold mt-5 mb-2">
        Welcome to SamePage 🎉{" "}
      </h1>
      <p className="text-gray-600 mb-10">
        Our goal is to make information retrieval at least 10x faster, not only
        for you, but for your whole team. Follow our intuitive hashtagging and
        streaming system to get up and running in a matter of minutes.
      </p>
      <div className="flex">
        <div style={{ flex: 2 }}>
          <Button
            handleClick={() => handleContinue()}
            message={"Take me to onboarding"}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ButtonText
            handleClick={() => handleComplete()}
            message={"Skip onboarding"}
          />
        </div>
      </div>
    </section>
  );
};
