import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import SectionHeading from "../../components/shared/SectionHeading";

function WorkspaceSectionNotespaces(props) {
  const { setIsCreateOpen, handleSelect } = props;
  const notespaces = props.notespaces ?? [];
  return (
    <section>
      <>
        <SectionHeading
          heading={"Our Notespaces"}
          subheading={"Create and manage your team's notespaces"}
          buttonMessage={"Create Notespace"}
          handleClick={() => setIsCreateOpen("notespace")}
        />
        <ul className="">
          {notespaces.map((notespace) => (
            <NotespaceListItem
              notespace={notespace}
              key={notespace.notespaceId}
              handleSelect={handleSelect}
            />
          ))}
        </ul>
      </>
    </section>
  );
}

const NotespaceListItem = (props) => {
  const { notespace, handleSelect } = props;
  return (
    <li
      onClick={() => handleSelect(notespace.notespaceId)}
      role="button"
      aria-label={`Select ${notespace.title}`}
      className="w-100 text-left p-5 text-gray-700 border-b border-b-slate-100 hover:bg-slate-100"
      key={notespace.notespaceId}
    >
      <div className="ml-5 flex items-center">
        {notespace.type === "notespace" ? (
          <ChatBubbleBottomCenterIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
        ) : (
          <ChatBubbleLeftRightIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
        )}
        {notespace.title}
      </div>
    </li>
  );
};

export default WorkspaceSectionNotespaces;
