import { HandThumbUpIcon } from "@heroicons/react/20/solid";
import React from "react";

function DecisionChip() {
  return (
    <span className="text-violet-700 p-1 bg-violet-100 text-xs rounded-sm mr-1 px-2 inline-flex items-center">
      <HandThumbUpIcon className="w-3 h-3 mr-1 text-violet-700" />
      isDecision
    </span>
  );
}

export default DecisionChip;
