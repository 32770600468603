import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import Button from "../../buttons/Button";

export default function ModalMessage(props) {
  const {
    list,
    type,
    message,
    link,
    linkText,
    action,
    handleAction,
    isOpen,
    setIsOpen,
  } = props;

  const getProps = () => {
    switch (type) {
      case "error":
        return {
          icon: <XCircleIcon className="h-10 w-10 text-red-400" />,
          bgColor: "bg-red-50",
          textColor: "text-red-700",
          headingColor: "text-red-800",
          hoverTextColor: "hover:text-red-600",
          color: "red",
        };
      case "success":
        return {
          icon: <CheckCircleIcon className="h-10 w-10 text-green-400" />,
          bgColor: "bg-green-50",
          textColor: "text-green-700",
          headingColor: "text-green-800",
          hoverTextColor: "hover:text-green-600",
          color: "green",
        };
      case "warning":
        return {
          icon: <ExclamationCircleIcon className="h-10 w-10 text-yellow-400" />,
          bgColor: "bg-yellow-50",
          textColor: "text-yellow-700",
          headingColor: "text-yellow-800",
          hoverTextColor: "hover:text-yellow-600",
          color: "yellow",
        };
      default:
        return {
          icon: <InformationCircleIcon className="h-10 w-10 text-blue-400" />,
          bgColor: "bg-blue-50",
          textColor: "text-blue-700",
          headingColor: "text-blue-800",
          hoverTextColor: "hover:text-blue-600",
          color: "blue",
        };
    }
  };
  const { icon, bgColor, textColor, headingColor, hoverTextColor, color } =
    getProps();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-7xl sm:my-8 sm:max-w-sm">
                <div className={`rounded-md py-20 ${bgColor}`}>
                  <div className="flex flex-col w-full items-center">
                    <div className="flex-shrink-0">{icon}</div>
                    <div className="p-5">
                      <h3 className={`text-xl font-medium text-center ${headingColor}`}>
                        {message}
                      </h3>
                      {list && (
                        <div className={`mt-2 text-sm ${textColor}`}>
                          <ul className="list-disc space-y-1 pl-5">
                            {list.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {link && linkText && (
                        <div className="mt-2 text-sm underline bold">
                          <a
                            href={link}
                            className={`font-medium underline ${textColor} ${hoverTextColor}`}
                          >
                            {linkText}
                          </a>
                        </div>
                      )}
                      {action && (
                        <div className="flex m-10 justify-center">
                          <div>
                            <Button
                              bg={color}
                              message={action}
                              handleClick={handleAction}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
