import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import logo from "../images/logo.png";
import Avatar from "../components/avatars/Avatar";
import { getColors } from "../utils/getColor";
import { logout } from "../firebase";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PageShell(props) {
  const {
    activeView,
    setActiveView,
    mainView,
    navigationItems,
    crumbs,
    type,
    isAlt,
    color,
    userInfo,
    handleOpenSearch,
    isSearchVisible,
    secondaryView,
  } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const { color7, color8, colorText3, colorHover8 } = getColors(color);

  if (!visible) {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }

  const allNavigationItems = navigationItems.filter(
    (item) => item.isHidden !== true
  );
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/user");
  };
  const userNavigation = [
    {
      name: "Your Profile",
      handleClick: () => handleNavigate(),
      role: "link",
      href: "/user",
    },
    { name: "Sign out", handleClick: () => logout(), type: "warning" },
  ];

  return (
    <>
      <div className="flex h-full min-h-screen">
        {/* Narrow sidebar */}
        <div className={"hidden w-28 overflow-y-auto md:block " + color7}>
          <div className="flex w-full flex-col items-center py-6">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/user">
                <img className="h-8 w-auto" src={logo} alt="SamePage" />
              </Link>
            </div>
            <div
              style={{ opacity: visible ? 1 : 0, transition: "all .5s" }}
              className="mt-6 w-full flex-1 space-y-1 px-2"
            >
              {allNavigationItems.map((item) => (
                <div
                  onClick={() => setActiveView(item.id)}
                  role="link"
                  aria-label={`Go to ${item.name} view`}
                  key={item.id}
                  href={item.href}
                  className={classNames(
                    item.id === activeView
                      ? "text-white " + color8
                      : "text-indigo-100 hover:text-white " + colorHover8,
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={item.id === activeView ? "page" : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.id === activeView
                        ? "text-white"
                        : "group-hover:text-white " + colorText3,
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20 md:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel
                  className={
                    "relative flex w-full max-w-xs flex-1 flex-col pt-5 pb-4 " +
                    color7
                  }
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div
                    className={"flex flex-shrink-0 items-center px-4 " + color7}
                  >
                    <img className="h-8 w-auto" src={logo} alt="SamePage" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                    <nav className="flex h-full flex-col">
                      <div className="space-y-1">
                        {navigationItems.map((item) => (
                          <div
                            onClick={() => setActiveView(item.id)}
                            role="link"
                            aria-label={`Go to ${item.name} view`}
                            key={item.id}
                            className={classNames(
                              item.id === activeView
                                ? "text-white " + color8
                                : "text-indigo-100 hover:text-white " +
                                    colorHover8,
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )}
                            aria-current={
                              item.id === activeView ? "page" : undefined
                            }
                          >
                            <item.icon
                              className={classNames(
                                item.id === activeView
                                  ? "text-white"
                                  : "group-hover:text-white " + colorText3,
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </div>
                        ))}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div
          key={`search-${type}`}
          style={{ paddingLeft: visible ? 0 : ".5em", transition: "all .5s" }}
          className="flex flex-1 flex-col h-screen overflow-auto"
        >
          <header className="w-full">
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <button
                type="button"
                className="border-r border-gray-200 px-4   + color7text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-between px-4 sm:px-6">
                <div className="hidden md:flex">
                  <Breadcrumbs
                    isAlt={isAlt}
                    key={`crumbs-${type}`}
                    crumbs={crumbs}
                  />
                </div>
                <div className="flex flex-1">
                  {!isSearchVisible && (
                    <button
                      onClick={() => handleOpenSearch(true)}
                      aria-label="Open search"
                      className="flex w-full md:ml-0 items-center"
                    >
                      <MagnifyingGlassIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-gray-400 ml-2">Search</span>
                    </button>
                  )}
                </div>
                <div className="ml-2 flex items-center space-x-4   + color7sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <div
                          style={{
                            opacity: visible ? 1 : 0,
                            marginRight: visible ? 0 : ".5em",
                            transition: "all .5s",
                          }}
                        >
                          <Avatar name={userInfo.name} />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              const colors =
                                item.type === "warning"
                                  ? "bg-red-50"
                                  : "bg-slate-100";
                              const role = item.role ?? "button";
                              const href = item.href ?? "#";
                              return (
                                <button
                                  role={role}
                                  data-href={href}
                                  onClick={item.handleClick}
                                  className={classNames(
                                    active ? colors : "",
                                    item.type === "warning"
                                      ? "text-red-700"
                                      : "text-slate-900",
                                    "block px-4 py-2 text-sm w-full text-left"
                                  )}
                                >
                                  {item.name}
                                </button>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex justify-center overflow-hidden h-full flex-1">
          {secondaryView && (
              <aside className="hidden w-96 overflow-auto border-r border-gray-200 lg:block bg-slate-50">
                {secondaryView}
              </aside>
            )}
            <main className="overflow-y-auto flex-1">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last pb-5"
              >
                {mainView}
              </section>
            </main>
            {/*  Secondary column (hidden on smaller screens) */}
          </div>
        </div>
      </div>
    </>
  );
}
