export const getString = (string) => {
  switch (string) {
    case "stream-personal":
      return "Personal streams will only be accessible to you.";
    case "stream-team":
      return "Team streams will be accessible to all workspace members.";
    case "settings-notespace-type":
      return "Notespaces are for internal teams to stay on top of notes, ideas and tasks. Notespaces can be changed to Meetingspaces at any time.";
    case "settings-meetingspace-type":
      return "Meetingspaces are for external teams to collaborate on notes, ideas and tasks. Meetingspaces can be changed to Notespaces at any time.";
    case "settings-all-notes":
      return "If this toggle is switch, Workspace members will be able to see notes created in any Notespace/Meetingspace. If this toggle isn't switched, they will only be able to see notes created while in this Space.";
    case "settings-calendar-view":
      return "If this toggle is switched, space members will be able to see the calendar view in the sidebar. External members will only ever be able to see tasks from this space.";
    case "settings-decision-log-view":
      return "If this toggle is switched, space members will be able to see the decision log in the sidebar. External members will only ever be able to see decisions from this space.";
    case "premium-features-notespaces":
      return "Notespaces are for teams to collaborate and stay on top of notes, ideas and tasks for ongoing internal or external projects.";
    case "workspace-stream-view-streams":
      return "Select which streams you'd like to see in the Stream View.";
    case "workspace-stream-view-users":
      return "Select a user and see the streams they currently have in their Stream View.";
    case "workspace-stream-view-collections":
      return "Select a collection (of streams) and populate it into the Stream View.";
    case "workspace-stream-view-hashtags":
      return "Select the hashtags that you'd like to see in the Stream View.";
    case "empty-hashtag":
      return "To get started with hashtags, create one below or simply create a note using a hashtag.";
    case "empty-stream":
      return "Streams are filtered views for your notes. To get started with streams, create one by pressing the button and define your filter conditions.";
    case "empty-collection":
      return "Collections are simply a collections of streams. This makes it easy for you to group streams together and populate them into your Stream View.";
    case "empty-note":
      return "To get started with notes, tasks and decision, create now. By adding hashtags to your notes, you can easily filter them into streams.";
    default:
      return null;
  }
};

export const getUppercase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
