import { updateUser } from "../services/UserService";
import { updateWorkspace } from "../services/WorkspaceService";

export function updateTitle(newTitle, userInfo, workspaceId) {
  let workspaces = userInfo.workspaces;
  const oldTitle = workspaces.find((item) => item.workspaceId === workspaceId);
  if (newTitle !== oldTitle) {
    workspaces = workspaces.map((item) => {
      if (item.workspaceId === workspaceId) {
        item.title = newTitle;
      }
      return item;
    });
    const updateObj = { value: workspaces, prop: "workspaces" };
    return updateUser(userInfo.id, updateObj);
  }
}

export function removeWorkspace(workspaceId, userInfo, navigate) {
  let workspaces = userInfo.workspaces;
  workspaces = workspaces.map((item) => {
    if (item.workspaceId === workspaceId) {
      item.isDeleted = true;
    }
    return item;
  });
  const updateObj = { value: workspaces, prop: "workspaces" };
  return updateUser(userInfo.id, updateObj).then(() => navigate("/user"));
}

export function removeNotespace(
  workspaceId,
  notespaceId,
  notespaces,
  userInfo
) {
  const newNotespaces = notespaces.map((item) => {
    if (item.notespaceId === notespaceId) {
      item.isDeleted = true;
    }
    return item;
  });
  const updateObj = { value: newNotespaces, prop: "notespaces" };
  return updateWorkspace(workspaceId, userInfo, updateObj);
}
