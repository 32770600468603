export const createStreamFromHashtag = (hashtags) => {
  const conditions = hashtags.map((hashtag) => hashtag.title);
  const titles = conditions.join(", ");
  const ids = conditions.join("-");
  const stream = {
    id: `hashtag-stream-${ids}`,
    conditions,
    title: `All notes for ${titles}`,
    description: `All notes wthat have been tagged with ${titles}`,
    isHashtagStream: true,
    isNoOptions: true,
  };
  return stream;
};

export const createTempStream = (conditions) => {
  const stream = {
    id: `temp-stream`,
    conditions,
    title: `My Stream`,
    description: `All notes wthat have been filtered through my stream`,
    isNoOptions: true,
  };
  return stream;
};
