import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  BookmarkIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChatBubbleBottomCenterIcon,
  FunnelIcon,
  HashtagIcon,
  PencilIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const getIcon = (name, classes) => {
  const iconClasses = classes ?? "h-5 w-5";
  switch (name) {
    case "note":
    case "notes":
      return <PencilIcon className={iconClasses} />;
    case "stream":
    case "streams":
      return <ChatBubbleBottomCenterIcon className={iconClasses} />;
    case "collection":
    case "collections":
      return <BookmarkIcon className={iconClasses} />;
    case "hashtag":
    case "hashtags":
      return <HashtagIcon className={iconClasses} />;
    case "user":
    case "users":
      return <UserIcon className={iconClasses} />;
      case "team":
      case "teams":
      return <UsersIcon className={iconClasses} />;
    case "calendar":
      return <CalendarIcon className={iconClasses} />;
    case "workspace":
    case "workspaces":
      return <BriefcaseIcon className={iconClasses} />;
    case "filter":
      return <FunnelIcon className={iconClasses} />;
    case "plus":
      return <PlusIcon className={iconClasses} />;
    case "minus":
      return <MinusIcon className={iconClasses} />;
    default:
      return null;
  }
};
