import { db, firebase } from "../firebase";

export function createHashtag(workspaceId, userInfo, obj) {
  //Create workspaceStream in WorkspaceService
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const { title, description } = obj;
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("hashtags")
    .add({
      title,
      description,
      workspaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    });
}

export function updateHashtag(workspaceId, hashtagId, userInfo, obj) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("hashtags")
    .doc(hashtagId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateHashtagFull(workspaceId, hashtagId, userInfo, obj) {
  const userId = userInfo.id;
  const updateMessage = "Update full stream";
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("hashtags")
    .doc(hashtagId)
    .update({
      ...obj,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function createHashtagBatch(workspaceId, userInfo, array) {
  //Create workspaceStream in WorkspaceService
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const batch = db.batch();
  array.forEach((hashtag) => {
    var docRef = db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("hashtags")
      .doc();
    const doc = {
      title: hashtag,
      description: "",
      workspaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    };
    batch.set(docRef, doc);
  });
  return batch.commit();
}
