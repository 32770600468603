import { UserIcon } from "@heroicons/react/20/solid";
import React from "react";

function AvatarBlank(props) {
  return (
    <div className="h-8 w-8 rounded-full flex justify-center items-center bg-slate-200 text-slate-400 font-medium">
      <UserIcon className="h-8 w-8" />
    </div>
  );
}

export default AvatarBlank;
