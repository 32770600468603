export function getPriorityColor(status) {
  switch (status) {
    case "high":
      return { color: "text-red-700", bg: "bg-red-100" };
    case "medium":
      return { color: "text-indigo-700", bg: "bg-indigo-100" };
    case "low":
      return { color: "text-green-700", bg: "bg-green-100" };
    default:
      return { color: "text-gray-700", bg: "bg-gray-100" };
  }
}

export const getColors = (color) => {
  switch (color) {
    case "red":
      return {
        colorText3: "text-red-300",
        colorText6: "text-red-600",
        color: "bg-red-600",
        color6: "bg-red-600",
        color7: "bg-red-700",
        color8: "bg-red-800",
        colorHover: "hover:bg-red-700",
        colorHover8: "hover:bg-red-800",
        focusColor: "focus:ring-red-500",
      };
    case "indigo":
      return {
        colorText3: "text-indigo-300",
        colorText6: "text-indigo-600",
        color: "bg-indigo-600",
        color6: "bg-indigo-600",
        color7: "bg-indigo-700",
        color8: "bg-indigo-800",
        colorHover: "hover:bg-indigo-700",
        colorHover8: "hover:bg-indigo-800",
        focusColor: "focus:ring-indigo-500",
      };
    case "violet":
      return {
        colorText3: "text-violet-300",
        colorText6: "text-violet-600",
        color: "bg-violet-600",
        color6: "bg-violet-600",
        color7: "bg-violet-700",
        color8: "bg-violet-800",
        colorHover: "hover:bg-violet-700",
        colorHover8: "hover:bg-violet-800",
        focusColor: "focus:ring-violet-500",
      };
    case "blue":
      return {
        colorText3: "text-blue-300",
        colorText6: "text-blue-600",
        color: "bg-blue-600",
        color6: "bg-blue-600",
        color7: "bg-blue-700",
        color8: "bg-blue-800",
        colorHover: "hover:bg-blue-700",
        colorHover8: "hover:bg-blue-800",
        focusColor: "focus:ring-blue-500",
      };
    case "sky":
      return {
        colorText3: "text-sky-300",
        colorText6: "text-sky-600",
        color: "bg-sky-600",
        color6: "bg-sky-600",
        color7: "bg-sky-700",
        color8: "bg-sky-800",
        colorHover: "hover:bg-sky-700",
        colorHover8: "hover:bg-sky-800",
        focusColor: "focus:ring-sky-500",
      };
    default:
      return {
        colorText3: "text-indigo-300",
        colorText6: "text-indigo-600",
        color: "bg-indigo-600",
        color6: "bg-indigo-600",
        color7: "bg-indigo-700",
        color8: "bg-indigo-800",
        colorHover: "hover:bg-indigo-700",
        colorHover8: "hover:bg-indigo-800",
        focusColor: "focus:ring-indigo-500",
      };
  }
};
