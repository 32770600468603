import React from "react";
import InputCheckbox from "../inputs/InputCheckboxStream";
import FilterSectionHeading from "./FilterSectionHeading";

function FilterSectionContext(props) {
  const { isOpen, handleToggle, conditions } = props;

  const handleToggleCheckbox = (value) => {
    const obj = getContextObject(value);
    props.handleToggleCheckbox(obj);
  };

  const contextInputs = contextOptions.map((option) => ({
    name: option.value,
    label: option.label,
    description: option.description,
    value: checkIfObjectIsSelected(option.value, conditions),
    setValue: () => handleToggleCheckbox(option.value),
  }));

  return (
    <div className={`border-b ${isOpen && "pb-5"}`}>
      <FilterSectionHeading
        isOpen={isOpen}
        handleToggle={handleToggle}
        title="Context"
      />
      {isOpen && <InputCheckbox entity={"Contexts"} inputs={contextInputs} />}
    </div>
  );
}

export default FilterSectionContext;

const getContextObject = (type) => {
  switch (type) {
    case "IsCompleteTask":
      return { prop: "isComplete", value: "true", operation: "=" };
    case "IsIncompleteTask":
      return { prop: "isComplete", value: "false", operation: "=" };
    case "isHighPriority":
      return { prop: "priority", value: "high", operation: "=" };
    case "isDecision":
      return { prop: "isDecision", value: "true", operation: "=" };
    default:
      return null;
  }
};

export const contextOptions = [
  {
    id: "IsCompleteTask",
    label: "Is a Completed Task",
    value: "IsCompleteTask",
    description: "Is a task that has already been completed",
    obj: getContextObject("IsCompleteTask"),
    locators: ["task", "--task"],
  },
  {
    id: "IsIncompleteTask",
    label: "Is an Incomplete Task",
    value: "IsIncompleteTask",
    description: "Is a task that has not yet been completed",
    obj: getContextObject("IsIncompleteTask"),
    locators: ["task", "--task"],
  },
  {
    id: "isHighPriority",
    label: "Is of High Priority",
    value: "isHighPriority",
    description: "Is a note or task that has been marked as high priority",
    obj: getContextObject("isHighPriority"),
    locators: ["priority", "!!priority"],
  },
  {
    id: "isDecision",
    label: "Is a Decision",
    value: "isDecision",
    description: "Is a note or task that has been marked as a decision",
    obj: getContextObject("isDecision"),
    locators: ["decision", "--decision"],
  },
  //{ label: "IsTask (Overdue)", value: "isTaskOverdue" },
  //{ label: "IsTask (Today)", value: "isTaskToday" },
];

export const checkIfObjectIsSelected = (type, conditions) => {
  let isSelected = false;
  if (type === "IsCompleteTask") {
    return conditions.some(
      (condition) =>
        condition.prop === "isComplete" && condition.value === "true"
    );
  }
  if (type === "IsIncompleteTask") {
    return conditions.some(
      (condition) =>
        condition.prop === "isComplete" && condition.value === "false"
    );
  }
  if (type === "isHighPriority") {
    return conditions.some(
      (condition) => condition.prop === "priority" && condition.value === "high"
    );
  }
  if (type === "isDecision") {
    return conditions.some(
      (condition) =>
        condition.prop === "isDecision" && condition.value === "true"
    );
  }
  return isSelected;
};
