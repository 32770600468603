import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import InputTextarea from "../inputs/InputTextarea";
import InputSingle from "../inputs/InputSingle";
//import InputRadio from "../inputs/InputRadio";

export default function NewWorkspaceSlideout(props) {
  const { isOpen, setIsOpen } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  /* 
  const [type, setType] = useState("internal");
  const options = [
    { label: "Internal", value: "internal" },
    { label: "External", value: "external" },
  ]; */

  const handleCreate = (e) => {
    e.preventDefault();
    const obj = { title: name, description };
    props.handleCreate(obj);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div
            style={{
              background: isOpen ? "rgba(0,0,0,.3)" : "rgba(0,0,0,0)",
              transition: "all .5s",
            }}
            className="absolute inset-0 overflow-hidden"
          >
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form
                    onSubmit={(e) => handleCreate(e)}
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            New Workspace
                          </Dialog.Title>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new workspace.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <InputSingle
                              required
                              label="Workspace name"
                              name="workspace-name"
                              value={name}
                              setValue={setName}
                            />
                            <InputTextarea
                              label="Description"
                              name="description"
                              value={description}
                              setValue={setDescription}
                            />
                            {/*
                            <InputRadio
                              label="Select an Project type"
                              options={options}
                              setValue={setType}
                              value={type}
                              title={"Project type"}
                              selectionInfo={
                                type === "external" ? "External" : "Internal"
                              }
                            /> */}
                          </div>
                          <div className="pt-4 pb-6">
                            <div className="mt-4 flex text-sm">
                              <a
                                href="localhost:3000/privacy-policy"
                                className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                              >
                                <QuestionMarkCircleIcon
                                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="ml-2">
                                  Learn more about Workspaces
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
