import { formatDateToTimestampFromCalendar } from "./getDates";

export function modelNote(newNote, users) {
  let words,
    hashtags,
    contextWords,
    wordsWithoutIndicators,
    assignees = [];
  words = newNote.split(" ");
  hashtags = words.filter((word) => word.startsWith("#"));
  const priorityWord = words.find((word) => word.startsWith("!!"));
  const statusWord = words.find((word) => word.startsWith("//"));
  const dateString = words.find((word) => word.startsWith("-d-"));
  contextWords = words.filter((word) => word.startsWith("--"));
  assignees = words.filter((word) => word.startsWith("@@"));
  wordsWithoutIndicators = words.filter(
    (word) =>
      !word.startsWith("#") &&
      !word.startsWith("!!") &&
      !word.startsWith("--") &&
      !word.startsWith("//") &&
      !word.startsWith("-d-") &&
      !word.startsWith("@@")
  );
  const note = wordsWithoutIndicators.join(" ");
  const isTask = contextWords.includes("--task");
  const isDecision = contextWords.includes("--decision");
  const priority = priorityWord ? priorityWord.slice(2) : false;
  const status = statusWord ? statusWord.slice(2) : false;
  let dueDate = dateString ? dateString.slice(3) : false;
  dueDate = dueDate ? formatDateToTimestampFromCalendar(dueDate) : false;
  let userTags = [];
  if (users) {
    userTags = users.map((user) => user.tag);
  }
  assignees = assignees.map((assignee) => {
    const assigneTag = assignee.slice(2);
    const assigneeObj = users.find((user) => user.tag === assigneTag);
    if (userTags.includes(assigneTag)) {
      return { id: assigneeObj.userId, email: assigneeObj.email };
    }
  });

  const obj = {
    isTask,
    isDecision,
    priority,
    note,
    hashtags,
    assignees,
    description: "",
    dueDate,
    status,
  };
  return obj;
}

export function removeNoteIndicators(newNote, users) {
  let words,
    indicators = [];
  words = newNote.split(" ");
  indicators = words.filter(
    (word) =>
      word.startsWith("#") ||
      word.startsWith("!!") ||
      word.startsWith("--") ||
      word.startsWith("//") ||
      word.startsWith("-d-") ||
      word.startsWith("@@")
  );
  const note = indicators.join(" ");
  return note;
}

export const toggleNoteContext = (context, note, setNote) => {
  const doesExist = note.includes(context);
  if (doesExist) {
    setNote(note.replace(` ${context}`, ""));
  } else {
    setNote(`${note} ${context}`);
  }
};

export const toggleNoteSetContext = (context, note, setNote, number) => {
  const charNumber = number ?? 2;
  const firstChars = context.slice(0, charNumber);
  const doesExist = note.includes(firstChars);
  if (doesExist) {
    const wordStrings = note.split(" ");
    let matchingWord = wordStrings.find((word) => word.includes(firstChars));
    setNote(note.replace(`${matchingWord}`, context));
  } else {
    setNote(`${note} ${context}`);
  }
};

export function checkForHashtagBeforeSpace(value) {
  const regex = /#\S+/g;
  const matches = value.match(regex);
  const userRegex = /@\S+/g;
  const userMatches = value.match(userRegex);
  if ((matches || value.slice(-1) === "#") && !checkAtIsAfterHashtag(value)) {
    return "#";
  } else if (userMatches || value.slice(-1) === "@") {
    return "@";
  } else {
    return false;
  }
}

export function getLastWordThatStartsWithHashtag(value) {
  const regex = /#\S+/g;
  let matches = value.match(regex);
  if (!matches) {
    matches = [];
  }
  if (value.slice(-1) === "#") {
    matches.push("#");
  }
  if (matches.length === 0) {
    return "#";
  }
  return matches[matches.length - 1];
}

export function getLastWordThatStartsWithAt(value) {
  const regex = /@\S+/g;
  let matches = value.match(regex);
  if (!matches) {
    matches = [];
  }
  if (value.length > 1 && value.slice(-2) === "@" && value.slice(-1) === "@") {
    matches.push("@@");
  }
  if (matches.length === 0) {
    return "@";
  }
  return matches[matches.length - 1];
}

function checkAtIsAfterHashtag(value) {
  const lastHashtagIndex = value.lastIndexOf("#");
  const lastAtIndex = value.lastIndexOf("@");
  if (lastHashtagIndex < lastAtIndex) {
    return true;
  } else {
    return false;
  }
}
