import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props) {
  const { message, icon, isRight, isMiddle, isHighlighted, highlightColor } =
    props;
  let corner = isRight ? "rounded-r-md" : "rounded-l-md";
  let borderLeft = isRight || isMiddle ? "border-l-0" : "";
  corner = isMiddle ? "" : corner;
  const bg = isHighlighted
    ? "bg-gray-100 hover:bg-gray-150"
    : "bg-white hover:bg-gray-50";
  const text = isHighlighted ? highlightColor : "text-gray-700";

  const handleSelect = (value) => {
    props.handleSelect(value);
  };

  const options = props.options ?? [];

  return (
    <Menu as="div" className="relative inline-block text-left w-1/6 min-w-6">
      <div>
        <Menu.Button
          className={`inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ${corner} ${borderLeft} ${bg} ${text}`}
        >
          {icon && icon}
          <span className="hidden lg:block">{message}</span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bottom-10 left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ul className="py-1">
            {options.map((option) => (
              <Menu.Item>
                {({ active }) => (
                  <li
                    onClick={() => handleSelect(option.value)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {option.label}
                  </li>
                )}
              </Menu.Item>
            ))}
          </ul>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
