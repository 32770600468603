import React, { useState } from "react";
import InputToggle from "../../inputs/InputToggle";

function SettingsToggle(props) {
  const { enabled, handleUpdate, heading, description, prop, icon } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const expandMessage = isExpanded ? "See less" : "See more";

  const handleSwitch = () => {
    const obj = { prop, value: !enabled };
    handleUpdate(obj);
  };
  return (
    <div className="w-full">
      <div className="flex w-full p-5 border-box items-center">
        {icon && icon}
        <div className="w-full text-left flex-1">
          <h2 className="text-md text-gray-800">{heading}</h2>
          {description && (
            <>
              {isExpanded && (
                <p className="text-sm text-gray-500">{description}</p>
              )}
              <div
                onClick={() => setIsExpanded(!isExpanded)}
                role="button"
                aria-label={expandMessage}
              >
                <p
                  className={`text-xs ${
                    isExpanded ? "text-indigo-500" : "text-gray-500"
                  } `}
                >
                  {expandMessage}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="ml-10">
          <InputToggle enabled={enabled} setEnabled={handleSwitch} />
        </div>
      </div>
    </div>
  );
}

export default SettingsToggle;
