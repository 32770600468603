import React from "react";
import ListConditionItem from "./ListConditionItem";

function ListConditionsHashtags(props) {
  const { conditions } = props;

  return (
    <ul className="flex flex-wrap">
      {conditions.map((item, i) => {
        const suffix =
          conditions.length > 0 && i !== conditions.length - 1 ? " or " : " ";
        return (
          <ListConditionItem
            key={item}
            index={i}
            suffix={suffix}
            value={item}
            operation={"includes"}
            prop={"tags"}
          />
        );
      })}
    </ul>
  );
}

export default ListConditionsHashtags;
