import { Button } from "./Button";
import { Container } from "./Container";
import backgroundImage from "../images/background-call-to-action.jpg";

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <div
        style={{ width: 2347, height: 1244 }}
        className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
      >
        <img className="w-full" src={backgroundImage} alt="" unoptimized />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Get started today
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            It’s time to get your team a little more organized. Create an
            account and you'll have everybody on the same page in minutes.
          </p>
          <Button color="white" className="mt-10">
            Get 6 months free
          </Button>
        </div>
      </Container>
    </section>
  );
}
