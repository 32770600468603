import React from "react";
import { getOperationWord, getValueWord } from "../../../utils/getTemplates";
import ListConditionItem from "./ListConditionItem";

function ListConditions(props) {
  const { conditions, handleClear } = props;
  const users = props.users ?? [];
  const notespaces = props.notespaces ?? [];
  const meetings = props.meetings ?? [];
  const isHashtagStream = props.isHashtagStream ?? false;
  const joiningOperation = isHashtagStream ? "or" : "&";

  return (
    <ul className="flex flex-wrap">
      {conditions.map((item, i) => {
        const { prop, operation, value } = item;
        const suffix =
          conditions.length > 0 && i !== conditions.length - 1
            ? ` ${joiningOperation} `
            : " ";
        const valueWord = getValueWord(
          prop,
          value,
          users,
          notespaces,
          meetings
        );
        const operationWord = getOperationWord(prop, operation);
        return (
          <ListConditionItem
            key={item.prop + "-" + item.value}
            handleClear={handleClear}
            index={i}
            suffix={suffix}
            value={valueWord}
            operation={operationWord}
            item={item}
            prop={prop}
          />
        );
      })}
    </ul>
  );
}

export default ListConditions;
