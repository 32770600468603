import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Toolbar from "../toolbars/ToolbarNote";
import {
  checkForHashtagBeforeSpace,
  getLastWordThatStartsWithAt,
  getLastWordThatStartsWithHashtag,
  modelNote,
  removeNoteIndicators,
  toggleNoteContext,
  toggleNoteSetContext,
} from "../../../utils/modelNote";
import Button from "../../buttons/Button";
import ButtonDelete from "../../buttons/ButtonDelete";
import InputCheckbox from "../../inputs/InputCheckbox";
import InputQuill from "../../inputs/InputQuill";
import InputCombo from "../../inputs/InputCombo";

const getStartingNote = (noteObj, users) => {
  let note = noteObj.note;
  if (noteObj.isTask) {
    note = `${note} --task`;
  }
  if (noteObj.isDecision) {
    note = `${note} --decision`;
  }
  if (noteObj.priority) {
    note = `${note} !!${noteObj.priority}`;
  }
  /* if (noteObj.status) {
    note = `${note} //${noteObj.status}`;
  } */
  if (noteObj.hashtags) {
    noteObj.hashtags.forEach((hashtag) => {
      note = `${note} ${hashtag}`;
    });
  }
  if (noteObj.assignees) {
    noteObj.assignees.forEach((assignee) => {
      const assUser = users.find((user) => user.userId === assignee);
      note = `${note} @@${assUser.tag}`;
    });
  }
  if (noteObj.dueDate) {
    const timestampToISO = new Date(noteObj.dueDate).toLocaleDateString();
    note = `${note} -d-${timestampToISO}`;
  }
  return note;
};

export default function ModalNote(props) {
  const {
    note,
    isOpen,
    handleClose,
    handleUpdate,
    isCreate,
    handleCreate,
    handleCreateWithHashtags,
    handleUpdateWithHashtags,
    setToastMessage,
    users,
    tags,
    hashtags,
  } = props;
  const [newNote, setNewNote] = useState(getStartingNote(note, users));
  const [newDescription, setNewDescription] = useState(note.description);
  const [isContinueChecked, setIsContinueChecked] = useState(false);
  const [isCopyChecked, setIsCopyChecked] = useState(true);
  const [isCreateChecked, setIsCreateChecked] = useState(true);
  const action = isCreate ? "Create" : "Update";

  const handleFeedback = () => {
    const message = `${action} note successful`;
    setToastMessage(message);
    if (!isCreate) {
      handleClose();
      return;
    }
    if (isCopyChecked) {
      const removedIndicatorNote = removeNoteIndicators(newNote);
      setNewNote(removedIndicatorNote);
    } else {
      setNewNote("");
    }
    setNewDescription("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = modelNote(newNote, users);
    const noteHashtags = obj.hashtags;
    const newHashtags = noteHashtags.filter(
      (hashtag) => !hashtags.includes(hashtag)
    );
    if (isCreateChecked && newHashtags.length > 0 && isCreate) {
      obj.description = newDescription ?? "";
      handleCreateWithHashtags(obj, newHashtags).then(() => handleFeedback());
    } else if (isCreate) {
      obj.description = newDescription ?? "";
      handleCreate(obj).then(() => handleFeedback());
    } else {
      const { isTask, isDecision, priority, status, hashtags, dueDate } = obj;
      const updatedNoteObj = {
        ...note,
        ...obj,
        description: newDescription,
        isTask,
        isDecision,
        priority,
        status,
        hashtags,
        dueDate,
      };
      if (newHashtags.length < 1) {
        handleUpdate(note.id, updatedNoteObj, note.creator.email).then(() =>
          handleFeedback()
        );
      } else {
        handleUpdateWithHashtags(note.id, updatedNoteObj, newHashtags).then(
          () => handleFeedback()
        );
      }
    }
  };

  const handleDelete = () => {
    const deleteObj = { prop: "isDeleted", value: true };
    handleClose();
    props.handleDelete(note.id, deleteObj, note);
  };

  const toggleContext = (context) => {
    toggleNoteContext(context, newNote, setNewNote);
  };

  const toggleSetContext = (context, number) => {
    toggleNoteSetContext(context, newNote, setNewNote, number);
  };

  const title = isCreate ? "Create a new note" : note.note;
  const hasHashtag = newNote?.includes("#");
  const checkboxes = [
    {
      value: isContinueChecked,
      setValue: setIsContinueChecked,
      name: "continue-without-hashtag",
      label: "Continue without hashtag",
      disabled: hasHashtag,
    },
    {
      value: isCopyChecked,
      setValue: setIsCopyChecked,
      name: "keep-hashtags",
      label: "Keep hashtags on submission",
      disabled: !isCreate,
      isHidden: !isCreate,
    },
    {
      value: isCreateChecked,
      setValue: setIsCreateChecked,
      name: "create-hashtags",
      label: "Create new hashtags on submission",
      disabled: !isCreate,
      isHidden: !isCreate,
    },
  ];

  const handleSelect = (item) => {
    const { title } = item;
    const getLastWord =
      tagType === "#"
        ? getLastWordThatStartsWithHashtag
        : getLastWordThatStartsWithAt;
    const lastTag = getLastWord(newNote);
    const updatedNote = newNote.replace(new RegExp(lastTag + "$"), `${title} `);
    setNewNote(updatedNote);
  };

  const hashtagOptions = tags.map((tag) => {
    return { title: tag };
  });

  const userOptions = users.map((user) => {
    return { title: `@@${user.tag}`, id: user.userId };
  });

  const tagType = checkForHashtagBeforeSpace(newNote);
  const comboOptions = tagType === "@" || tagType === "@@" ? userOptions : hashtagOptions;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full md:max-w-7xl sm:my-8 sm:max-w-sm sm:p-6">
                <div>
                  {!isCreate && (
                    <div className="w-full flex justify-end">
                      <ButtonDelete handleClick={() => handleDelete()} />
                    </div>
                  )}
                  <h1 className="text-center font-bold">{title}</h1>
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    className="my-5 max-w-5xl mx-auto"
                  >
                    {/* <InfoPanel /> */}
                    <InputCombo
                      query={newNote}
                      setQuery={setNewNote}
                      label={"Note info"}
                      options={comboOptions}
                      handleSelect={handleSelect}
                      type={tagType}
                    />
                    <InputQuill
                      placeholder="Note description"
                      value={newDescription}
                      setValue={setNewDescription}
                      name="note-description"
                    />
                    <div className="my-1">
                      <Toolbar
                        handleToggleSet={toggleSetContext}
                        handleToggle={toggleContext}
                        note={newNote}
                        users={users}
                        tags={tags}
                      />
                    </div>
                    <InputCheckbox
                      inputs={checkboxes.filter((box) => !box.isHidden)}
                      flexDirection="flex"
                    />
                    <div className="flex justify-end m-5 w-auto">
                      <Button
                        disabled={!hasHashtag && !isContinueChecked}
                        type="submit"
                        message={action}
                      />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
