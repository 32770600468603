import { HomeIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";

export default function Breadcrumbs(props) {
  const crumbs = props.crumbs ?? [];
  const { isAlt } = props;
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url);
  };
  const text = isAlt ? "text-violet-700" : "text-violet-700";

  return (
    crumbs.length > 0 && (
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex space-x-4 bg-none pr-6 mr-2  ">
          <li
            onClick={() => handleNavigate("/user")}
            role="button"
            aria-label={`Go to Home`}
            className="flex cursor-pointer"
          >
            <div className="flex items-center">
              <span className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </span>
            </div>
          </li>
          {crumbs.map((crumb) => (
            <li
              onClick={crumb.handleClick ?? void 0}
              role="button"
              aria-label={`Go to ${crumb.name}`}
              key={crumb.name}
              className="flex cursor-pointer"
            >
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <span
                  className={`ml-4 text-sm font-medium  hover:text-gray-700 ${
                    crumb.current ? text : "text-gray-500"
                  }`}
                  aria-current={crumb.current ? "page" : undefined}
                >
                  {crumb.name}
                </span>
              </div>
            </li>
          ))}
          <svg
            className="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
        </ol>
      </nav>
    )
  );
}
