import React from "react";

function InputSingle(props) {
  const { cypressId, label, name, value, setValue } = props;

  const type = props.type ?? "text";
  const required = props.isRequired ?? false;
  const placeholder = props.placeholder ?? false;
  const margin = props.margin ?? "m-0";
  const readOnly = props.readOnly ?? false;
  const readerClass = props.isLabelHidden ? "sr-only" : "";
  return (
    <div className={margin}>
      <label
        htmlFor={name}
        className={
          "block text-sm font-medium text-gray-900 text-left " + readerClass
        }
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          readOnly={readOnly}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={type}
          name={name}
          id={name}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
}

export default InputSingle;
