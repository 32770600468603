export function sortNotes(type, notes) {
  if (type === "created-asc") {
    return notes.sort((a, b) => {
      return new Date(a.createdTimestamp) - new Date(b.createdTimestamp);
    });
  } else if (type === "created-desc") {
    return notes.sort((a, b) => {
      return new Date(b.createdTimestamp) - new Date(a.createdTimestamp);
    });
  }
  if (type === "date-asc") {
    return notes.sort((a, b) => {
      return new Date(a.dueDate) - new Date(b.dueDate);
    });
  } else if (type === "date-desc") {
    return notes.sort((a, b) => {
      return new Date(b.dueDate) - new Date(a.dueDate);
    });
  } else if (type === "priority") {
    notes = notes.map((item) => ({
      ...item,
      priorityNumber: getNumberForPriority(item.priority),
    }));
    return notes.sort((a, b) => {
      return b.priorityNumber - a.priorityNumber;
    });
  } else if (type === "task-complete") {
    return notes.sort((a, b) => {
      return b.isComplete - a.isComplete;
    });
  } else if (type === "task-incomplete") {
    return notes.sort((a, b) => {
      return a.isComplete - b.isComplete;
    });
  }
  return notes;
}

function getNumberForPriority(priority) {
  if (!priority) {
    return 0;
  } else if (priority === "low") {
    return 1;
  } else if (priority === "medium") {
    return 2;
  } else if (priority === "high") {
    return 3;
  }
}
