import React, { useState } from "react";
import Calendar from "react-calendar";

function Button(props) {
  const {
    icon,
    message,
    label,
    isRight,
    isMiddle,
    isHighlighted,
    highlightColor,
  } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const disabled = props.disabled ?? false;
  let corner = isRight ? "rounded-r-md" : "rounded-l-md";
  let borderLeft = isRight || isMiddle ? "border-l-0" : "";
  corner = isMiddle ? "" : corner;
  const bg = isHighlighted
    ? "bg-gray-100 hover:bg-gray-150"
    : "bg-white hover:bg-gray-50";
  const text = isHighlighted ? highlightColor : "text-gray-700";

  const handleSelect = (date) => {
    const dateTimestamp = new Date(date).getTime();
    let dateFormatted = new Date(dateTimestamp).toLocaleDateString();
    dateFormatted = `-d-${dateFormatted}`;
    props.handleSelect(dateFormatted, 3);
    setIsCalendarOpen(false);
  };

  return (
    <div className="relative w-1/6 min-w-6">
      {isCalendarOpen && (
        <div className="absolute left-0 z-20 bottom-10">
          <Calendar onChange={handleSelect} />
        </div>
      )}
      <button
        disabled={disabled}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        aria-label={label ?? message}
        type="button"
        className={`w-full relative flex justify-center items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 ${corner} ${borderLeft} ${bg} ${text}`}
      >
        {icon && icon}
        <span className="hidden lg:block">{message}</span>
      </button>
    </div>
  );
}

export default Button;
