import React from "react";

function Button(props) {
  const {
    icon,
    message,
    label,
    isRight,
    isMiddle,
    isHighlighted,
    highlightColor,
  } = props;
  const disabled = props.disabled ?? false;
  const handleClick = props.handleClick ?? (() => {});
  let corner = isRight ? "rounded-r-md" : "rounded-l-md";
  let borderLeft = isRight || isMiddle ? "border-l-0" : "";
  corner = isMiddle ? "" : corner;
  const bg = isHighlighted
    ? "bg-gray-100 hover:bg-gray-150"
    : "bg-white hover:bg-gray-50";
  const text = isHighlighted ? highlightColor : "text-gray-700";
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      aria-label={label ?? message}
      type="button"
      className={`min-w-6 w-1/6 flex justify-center min-w-6 relative items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-violet-500 focus:outline-none focus:ring-1 focus:ring-violet-500 ${corner} ${borderLeft} ${bg} ${text}`}
    >
      {icon && icon}
      <span className="hidden lg:block">{message}</span>
    </button>
  );
}

export default Button;
