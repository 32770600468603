import { format, addDays, addMonths } from "date-fns";

export function getNewDate(numberOfDays) {
  const now = new Date();
  let newDate = addDays(now, parseInt(numberOfDays));
  return format(newDate, "yyyy-MM-dd");
}

export function getNewDateFromDate(date, numberOfDays) {
  const now = new Date(date);
  let newDate = addDays(now, parseInt(numberOfDays));
  return format(newDate, "yyyy-MM-dd");
}

export function formatDateToTimestamp(date) {
  const formattedDate = format(new Date(date), "yyyy-dd-MM");
  return new Date(formattedDate).getTime();
}

export function formatDateToTimestampFromCalendar(date) {
  const fullDate = getDateFromCalendarWithLocale(date);
  return new Date(fullDate).getTime();
}

export function getDateFromCalendarWithLocale(date) {
  const formattedDate = date.toLocaleString();
  const isUSFormat = formattedDate.includes("/");
  const month = isUSFormat ? `${date[0]}${date[1]}` : `${date[0]}${date[1]}`;
  const day = isUSFormat ? `${date[3]}${date[4]}` : `${date[0]}${date[1]}`;
  let year;
  if (date.length < 8) {
    year = new Date().getFullYear();
  } else {
    year = `${date[6]}${date[7]}${date[8]}${date[9]}`;
  }
  const fullDate = `${year}-${day}-${month}`;
  return fullDate;
}

function calculateTimeSince(date) {
  let seconds = Math.floor(new Date().getTime() / 1000 - date);
  let interval = seconds / 31536000;

  if (interval >= 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function getTimeSince(date) {
  return calculateTimeSince(new Date(date).getTime() / 1000);
  //var aDay = 24 * 60 * 60 * 1000;
  //console.log(timeSince(new Date(Date.now() - aDay)));
  //If want to get calculation from other dates
}

const calendarFormat = {
  weekday: "long",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
};

const shortCalendarFormat = {
  month: "numeric",
  day: "numeric",
};

export function getCalendarFormat(date) {
  return new Date(date).toLocaleString("en-GB", calendarFormat);
}

export function getShortCalendarFormat(date) {
  return new Date(date).toLocaleString("en-GB", shortCalendarFormat);
}

//get current month
//Check how many days in the month
//Check how many days in the previous month
//Check what day of the week the first day of the month is
// 0 = Monday, 1 = Tuesday, 2 = Wednesday, 3 = Thursday, 4 = Friday, 5 = Saturday 6 = Sunday
//Previous month days = for loop, previous month days - dayIndex loop to end of month
//42 days total
// Next month days = 42 - current month days + previous month days
//Create an array of days in the previous month
//Create an array of days in the next month
//Concatenate the arrays

// Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
// but by using 0 as the day it will give us the last day of the prior
// month. So passing in 1 as the month number will return the last day
// July

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getCalendarDays(month, year, tasks) {
  const daysInCurrentMonth = daysInMonth(month, year);
  const daysInPreviousMonth = daysInMonth(month - 1, year);
  let firstDayOfMonth = new Date(year, month - 1, 0).getDay();
  const todayString = format(new Date(), "yyyy-MM-dd");
  let tasksWithDates = tasks.filter((task) => task.dueDate);
  tasksWithDates = tasksWithDates.map((task) => ({
    ...task,
    formattedDate: getFormattedDate(task.dueDate),
  }));

  let previousMonthDays = [];
  let nextMonthDays = [];
  let currentMonthDays = [];

  for (
    let i = daysInPreviousMonth - firstDayOfMonth + 1;
    i <= daysInPreviousMonth;
    i++
  ) {
    const dateString = i < 10 ? `0${i}` : i;
    let monthString = month - 1 < 10 ? `0${month - 1}` : month - 1;
    let tempYear = year;
    if (monthString === "00") {
      monthString = "12";
      tempYear = year - 1;
    }
    const date = `${tempYear}-${monthString}-${dateString}`;
    previousMonthDays.push(date);
  }

  for (let i = 1; i <= daysInCurrentMonth; i++) {
    const dateString = i < 10 ? `0${i}` : i;
    const monthString = month < 10 ? `0${month}` : month;
    const date = `${year}-${monthString}-${dateString}`;
    currentMonthDays.push(date);
  }

  for (
    let i = 1;
    i <= 42 - daysInCurrentMonth - previousMonthDays.length;
    i++
  ) {
    const parsedMonth = parseInt(month) + 1;
    const dateString = i < 10 ? `0${i}` : i;
    let monthString = parsedMonth < 10 ? `0${parsedMonth}` : parsedMonth;
    let yearString = year;
    if (monthString.toString() === "13") {
      monthString = "01";
      yearString = parseInt(year) + 1;
    }
    const date = `${yearString}-${monthString}-${dateString}`;
    nextMonthDays.push(date);
  }

  previousMonthDays = previousMonthDays.map((item) => {
    const dailyTasks = tasksWithDates.filter(
      (task) => task.formattedDate === item
    );
    return {
      hasTask: dailyTasks.length > 0,
      isOverdue: dailyTasks.find(
        (task) => task.isComplete === false && checkIfDateIsInPast(task.dueDate)
      ),
      dailyTasks,
      isOutstanding: !dailyTasks.find((task) => task.isComplete === false),
      isToday: item === todayString,
      date: item,
      isCurrentMonth: false,
    };
  });

  currentMonthDays = currentMonthDays.map((item) => {
    const dailyTasks = tasksWithDates.filter(
      (task) => task.formattedDate === item
    );
    return {
      hasTask: dailyTasks.length > 0,
      isOverdue: dailyTasks.find(
        (task) => task.isComplete === false && checkIfDateIsInPast(task.dueDate)
      ),
      dailyTasks,
      isOutstanding: !dailyTasks.find((task) => task.isComplete === false),
      isToday: item === todayString,
      date: item,
      isCurrentMonth: true,
    };
  });

  nextMonthDays = nextMonthDays.map((item) => {
    const dailyTasks = tasksWithDates.filter(
      (task) => task.formattedDate === item
    );
    return {
      hasTask: dailyTasks.length > 0,
      isOverdue: dailyTasks.find(
        (task) => task.isComplete === false && checkIfDateIsInPast(task.dueDate)
      ),
      dailyTasks,
      isOutstanding: !dailyTasks.find((task) => task.isComplete === false),
      isToday: item === todayString,
      date: item,
      isCurrentMonth: false,
    };
  });

  return [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
}

//Get month from today
//Get month index from today
//Get year from today
export function getMonthInfo(months) {
  let today = new Date();
  if (months) {
    today = addMonths(today, parseInt(months));
  }
  const month = today.toLocaleString("default", { month: "long" });
  const monthIndex = parseInt(today.getMonth()) + 1;
  const year = today.getFullYear();
  return { month, monthIndex, year };
}

export function getMonthName(monthIndex) {
  monthIndex = parseInt(monthIndex) - 1;
  return new Date(2000, monthIndex).toLocaleString("default", {
    month: "long",
  });
}

function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  return format(date, "yyyy-MM-dd");
}

export function getFormattedDate(date) {
  return formatDateFromTimestamp(date);
}

export function getFormattedDates(dates) {
  return dates.map((date) => formatDateFromTimestamp(date));
}

export function checkIfDateIsInPast(date) {
  const today = new Date();
  const dateToCheck = new Date(date);
  return dateToCheck < today;
}

export function getCalendarTasks(tasks, month, year) {
  let firstDay = getFirstDayOfMonth(month, year);
  let lastDay = getLastDayOfMonth(month, year);
  firstDay = new Date(getNewDateFromDate(firstDay, -7)).getTime();
  lastDay = new Date(getNewDateFromDate(lastDay, 7)).getTime();
  const tasksBetweenDates = tasks.filter(
    (task) => task.dueDate >= firstDay && task.dueDate <= lastDay
  );
  return tasksBetweenDates;
}

export function getFirstDayOfMonth(month, year) {
  return new Date(year, month - 1, 1);
}

export function getLastDayOfMonth(month, year) {
  return new Date(year, month, 0);
}

//daysInMonth(7,2009);
// of January, not February
