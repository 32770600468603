import logo from "../../../images/logo-full.png";

export function Logo(props) {
  return (
    <div className="flex items-center justify-center">
      <img src={logo} alt="Logo" className="h-8 w-auto sm:h-10" {...props} />
      <span className="text-indigo-600 text-bold text-xl ml-2">SamePage</span>
    </div>
  );
}
