import { db, firebase } from "../firebase";
import { createStream } from "./StreamService";

export function createNotespace(workspaceId, userInfo, obj) {
  let notespaceId = "";
  const { title, description, type } = obj;
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const creatorUser = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    type: "Owner",
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("notespaces")
    .add({
      title,
      description,
      type,
      streamRefs: [],
      streamOrderRefs: [],
      meetingTimes: [],
      nextMeetingAgendaItems: [],
      users: [creatorUser],
      workspaceId,
      isAllNotes: true,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      owner: creator,
    })
    .then((doc) => {
      notespaceId = doc.id;
      const obj = { notespaceId, title, type };
      const userObj = { notespaceId, title, type, role: "Owner" };
      return db
        .collection("workspaces")
        .doc(workspaceId)
        .update({
          notespaces: firebase.firestore.FieldValue.arrayUnion(obj),
        })
        .then(() => {
          return db
            .collection("users")
            .doc(userInfo.id)
            .update({
              notespaces: firebase.firestore.FieldValue.arrayUnion(userObj),
            });
        })
        .then(() => {
          const streamObj = {
            title: "Notes in " + title,
            description: "All notes created in " + title,
            conditions: [
              {
                operation: "=",
                prop: "createdInNotespace",
                value: notespaceId,
              },
            ],
          };
          return createStream(
            workspaceId,
            notespaceId,
            userInfo,
            streamObj
          ).then(() => {
            return notespaceId;
          });
        });
    });
}

export function updateNotespace(workspaceId, notespaceId, userInfo, obj) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("notespaces")
    .doc(notespaceId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateNotespaceForm(workspaceId, notespaceId, userInfo, obj) {
  const { title, description } = obj;
  const userId = userInfo.id;
  const updateMessage =
    obj.updateMessage ??
    `New title: ${title}, New description: ${description} `;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("notespaces")
    .doc(notespaceId)
    .update({
      title,
      description,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}
