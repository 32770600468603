import { db, firebase } from "../firebase";
import { getInitialsFromEmail } from "../utils/getInitials";

export function createWorkspace(userInfo, workspaceObj) {
  const userId = userInfo.id;
  const userUid = userInfo.uid;
  const userObj = {
    userId,
    userUid,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
    type: "Admin",
    tag: getInitialsFromEmail(userInfo.email),
  };

  const ownerObj = {
    userId,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };

  let title = workspaceObj.title ?? "";
  const description = workspaceObj.description ?? "";
  let docId;
  return db
    .collection("workspaces")
    .add({
      title,
      description,
      tags: [],
      streamRefs: [],
      streamOrderRefs: [],
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      owner: ownerObj,
      creator: ownerObj,
      biller: ownerObj,
      userActiveStreams: [],
      isPrivateDataSet: true,
    })
    .then((doc) => {
      docId = doc.id;
      return createWorkspacePrivateData(docId, [userObj]);
    })
    .then(() => {
      const workspaceObj = {
        title,
        workspaceId: docId,
        createdTimestamp: Date.now(),
      };
      return db
        .collection("users")
        .doc(userId)
        .update({
          workspaces: firebase.firestore.FieldValue.arrayUnion(workspaceObj),
        })
        .then(() => {
          return docId;
        });
    });
}

export function createWorkspacePrivateData(workspaceId, users, invitesArr) {
  const invites = invitesArr ?? [];
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("private")
    .doc("data")
    .set({ users, invites });
}

export function updateWorkspace(workspaceId, userInfo, obj) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updaterId: userId,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function deleteWorkspace(workspaceId, userInfo) {
  const userId = userInfo.id;
  const deleteObj = {
    deletedTimestamp: Date.now(),
    deleterId: userId,
  };
  return db.collection("workspaces").doc(workspaceId).update({
    isDeleted: true,
    deleteObj,
  });
}

export function createWorkspaceInvite(workspaceObj, userInfo, obj) {
  const { title, workspaceId } = workspaceObj;
  const { email, type } = obj;
  return db
    .collection("invites")
    .doc(email)
    .collection("invites")
    .add({
      workspaceTitle: title,
      workspaceId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      type,
      email,
      inviter: {
        name: userInfo.name,
        email: userInfo.email,
      },
      status: "pending",
      isAccepted: false,
    })
    .then((doc) => {
      const inviteObj = {
        invited: email,
        status: "pending",
        createdTimestamp: Date.now(),
        type,
        email,
        inviter: {
          name: userInfo.name,
          email: userInfo.email,
        },
        inviteId: doc.id,
      };
      return db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          invites: firebase.firestore.FieldValue.arrayUnion(inviteObj),
        })
        .then(() => {
          return db
            .collection("mail")
            .add({
              to: email,
              message: {
                subject: `${userInfo.name} would like you to join their workspace in SamePage`,
                html: `<h1>${userInfo.name} would like you to join SamePage</h1><p>Exciting things ahead. You've been asked to join a workspace in SamePage. In order to join please follow the link below in and create an account with this address to access the space.</p><p><a href="https://wwww.samepage.tech">Click me to go to SamePage</a></p>`,
              },
            })
            .then(() => console.log("Queued email for delivery!"));
        });
    });
}

export function acceptWorkspaceInvite(userInfo, inviteObj) {
  const { inviteId, type, workspaceId, workspaceTitle } = inviteObj;
  const userId = userInfo.id;
  const userUid = userInfo.uid;
  return db
    .collection("invites")
    .doc(userInfo.email)
    .collection("invites")
    .doc(inviteId)
    .update({
      isAccepted: true,
      status: "accepted",
    })
    .then(() => {
      const userObj = {
        userId,
        userUid,
        email: userInfo.email,
        name: userInfo.name,
        createdTimestamp: Date.now(),
        type,
        tag: getInitialsFromEmail(userInfo.email),
      };
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(userObj),
        });
    })
    .then(() => {
      const workspaceObj = {
        title: workspaceTitle,
        workspaceId,
        createdTimestamp: Date.now(),
      };
      db.collection("users")
        .doc(userId)
        .update({
          workspaces: firebase.firestore.FieldValue.arrayUnion(workspaceObj),
        });
    });
}

export function updateWorkspaceInviteStatus(workspaceId, userInfo, obj) {
  const { inviteId, invitedEmail, status, invites } = obj;
  const updateObj = {
    status,
    updater: {
      id: userInfo.id,
      name: userInfo.name,
      updatedTimestamp: Date.now(),
    },
  };
  return db
    .collection("invites")
    .doc(invitedEmail)
    .collection("invites")
    .doc(inviteId)
    .update({
      isAccepted: true,
      status,
      updateObj,
    })
    .then(() => {
      return db.collection("workspaces").doc(workspaceId).update({
        invites,
      });
    });
}

export function updateWorkspaceForm(workspaceId, userInfo, obj) {
  const { title, description } = obj;
  const userId = userInfo.id;
  const updateMessage =
    obj.updateMessage ??
    `New title: ${title}, New description: ${description} `;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .update({
      title,
      description,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateWorkspaceActiveStream(workspaceId, obj) {
  const { prop, value } = obj;
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .update({
      [prop]: value,
    });
}
