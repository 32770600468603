import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [["bold", "italic", "underline", "strike"], ["link"]];

function InputQuill(props) {
  const { label, name, value, setValue, placeholder } = props;
  const margin = props.margin ?? "m-0";

  return (
    <div className={margin}>
      <label
        htmlFor="description"
        className="block text-sm text-left font-medium text-gray-900"
      >
        {label}
      </label>
      <div className="mt-1 border rounded-lg overflow-visible z-10 min-h-10">
        <ReactQuill
          key={"overlay-question-box-" + name}
          placeholder={placeholder}
          className="w-100 min-h-20"
          modules={{ toolbar: toolbarOptions }}
          theme="snow"
          value={value}
          onChange={setValue}
        />
      </div>
    </div>
  );
}

export default InputQuill;
