import React, { useEffect, useState } from "react";
import { auth, db, logout } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router";
import Slideout from "../../components/slideouts/SlideoutNewNotespace";
import { getNaviagtionItems } from "../../utils/getNavigationItems";
import PageShell from "../PageShell";
import WorkspaceSectionNotespaces from "./WorkspaceSectionNotespaces";
import { createNotespace } from "../../services/NotespaceService";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  createStream,
  createUserStream,
  updateHiddenStreams,
  updateStream,
  updateStreamFull,
  updateUserStream,
  updateUserStreamFull,
} from "../../services/StreamService";
import WorkspaceSectionTeam from "./WorkspaceSectionTeam";
import AddMemberSlideout from "../../components/slideouts/SlideoutAddMember";
import {
  createWorkspaceInvite,
  createWorkspacePrivateData,
  deleteWorkspace,
  updateWorkspace,
  updateWorkspaceActiveStream,
  updateWorkspaceForm,
  updateWorkspaceInviteStatus,
} from "../../services/WorkspaceService";
import LoadingPageShell from "../../components/shared/loading/LoadingPageShell";
import WorkspaceSectionSettings from "./WorkspaceSectionSettings";
import Toast from "../../components/shared/toasts/Toast";
import WorkspaceSectionCalendar from "./WorkspaceSectionCalendar";
import ModalNote from "../../components/shared/modals/ModalNote";
import {
  noteTemplate,
  streamTemplate,
  tempStreamTemplate,
} from "../../utils/getTemplates";
import {
  createNote,
  updateNote,
  updateNoteFull,
} from "../../services/NoteService";
import {
  createHashtag,
  createHashtagBatch,
  updateHashtag,
  updateHashtagFull,
} from "../../services/HashtagService";
import Button from "../../components/buttons/Button";
import { removeWorkspace, updateTitle } from "../../utils/syncData";
import ModalMessage from "../../components/shared/modals/ModalMessage";
import WorkspaceSectionStreams from "./WorkspaceSectionStreams";
import NewStreamSlideout from "../../components/slideouts/SlideoutNewStream";
import ModalStreamPositions from "../../components/shared/modals/modal-stream-positions/ModalStreamPositions";
import UpdateStreamSlideout from "../../components/slideouts/SlideoutUpdateStream";
import LoadingSpinner from "../../components/shared/loading/LoadingSpinner";
import Alert from "../../components/shared/alerts/Alert";
import ModalSearchBar from "../../components/shared/modals/modal-search/ModalSearchBar";
import NotespaceSectionDecisions from "../notespace-dashboard/NotespaceSectionDecisions";
import { deleteWorkspaceEntity } from "../../services/SharedService";
import WorkspaceAsideSelection from "./WorkspaceAsideSelection";
import {
  createStreamFromHashtag,
  createTempStream,
} from "../../utils/modelStream";
import { createCollection } from "../../services/CollectionService";
import NewCollectionSlideout from "../../components/slideouts/SlideoutNewCollection";
import { getIcon } from "../../utils/getIcons";
import ModalManageStream from "../../components/shared/modals/ModalManageStream";
import ModalOnboarding from "../../components/shared/modals/ModalOnboarding";

function WorkspaceDashboard() {
  const { workspaceId } = useParams();
  const [user, authLoading] = useAuthState(auth);
  const [isPrivateDataLoading, setIsPrivateDataLoading] = useState(true);
  const [privateData, setPrivateData] = useState(false);
  const [activeView, setActiveView] = useState("streams");
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [workspace, setWorkspace] = useState(false);
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(true);
  const [isWorkpaceSynced, setIsWorkpaceSynced] = useState(false);
  const [isWorkspaceDeleted, setIsWorkspaceDeleted] = useState(false);
  const [activeNotespaceId, setActiveNotespaceId] = useState(false);
  const [isCreateNoteOpen, setIsCreateNoteOpen] = useState(false);
  const [activeNoteId, setActiveNoteId] = useState(false);
  const [activeStreamId, setActiveStreamId] = useState(false);
  const [invites, setInvites] = useState([]);
  const [userStreams, setUserStreams] = useState([]);
  const [isUserStreamsLoading, setIsUserStreamsLoading] = useState(true);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isRearrangeStreamOpen, setIsRearrangeStreamOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [asideActive, setAsideActive] = useState("filter");
  const [isManageStreamOpen, setIsManageStreamOpen] = useState(false);
  const [tempStreamConditions, setTempStreamConditions] = useState([]);
  const [openSections, setOpenSections] = useState(["hashtags"]);
  const [showAllHashtags, setShowAllHashtags] = useState(false);

  let notes = [];
  let streams = [];
  let tags = [];
  let collections = [];
  let users = [];
  let meetings = [];

  if (!isWorkspaceLoading && !isPrivateDataLoading) {
    users = privateData.users;
    if (workspace.users) {
      const privateDataUserIds = privateData.users.map((item) => item.userId);
      const workspaceUsers = workspace.users.filter((item) =>
        !privateDataUserIds.includes(item.userId)
      );
      users = workspaceUsers.concat(privateData.users);
    }
    if (!users) {
      users = [];
    }
  }

  if (!isWorkspaceLoading) {
    if (!workspace.isPrivateDataSet) {
      createWorkspacePrivateData(
        workspaceId,
        workspace.users,
        workspace.invites
      );
    }
  }

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        setToastMessage("");
      }, 3000);
    }
  }, [toastMessage]);

  useEffect(() => {
    setIsSpinning(false);
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  const [noteValue, noteLoading] = useCollection(
    db
      .collection("notes")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (noteValue && !noteLoading) {
    notes = noteValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "note";
      return item;
    });
  }

  const [streamValue, streamLoading] = useCollection(
    db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("streams")
      .where("isDeleted", "==", false),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (streamValue && !streamLoading) {
    streams = streamValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "stream";
      return item;
    });
  }

  const [meetingValue, meetingLoading] = useCollection(
    db
      .collection("meetings")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (meetingValue && !meetingLoading) {
    meetings = meetingValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "stream";
      return item;
    });
  }

  const [tagValue, tagLoading] = useCollection(
    db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("hashtags")
      .where("isDeleted", "==", false),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (tagValue && !tagLoading) {
    tags = tagValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "tag";
      return item;
    });
  }

  const [collectionValue, collectionLoading] = useCollection(
    db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("collections")
      .where("isDeleted", "==", false),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (collectionValue && !collectionLoading) {
    collections = collectionValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "collection";
      return item;
    });
  }

  const fetchUser = async () => {
    try {
      db.collection("users")
        .where("uid", "==", user?.uid)
        .onSnapshot((value) => {
          const doc = value.docs[0];
          const data = doc.data();
          data.ref = doc.id;
          data.id = doc.id;
          setUserInfo(data);
          //window.heap.identify(user.uid, "uid");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserStreams = async (id) => {
    //Only adding one item and not as array

    try {
      db.collection("users")
        .doc(id)
        .collection("streams")
        .where("isDeleted", "==", false)
        .onSnapshot((value) => {
          let userStreamsArr = [];
          value.docs.forEach((doc) => {
            const data = doc.data();
            data.ref = doc.id;
            data.id = doc.id;
            data.type = "stream";
            data.streamType = "personal";
            userStreamsArr.push(data);
          });
          userStreamsArr = userStreamsArr.filter(
            (item) => item.workspaceId === workspaceId
          );
          setUserStreams(userStreamsArr);
          setIsUserStreamsLoading(false);
          //window.heap.identify(user.uid, "uid");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspace = async () => {
    if (!isWorkspaceLoading) {
      return;
    }
    try {
      await fetchWorkspacePrivateData(workspaceId);
      db.collection("workspaces")
        .doc(workspaceId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setWorkspace(data);
          setIsWorkspaceLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspacePrivateData = async () => {
    if (!isPrivateDataLoading) {
      return;
    }
    try {
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .onSnapshot((doc) => {
          const data = doc.data();
          if (!data) {
            setIsPrivateDataLoading(false);
            setInvites([]);
            setPrivateData({ users: [] });
            return;
          }
          data.id = doc.id;
          let workspaceInvites = [];
          const allWorksaceEmails = data.users.map((item) => item.email);
          workspaceInvites = data.invites?.filter(
            (item) =>
              item.status === "pending" &&
              !allWorksaceEmails.includes(item.invited)
          );
          setInvites(workspaceInvites);
          setPrivateData(data);
          setIsPrivateDataLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchWorkspace();
    //eslint-disable-next-line
  }, [isWorkspaceLoading]);

  useEffect(() => {
    if (userInfo && isUserStreamsLoading) {
      fetchUserStreams(userInfo.id);
    }
    //eslint-disable-next-line
  }, [isUserStreamsLoading, userInfo]);

  const getActiveView = () => {
    switch (activeView) {
      case "streams":
        return (
          <WorkspaceSectionStreams
            hashtags={tags}
            setIsCreateOpen={setIsCreateOpen}
            setIsCreateNoteOpen={setIsCreateNoteOpen}
            streams={orderedStreams}
            userStreams={userStreams}
            handleUpdateHiddenStreams={handleUpdateHiddenStreams}
            handleSelectNote={setActiveNoteId}
            notes={notes}
            handleRearrange={setIsRearrangeStreamOpen}
            setActiveStreamId={setActiveStreamId}
            handleUpdateStream={handleUpdateStream}
            handleUpdateUserStream={handleUpdateUserStream}
            handleUpdateNote={handleUpdateNote}
            users={users}
            asideActive={asideActive}
            setAlert={setAlert}
            handleClearStreams={handleClearUserActiveStreams}
            isAllShowing={!userInfo.hiddenStreams?.includes(workspaceId)}
            tempStreamConditions={tempStreamConditions}
          />
        );
      case "pages":
        return (
          <WorkspaceSectionNotespaces
            notespaces={workspace.notespaces.filter((item) => !item.isDeleted)}
            setIsCreateOpen={setIsCreateOpen}
            handleSelect={handleSelectNotespace}
          />
        );
      case "team":
        return (
          <WorkspaceSectionTeam
            members={users}
            setIsCreateOpen={setIsCreateOpen}
            handleCreate={handleCreateInvite}
            handleCancel={handleUpdateInviteStatus}
            invites={invites}
          />
        );
      case "decision":
        return (
          <NotespaceSectionDecisions
            notes={notes}
            users={users}
            handleSelect={setActiveNoteId}
          />
        );
      case "calendar":
        return (
          <WorkspaceSectionCalendar
            tasks={notes.filter((item) => item.isTask)}
            users={users}
            handleSelect={setActiveNoteId}
            handleUpdate={handleUpdateNote}
          />
        );
      case "settings":
        return (
          <WorkspaceSectionSettings
            handleUpdateForm={handleUpdateWorkspaceForm}
            handleUpdateHashtag={handleUpdateHashtag}
            handleUpdate={handleUpdateWorkspace}
            handleDelete={handleDeleteWorkspace}
            handleCreateHashtag={handleCreateHashtag}
            workspace={workspace}
            hashtags={tags}
          />
        );
      default:
        return null;
    }
  };

  const getSecondaryView = () => {
    switch (activeView) {
      case "streams":
        return (
          <WorkspaceAsideSelection
            streams={streams}
            userStreams={userStreams}
            setIsCreateOpen={setIsCreateOpen}
            handleToggle={handleToggleUserActiveStream}
            handleUpdateSet={handleToggleUserActiveStreams}
            userActiveStreams={workspace.userActiveStreams}
            userInfo={userInfo}
            users={users}
            hashtags={tags}
            collections={collections}
            active={asideActive}
            setActive={setAsideActive}
            tempStreamConditions={tempStreamConditions}
            setTempStreamConditions={setTempStreamConditions}
            openSections={openSections}
            setOpenSections={setOpenSections}
            showAllHashtags={showAllHashtags}
            setShowAllHashtags={setShowAllHashtags}
          />
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    if (authLoading) return;
    if (!user) {
      logout();
      return navigate("/login");
    }
    fetchUser();
    //eslint-disable-next-line
  }, [user, authLoading, navigate]);

  const handleFeedback = (toastMessage) => {
    setIsSpinning(false);
    setIsCreateOpen(false);
    setToastMessage(toastMessage);
  };

  const handleCreateNotespace = (obj) => {
    setIsSpinning(true);
    createNotespace(workspaceId, userInfo, obj).then(() =>
      handleFeedback("Notespace created")
    );
  };

  const handleSelectNotespace = (notespaceId) => {
    setActiveNotespaceId(notespaceId);
  };

  const handleCreateStream = (obj, type) => {
    if (type === "personal") {
      return handleCreateStreamUser(obj);
    } else {
      return handleCreateStreamTeam(obj);
    }
  };

  const handleCreateStreamTeam = (obj) => {
    setIsSpinning(true);
    const notespaceId = !activeNotespaceId ? "workspace" : activeNotespaceId;
    try {
      return createStream(workspaceId, notespaceId, userInfo, obj).then(() =>
        handleFeedback("Stream created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateStreamUser = (obj) => {
    setIsSpinning(true);
    const notespaceId = !activeNotespaceId ? "workspace" : activeNotespaceId;
    obj.notespaceId = notespaceId;
    obj.workspaceId = workspaceId;
    try {
      return createUserStream(userInfo, obj).then(() =>
        handleFeedback("Stream created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateStream = (obj, streamId, isFull) => {
    //Need update stream private
    setIsSpinning(true);
    try {
      if (isFull) {
        return updateStreamFull(workspaceId, streamId, userInfo, obj).then(() =>
          handleFeedback("Stream updated")
        );
      } else {
        return updateStream(workspaceId, streamId, userInfo, obj).then(() =>
          handleFeedback("Stream updated")
        );
      }
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateUserStream = (obj, streamId, isFull) => {
    //Need update stream private
    setIsSpinning(true);
    try {
      if (isFull) {
        return updateUserStreamFull(streamId, userInfo, obj).then(() =>
          handleFeedback("Stream updated")
        );
      } else {
        return updateUserStream(streamId, userInfo, obj).then(() =>
          handleFeedback("Stream updated")
        );
      }
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateCollection = (obj) => {
    setIsSpinning(true);
    const notespaceId = !activeNotespaceId ? "workspace" : activeNotespaceId;
    obj.streamRefs = workspace.userActiveStreams.filter(
      (item) => item.userId === userInfo.id
    );
    try {
      return createCollection(workspaceId, notespaceId, userInfo, obj).then(
        () => handleFeedback("Stream created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateHashtag = (obj) => {
    try {
      return createHashtag(workspaceId, userInfo, obj).then(() =>
        setToastMessage("Hashtag created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateHashtag = (obj, hashtagId, isFull) => {
    try {
      if (isFull) {
        return updateHashtagFull(workspaceId, hashtagId, userInfo, obj).then(
          () => setToastMessage("Hashtag updated")
        );
      } else {
        return updateHashtag(workspaceId, hashtagId, userInfo, obj).then(() =>
          setToastMessage("Hashtag updated")
        );
      }
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateInvite = (obj) => {
    setIsSpinning(true);
    const workspaceObj = { title: workspace.title, workspaceId: workspace.id };
    try {
      return createWorkspaceInvite(workspaceObj, userInfo, obj).then(() =>
        handleFeedback("Invite sent")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateInviteStatus = (obj) => {
    const { inviteId, status } = obj;
    const updatedInvites = invites.map((item) => {
      return inviteId === item.inviteId ? { ...item, status } : { ...item };
    });
    obj.invites = updatedInvites;
    try {
      return updateWorkspaceInviteStatus(workspaceId, userInfo, obj);
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateWorkspace = (obj) => {
    setIsSpinning(true);
    try {
      return updateWorkspace(workspaceId, userInfo, obj).then(() =>
        handleFeedback("Workspace updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleToggleUserActiveStream = (streamId, type) => {
    setIsSpinning(true);
    let uaStreams = workspace.userActiveStreams ?? [];
    const userActiveStreamIndex = uaStreams.findIndex(
      (item) =>
        item.userId === userInfo.id &&
        item.streamId === streamId &&
        item.type === type
    );
    if (userActiveStreamIndex > -1) {
      uaStreams.splice(userActiveStreamIndex, 1);
    } else {
      uaStreams.push({ userId: userInfo.id, streamId, type });
    }
    const obj = { prop: "userActiveStreams", value: uaStreams };
    try {
      return updateWorkspaceActiveStream(workspaceId, obj).then(() =>
        handleFeedback("Success")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleToggleUserActiveStreams = (streams) => {
    setIsSpinning(true);
    const obj = { prop: "userActiveStreams", value: streams };
    try {
      return updateWorkspaceActiveStream(workspaceId, obj).then(() =>
        handleFeedback("Success")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleClearUserActiveStreams = () => {
    setIsSpinning(true);
    const obj = { prop: "userActiveStreams", value: [] };
    setTempStreamConditions([]);
    try {
      return updateWorkspaceActiveStream(workspaceId, obj).then(() =>
        handleFeedback("Streams cleared")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateWorkspaceForm = (obj) => {
    setIsSpinning(true);
    try {
      return updateWorkspaceForm(workspaceId, userInfo, obj).then(() =>
        handleFeedback("Space updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateHiddenStreams = (streamId) => {
    updateHiddenStreams(workspaceId, userInfo);
  };

  const handleDeleteWorkspaceEntity = (entityType, docId) => {
    setIsSpinning(true);
    try {
      return deleteWorkspaceEntity(
        workspaceId,
        entityType,
        docId,
        userInfo
      ).then(() => handleFeedback("Entity deleted"));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const isLoading =
    authLoading ||
    !userInfo ||
    isWorkspaceLoading ||
    streamLoading ||
    noteLoading ||
    meetingLoading ||
    tagLoading ||
    collectionLoading ||
    isUserStreamsLoading ||
    isPrivateDataLoading;

  let allStreams = [];
  if (!streamLoading && !isUserStreamsLoading) {
    allStreams = streams.concat(userStreams);
  }

  const workspaceCrumbs = [{ name: workspace.title, current: true }];

  const activeNote = activeNoteId
    ? notes.find((item) => item.id === activeNoteId)
    : false;

  const handleCreateNote = (obj) => {
    setIsSpinning(true);
    const meetingId = false;
    const notespaceId = false;
    try {
      return createNote(
        workspaceId,
        notespaceId,
        meetingId,
        userInfo,
        obj
      ).then(() => handleFeedback("Note created"));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateNoteWithHashtags = (obj, hashtags) => {
    setIsSpinning(true);
    const meetingId = false;
    const notespaceId = false;
    try {
      return createNote(workspaceId, notespaceId, meetingId, userInfo, obj)
        .then(() => createHashtagBatch(workspaceId, userInfo, hashtags))
        .then(() => handleFeedback("Note & Hashtags created"));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateNote = (noteId, obj, note) => {
    try {
      return updateNote(noteId, userInfo, obj, note);
    } catch (err) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateNoteWithHashtags = (noteId, obj, hashtags) => {
    setIsSpinning(true);
    try {
      return updateNoteFull(noteId, userInfo, obj)
        .then(() => createHashtagBatch(workspaceId, userInfo, hashtags))
        .then(() => handleFeedback("Note & Hashtags created"));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateNoteFull = (noteId, obj) => {
    try {
      return updateNoteFull(noteId, userInfo, obj);
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  if (!isWorkspaceLoading && userInfo && !isWorkpaceSynced) {
    updateTitle(workspace.title, userInfo, workspaceId);
    setIsWorkpaceSynced(true);
    if (workspace.isDeleted) {
      setIsWorkspaceDeleted(true);
    }
  }

  const handleRemoveDeletedWorkspace = () => {
    removeWorkspace(workspaceId, userInfo, navigate);
  };

  const handleDeleteWorkspace = () => {
    return deleteWorkspace(workspace.id, userInfo);
  };

  let orderedStreamRefs = [];
  let orderedStreams = [];
  let activeStreams = [];
  let activeStreamRefs = [];
  let activeHashtagStreamRefs = [];

  if (workspace) {
    //Remove ordering, memize the notestreams, and make them in state streams
    const uaStreams = workspace.userActiveStreams ?? [];
    activeStreams = uaStreams;
    const allStreams = streams.concat(userStreams);
    activeStreams = activeStreams.filter(
      (item) => item.userId === userInfo.id && item.type === "stream"
    );
    activeStreamRefs = activeStreams.map((item) => item.streamId);
    activeStreams = activeStreamRefs.map((ref) =>
      allStreams.find((item) => item.id === ref)
    );
    activeStreams = activeStreams.filter(
      (item) => item !== undefined && item !== false
    );
    activeHashtagStreamRefs = uaStreams.filter(
      (item) => item.userId === userInfo.id && item.type === "hashtag"
    );
    activeHashtagStreamRefs = activeHashtagStreamRefs.map(
      (item) => item.streamId
    );
    let hashtagStream = [];
    if (tempStreamConditions?.length > 0) {
      const tempStream = createTempStream(tempStreamConditions);
      orderedStreams.push(tempStream);
    }
    if (activeHashtagStreamRefs.length > 0) {
      const tagsForStream = tags.filter((item) =>
        activeHashtagStreamRefs.includes(item.id)
      );
      hashtagStream = createStreamFromHashtag(tagsForStream);
      orderedStreams.push(hashtagStream);
    }
    orderedStreams.push(...activeStreams);
  }
  let workspaceViews = {};
  if (workspace) {
    const { isCalendarView, isDecisionLogView, isNotespacesFeatureEnabled } =
      workspace;
    workspaceViews = {
      isCalendarView,
      isDecisionLogView,
      isNotespacesFeatureEnabled,
    };
  }

  let uniqueTags = [];
  if (!noteLoading && !tagLoading) {
    tags.forEach((tag) => {
      uniqueTags.push(tag.title);
    });
    notes.forEach((note) => {
      note.hashtags.forEach((tag) => {
        if (!uniqueTags.includes(tag)) {
          uniqueTags.push(tag);
        }
      });
    });
  }

  let activeStream;
  if (activeStreamId) {
    activeStream = allStreams.find((item) => item.id === activeStreamId);
  }

  return isLoading ? (
    <LoadingPageShell isAlt navigationItems={getNaviagtionItems("workspace")} />
  ) : (
    <div className="w-full min-h-screen">
      <>
        <ModalOnboarding
          handleCreateHashtag={handleCreateHashtag}
          handleCreateInvite={handleCreateInvite}
          handleUpdateWorkspace={handleUpdateWorkspace}
          hashtags={tags}
          invites={invites}
          handleClose={() => void 0}
          isOpen={!workspace.isOnboarded}
        />
        <ModalSearchBar
          handleClose={() => setIsSearchOpen(false)}
          isOpen={isSearchOpen === true}
          notes={notes}
          handleSelectNote={setActiveNoteId}
        />
        <ModalManageStream
          isOpen={isManageStreamOpen}
          handleClose={() => setIsManageStreamOpen(false)}
          view={getSecondaryView(activeView)}
        />
        {toastMessage && <Toast message={toastMessage} />}
        {alert && <Alert isTop={true} message={alert} />}
        {isSpinning && <LoadingSpinner />}
        <NewStreamSlideout
          isOpen={isCreateOpen === "stream"}
          setIsOpen={setIsCreateOpen}
          stream={
            asideActive === "filter"
              ? tempStreamTemplate(tempStreamConditions)
              : streamTemplate
          }
          handleCreate={handleCreateStream}
          users={users}
          tags={uniqueTags}
        />
        <UpdateStreamSlideout
          activeId={activeStreamId}
          isOpen={activeStreamId !== false}
          setIsOpen={setActiveStreamId}
          stream={activeStream}
          handleUpdate={
            activeStream?.streamType === "personal"
              ? handleUpdateUserStream
              : handleUpdateStream
          }
          users={users}
          tags={uniqueTags}
        />
        <NewCollectionSlideout
          isOpen={isCreateOpen === "collection"}
          setIsOpen={setIsCreateOpen}
          handleCreate={handleCreateCollection}
          tags={uniqueTags}
        />
        {isWorkspaceDeleted && (
          <ModalMessage
            setIsOpen={() => handleRemoveDeletedWorkspace()}
            isOpen={workspace.isDeleted === true}
            type="error"
            heading={`${workspace.title} has been deleted`}
            message="This workspace has been deleted. You will be redirected to your dashboard."
            action="Go back to dashboard"
            handleAction={() => handleRemoveDeletedWorkspace()}
          />
        )}
        <ModalStreamPositions
          isOpen={isRearrangeStreamOpen === true}
          orderedStreamRefs={orderedStreamRefs}
          handleUpdate={handleUpdateWorkspace}
          handleClose={() => setIsRearrangeStreamOpen(false)}
          streams={orderedStreams}
          streamOrderRefs={workspace.streamOrderRefs}
          title={workspace.title}
        />
        {(activeNote || isCreateNoteOpen) && (
          <ModalNote
            handleDelete={handleUpdateNote}
            handleUpdate={handleUpdateNoteFull}
            handleCreate={handleCreateNote}
            handleCreateWithHashtags={handleCreateNoteWithHashtags}
            handleUpdateWithHashtags={handleUpdateNoteWithHashtags}
            handleClose={
              isCreateNoteOpen
                ? () => setIsCreateNoteOpen(false)
                : () => setActiveNoteId(false)
            }
            setToastMessage={setToastMessage}
            isOpen={activeNote !== false || isCreateNoteOpen}
            note={isCreateNoteOpen ? noteTemplate : activeNote}
            isCreate={isCreateNoteOpen}
            users={users}
            tags={uniqueTags}
            hashtags={tags.map((tag) => tag.title)}
          />
        )}
        <AddMemberSlideout
          setIsOpen={setIsCreateOpen}
          isOpen={isCreateOpen === "invite"}
          handleCreate={handleCreateInvite}
        />
        <Slideout
          setIsOpen={setIsCreateOpen}
          isOpen={isCreateOpen === "notespace"}
          handleCreate={handleCreateNotespace}
        />
        <PageShell
          color={"violet"}
          userInfo={userInfo}
          mainView={getActiveView()}
          activeView={activeView}
          setActiveView={setActiveView}
          navigationItems={getNaviagtionItems("workspace", workspaceViews)}
          crumbs={workspaceCrumbs}
          type={"workspace"}
          handleOpenSearch={setIsSearchOpen}
          secondaryView={getSecondaryView()}
        />{" "}
        <div className="fixed bottom-2 right-2 z-10">
          <Button
            icon={getIcon("note", "h-4 w-4 mr-1")}
            handleClick={() => setIsCreateNoteOpen(true)}
            message={"Create Note"}
            bg={"blue"}
          />
        </div>
        {activeView === "streams" && (
          <div className="fixed bottom-2 left-2 z-10 block lg:hidden">
            <Button
              icon={getIcon("filter")}
              handleClick={() => setIsManageStreamOpen(true)}
              label={"Manage Stream View"}
              bg={"sky"}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default WorkspaceDashboard;
