import { PlusIcon } from "@heroicons/react/20/solid";
import { getIcon } from "../../../utils/getIcons";
import Button from "../../buttons/Button";

export default function EmptyState(props) {
  const { entity, message, buttonMessage, handleClick } = props;
  const buttonColor = props.buttonColor ?? "violet";
  const entityLower = entity.toLowerCase();
  const text = props.isTextSmall ? "text-sm" : "text-base";
  const paddingTop = props.paddingTop ?? "pt-10";
  return (
    <div className={`text-center w-full px-5 ${paddingTop}`}>
      <div className="flex justify-center">
        {getIcon(entityLower, "h-12 w-12 text-gray-400")}
      </div>
      <h3 className="mt-2 font-medium text-gray-900">No {entity}</h3>
      <p className={`mt-1 text-gray-500 max-w-xl m-auto ${text}`}>{message}</p>
      {buttonMessage && (
        <div className="mt-6 flex justify-center">
          <div>
            <Button
              bg={buttonColor}
              message={buttonMessage}
              handleClick={handleClick}
              icon={<PlusIcon className="h-5 w-5 mr-2 -ml-1" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}
