import { db, firebase } from "../firebase";
import { updateUser } from "./UserService";

export function createStream(workspaceId, notespaceId, userInfo, obj) {
  //Create workspaceStream in WorkspaceService
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const { title, description, conditions } = obj;
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("streams")
    .add({
      title,
      description,
      conditions,
      workspaceId,
      notespaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    });
}

export function createUserStream(userInfo, obj) {
  //Create workspaceStream in WorkspaceService
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const { title, description, conditions } = obj;
  const workspaceId = obj.workspaceId ?? false;
  const notespaceId = obj.notespaceId ?? false;
  return db.collection("users").doc(userInfo.id).collection("streams").add({
    title,
    description,
    conditions,
    workspaceId,
    notespaceId,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
  });
}

export function updateStream(workspaceId, streamId, userInfo, obj) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("streams")
    .doc(streamId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateUserStream(streamId, userInfo, obj) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("users")
    .doc(userId)
    .collection("streams")
    .doc(streamId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateStreamFull(workspaceId, streamId, userInfo, obj) {
  const userId = userInfo.id;
  const updateMessage = "Update full stream";
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("streams")
    .doc(streamId)
    .update({
      ...obj,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateUserStreamFull(streamId, userInfo, obj) {
  const userId = userInfo.id;
  const updateMessage = "Update full stream";
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("users")
    .doc(userId)
    .collection("streams")
    .doc(streamId)
    .update({
      ...obj,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export function updateHiddenStreams(workspaceId, userInfo) {
  let userStreams = userInfo.hiddenStreams ?? [];
  const isCollapsed = userStreams.includes(workspaceId);
  if (isCollapsed) {
    userStreams = userStreams.filter((item) => item !== workspaceId);
  } else {
    userStreams.push(workspaceId);
  }
  const obj = { prop: "hiddenStreams", value: userStreams };
  return updateUser(userInfo.id, obj);
}
