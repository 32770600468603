import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/Button";
import InputTextarea from "../../inputs/InputTextarea";
import InputSingle from "../../inputs/InputSingle";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { checkIfStringIsValid } from "../../../utils/checkStrings";
import Alert from "../alerts/Alert";

export default function ModalCreateHashtag(props) {
  const { isOpen, handleClose, hashtags } = props;
  const [title, setTitle] = useState("#");
  const [description, setDescription] = useState("");

  const nameNoHashtag = title.charAt(0) === "#" ? title : title.slice(1);
  const isHashtagTaken = hashtags.find(
    (h) => h.title.includes(nameNoHashtag) && title.length > 3
  );
  const isSubmitDisabled = !checkIfStringIsValid(nameNoHashtag);

  const handleCreate = (e) => {
    e.preventDefault();
    const obj = { title, description };
    props.handleCreate(obj).then(() => handleClose());
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-7xl sm:my-8 sm:max-w-sm">
                <form
                  onSubmit={(e) => handleCreate(e)}
                  className="flex h-full flex-col divide-y divide-gray-200 bg-white"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          New Hashtag
                        </Dialog.Title>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new hashtag.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <InputSingle
                            required
                            label={`Hashtag`}
                            name="hashtag-name"
                            value={title}
                            setValue={setTitle}
                          />
                          {isHashtagTaken && (
                            <Alert
                              message={
                                "You have already created a hashtag that contains this title"
                              }
                            />
                          )}
                          <InputTextarea
                            label="Description"
                            name="description"
                            value={description}
                            setValue={setDescription}
                          />
                        </div>
                        <div className="pt-4 pb-6">
                          <div className="mt-4 flex text-sm">
                            <a
                              href="localhost:3000/privacy-policy"
                              className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                            >
                              <QuestionMarkCircleIcon
                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="ml-2">
                                Learn more about Notespaces
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <div className="flex ml-2">
                      <div>
                        <Button
                          message="Save"
                          disabled={isSubmitDisabled}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
