import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import {
  getCalendarDays,
  getCalendarTasks,
  getMonthInfo,
  getMonthName,
} from "../../../utils/getDates";
import Avatar from "../../avatars/Avatar";
import AvatarBlank from "../../avatars/AvatarBlankCalendar";
import CompleteButton from "../../buttons/ButtonComplete";
import DateChip from "../chips/ChipDate";
import CalendarSquare from "./CalendarSquare";

export default function Calendar(props) {
  const { tasks, users, handleSelect, handleUpdate } = props;
  const currentMonth = getMonthInfo().monthIndex;
  const currentYear = getMonthInfo().year;
  const [calendarMonth, setCalendarMonth] = useState(currentMonth);
  const [calendarYear, setCalendarYear] = useState(currentYear);
  const days = getCalendarDays(calendarMonth, calendarYear, tasks);
  const monthName = getMonthName(calendarMonth);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleSelectDate = (date) => {
    if (selectedDates.includes(date)) {
      const newSelectedDates = selectedDates.filter((d) => d !== date);
      setSelectedDates(newSelectedDates);
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleAddMonth = () => {
    if (calendarMonth === 12) {
      setCalendarMonth(1);
      setCalendarYear(calendarYear + 1);
    } else {
      setCalendarMonth(calendarMonth + 1);
    }
  };

  const handleSubtractMonth = () => {
    if (calendarMonth === 1) {
      setCalendarMonth(12);
      setCalendarYear(calendarYear - 1);
    } else {
      setCalendarMonth(calendarMonth - 1);
    }
  };

  let calendarTasks = getCalendarTasks(tasks, calendarMonth, calendarYear);
  calendarTasks =
    selectedDates.length > 0
      ? calendarTasks.filter((item) => selectedDates.includes(item.dueDate))
      : calendarTasks;

  const shortYear = `'${calendarYear.toString().slice(2)}`;

  const listViewHeading =
    selectedDates.length > 0
      ? `Tasks for selected dates ${monthName} ${shortYear}`
      : `All tasks for ${monthName} ${shortYear}`;

  return (
    <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
      <div className="md:pr-8">
        <div className="flex items-center">
          <h2 className="flex-auto font-semibold text-gray-900">
            {`${monthName} ${calendarYear}`}
          </h2>
          <button
            onClick={() => handleSubtractMonth()}
            type="button"
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={() => handleAddMonth()}
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="mt-2 grid grid-cols-7 text-sm">
          {days.map((day, dayIdx) => {
            let isActive = false;
            if (day.dailyTasks.length > 0) {
              const dayTimestamp = day.dailyTasks[0].dueDate;
              isActive = selectedDates.includes(dayTimestamp);
            }
            return (
              <CalendarSquare
                key={day.date}
                day={day}
                dayIdx={dayIdx}
                handleSelect={handleSelectDate}
                isActive={isActive}
              />
            );
          })}
        </div>
      </div>
      <section className="mt-12 md:mt-0 md:pl-8">
        <div className="mb-5">
          <h2 className="font-semibold text-gray-900">{listViewHeading}</h2>
          <p className="text-slate-400 text-xs">{`${
            selectedDates.length > 0 ? "See dates below" : "+/- 7 days"
          }`}</p>
        </div>
        <ul className="flex p-2">
          {selectedDates.length > 0 &&
            selectedDates.map((date) => (
              <li>
                <DateChip key={date} date={date} />
              </li>
            ))}
        </ul>
        <ol className="mt-4 text-sm leading-6 text-gray-500">
          {calendarTasks.map((task, i) => (
            <CalendarListItem
              key={task.id}
              task={task}
              users={users}
              handleSelect={handleSelect}
              handleUpdate={handleUpdate}
              isStriped={i % 2 === 0}
              isLast={i === calendarTasks.length - 1}
            />
          ))}
        </ol>
      </section>
    </div>
  );
}

const CalendarListItem = ({
  task,
  users,
  handleSelect,
  handleUpdate,
  isStriped,
  isLast,
}) => {
  let assignees = [];
  let assigneeNames = [];
  if (task.assignees.length > 0) {
    assignees = task.assignees.map((assigneeId) =>
      users.find((user) => user.userId === assigneeId)
    );
    assigneeNames = assignees.map((assignee) => assignee.name);
  }
  return (
    <li
      onClick={() => handleSelect(task.id)}
      className={`flex items-center space-x-4 py-2 px-4 focus-within:bg-gray-100 hover:bg-gray-100 ${
        !isLast && "border-b"
      } ${isStriped && "bg-slate-50"}`}
    >
      <CompleteButton
        isComplete={task.isComplete}
        handleUpdate={handleUpdate}
        noteId={task.id}
        note={task}
      />
      <div className="text-left flex-1 overflow-hidden">
        <h3 className="text-sm font-medium text-gray-900 truncate">
          {task.note}
        </h3>
        {assignees.length > 0 && (
          <p className="text-xs text-gray-500 truncate">
            {assigneeNames.join(", ")}
          </p>
        )}
      </div>
      {task.dueDate && (
        <div>
          <DateChip date={task.dueDate} />
        </div>
      )}
      <div className="flex justify-center items-center">
        <div
          className={`flex justify-center items-center ${
            assignees.length > 0 && "pr-3"
          }`}
        >
          {assignees.length > 0 ? (
            assignees.map((assignee) => {
              return (
                <Avatar
                  key={assignee.userId}
                  name={assignee.name}
                  margin={"ring-2 ring-white mr-[-1em]"}
                />
              );
            })
          ) : (
            <AvatarBlank />
          )}
        </div>
      </div>
    </li>
  );
};
