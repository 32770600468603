import React from "react";

function InputSelection(props) {
  const { cypressId, label, name, value, setValue, options } = props;

  const margin = props.margin ?? "m-0";

  return (
    <div className={margin}>
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        id={name}
        name={name}
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default InputSelection;
