import { pluralize } from "./checkStrings";
import { getNewDate } from "./getDates";
import { getUniqueValues } from "./sortArray";
var stringSimilarity = require("string-similarity");

function checkCondition(note, condition) {
  if (condition.prop === "tags") {
    const pluralizedTag = pluralize(condition.value);
    let isMatching =
      note.hashtags?.includes(`#${condition.value}`) ||
      note.hashtags?.includes(`#${pluralizedTag}`);
    if (!isMatching) {
      isMatching = note.hashtags?.some((item) => {
        const noHashtag = item.slice(1);
        const similarity = stringSimilarity.compareTwoStrings(
          noHashtag,
          condition.value
        );
        return similarity > 0.59;
      });
    }
    return isMatching;
  }
  if (condition.prop === "priority") {
    return note.priority === condition.value;
  }
  if (condition.prop === "assignee") {
    return note.assignee === condition.value;
  }
  if (condition.prop === "isComplete") {
    return note.isComplete.toString() === condition.value && note.isTask;
  }
  if (condition.prop === "isDecision") {
    return note.isDecision.toString() === condition.value;
  }
  if (condition.prop === "assignees") {
    return note.assignees.includes(condition.value);
  }
  if (condition.prop === "createdBy") {
    return note.creator.userRef.includes(condition.value);
  }
  if (condition.prop === "createdInMeeting") {
    return note.meetingId === condition.value;
  }
  if (condition.prop === "createdInNotespace") {
    return note.notespaceId === condition.value;
  }
  if (condition.prop === "dueDate") {
    if (note.dueDate === "" || !note.dueDate) {
      return false;
    }
    const noteValue = new Date(note.dueDate).getTime();
    let conditionValue = getNewDate(condition.value); //add on number of days
    conditionValue = new Date(conditionValue).getTime();
    const { operation } = condition;
    if (operation === "=") {
      return conditionValue === noteValue;
    } else if (operation === "<") {
      return noteValue < conditionValue;
    } else return noteValue > conditionValue;
  }
}

export function filterData(notes, conditions) {
  let filteredData = [];
  for (var i = 0; i < notes.length; i++) {
    const currentNote = notes[i];
    let isMatched = false;
    for (var j = 0; j < conditions.length; j++) {
      const currentCondition = conditions[j];
      isMatched = checkCondition(currentNote, currentCondition);
      if (!isMatched) {
        break; //We know that one is invalid and all need to be valid
      }
    }
    if (isMatched) {
      filteredData.push(currentNote);
    }
  }
  return filteredData;
}

function checkConditionHashtag(note, hashtag) {
  const pluralizedTag = pluralize(hashtag);
  let isMatching =
    note.hashtags?.includes(`${hashtag}`) ||
    note.hashtags?.includes(`${pluralizedTag}`);
  if (!isMatching) {
    isMatching = note.hashtags?.some((item) => {
      const similarity = stringSimilarity.compareTwoStrings(item, hashtag);
      return similarity > 0.59;
    });
  }
  return isMatching;
}

export function filterDataByHashtags(notes, hashtags) {
  let filteredData = [];
  for (var i = 0; i < notes.length; i++) {
    const currentNote = notes[i];
    let isMatched = false;
    for (var j = 0; j < hashtags.length; j++) {
      const currentCondition = hashtags[j];
      isMatched = checkConditionHashtag(currentNote, currentCondition);
      if (isMatched) {
        break; //We know that one is invalid and all need to be valid
      }
    }
    if (isMatched) {
      filteredData.push(currentNote);
    }
  }
  return filteredData;
}

/* const filters = [
  {
    type: "date",
    title: "dueDate",
    value: "2022-09-30",
    operation: ">",
  },
  {
    type: "fixed",
    title: "priority",
    value: "medium",
    operation: "=",
  },
]; */

export function filterUniqueTags(notes, tags) {
  let allTags = notes.flatMap((item) => item.tags.map((it) => it));
  allTags = allTags.concat(tags);
  const allUniqueTags = getUniqueValues(allTags);
  return allUniqueTags;
}

export function getUnreachedNotes(notes, streams) {
  let unreachedNotes = [];
  for (var i = 0; i < notes.length; i++) {
    const currentNote = notes[i];
    let isMatched = false;
    for (var j = 0; j < streams.length; j++) {
      const currentStream = streams[j];
      for (var k = 0; k < currentStream.conditions.length; k++) {
        const currentCondition = currentStream.conditions[k];
        isMatched = checkCondition(currentNote, currentCondition);
        if (isMatched) {
          break;
        }
      }
      if (isMatched) {
        break;
      }
    }
    if (!isMatched) {
      unreachedNotes.push(currentNote);
    }
  }
  return unreachedNotes;
}
