import { CalendarIcon } from "@heroicons/react/20/solid";
import React from "react";
import { getShortCalendarFormat } from "../../../utils/getDates";

function DateChip(props) {
  const { date } = props;
  const action = props.action ?? "Due on";
  return (
    <span className="text-gray-700 p-1 bg-gray-100 text-xs rounded-sm mr-1 px-2 inline-flex items-center whitespace-nowrap ">
      <CalendarIcon className="w-3 h-3 mr-1 text-gray-700" />
      <span className="truncated ">{`${action} ${getShortCalendarFormat(date)}`}</span>
    </span>
  );
}

export default DateChip;
