import React from "react";
import Calendar from "../../components/shared/calendar/Calendar";
import SectionHeading from "../../components/shared/SectionHeading";

function WorkspaceSectionCalendar(props) {
  const { tasks, users, handleSelect, handleUpdate } = props;
  return (
    <section>
      <SectionHeading
        heading={"Our Calendar"}
        subheading={"View upcoming tasks for the entire team"}
        buttonMessage={"Create Task"}
      />
      <div className="p-5">
        <Calendar
          tasks={tasks}
          users={users}
          handleSelect={handleSelect}
          handleUpdate={handleUpdate}
        />
      </div>
    </section>
  );
}

export default WorkspaceSectionCalendar;
