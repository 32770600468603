import React, { useState } from "react";
import FilterSectionMembers from "./FilterSectionMembers";
import FilterSectionHashtags from "./FilterSectionHashtags";
import FilterSectionContext from "./FilterSectionContext";

function FilterSection(props) {
  const {
    conditions,
    handleToggleCheckbox,
    hashtags,
    openSections,
    setOpenSections,
    showAllHashtags,
    setShowAllHashtags,
  } = props;
  const sections = props.sections ?? [];
  const users = props.users ?? [];
  const [userTypeValue, setUserTypeValue] = useState("assignees");

  const handleToggleOpen = (section) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((s) => s !== section));
    } else {
      setOpenSections([...openSections, section]);
    }
  };

  const getSection = (type, isOpen) => {
    switch (type) {
      case "members":
        return (
          <FilterSectionMembers
            key={type}
            isOpen={isOpen}
            handleToggle={handleToggleOpen}
            userTypeValue={userTypeValue}
            setUserTypeValue={setUserTypeValue}
            handleToggleCheckbox={handleToggleCheckbox}
            users={users}
            conditions={conditions}
          />
        );
      case "hashtags":
        return (
          <FilterSectionHashtags
            key={type}
            isOpen={isOpen}
            handleToggle={handleToggleOpen}
            hashtags={hashtags}
            conditions={conditions}
            handleToggleCheckbox={handleToggleCheckbox}
            showAll={showAllHashtags}
            setShowAll={setShowAllHashtags}
          />
        );
      case "context":
        return (
          <FilterSectionContext
            key={type}
            isOpen={isOpen}
            handleToggle={handleToggleOpen}
            conditions={conditions}
            handleToggleCheckbox={handleToggleCheckbox}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="filter-section py-2 px-5">
      <div className="">
        {sections.map((section) => {
          const isOpen = openSections.includes(section);
          return getSection(section, isOpen);
        })}
      </div>
    </div>
  );
}

export default FilterSection;
