import React from "react";

function DeleteButton(props) {
  const { handleClick } = props;
  const action = props.action ?? "Delete";
  return (
    <button
      onClick={handleClick}
      type="button"
      className="ml-2 inline-flex items-center bg-transparent rounded-md border-transparent px-4 py-2 text-xs text-red-900 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-ref focus:ring-offset-2"
    >
      {action}
    </button>
  );
}

export default DeleteButton;
