import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  RectangleGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../../../images/logo.png";
import { Link } from "react-router-dom";

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LoadingPageShell(props) {
  const { activeView } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const color7 = props.isAlt ? "bg-violet-700" : "bg-indigo-700";
  const color8 = props.isAlt ? "bg-violet-800" : "bg-indigo-800";
  const colorText3 = props.isAlt ? "text-violet-300" : "text-indigo-300";
  const colorHover8 = props.isAlt
    ? "hover:bg-violet-800"
    : "hover:bg-indigo-800";

  const navigationItems = [];

  return (
    <>
      <div className="flex h-full min-h-screen">
        {/* Narrow sidebar */}
        <div className={"hidden w-28 overflow-y-auto md:block " + color7}>
          <div className="flex w-full flex-col items-center py-6">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/user">
                <img className="h-8 w-auto" src={logo} alt="Your Company" />
              </Link>
            </div>
            <div className="mt-6 w-full flex-1 space-y-1 px-2">
              {navigationItems.map((item) => (
                <div
                  role="button"
                  key={item.id}
                  href={item.href}
                  className={classNames(
                    item.id === activeView
                      ? "text-white " + color8
                      : "text-indigo-100 hover:text-white " + colorHover8,
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={item.id === activeView ? "page" : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.id === activeView
                        ? "text-white"
                        : "group-hover:text-white " + colorText3,
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20 md:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel
                  className={
                    "relative flex w-full max-w-xs flex-1 flex-col pt-5 pb-4 " +
                    color7
                  }
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div
                    className={"flex flex-shrink-0 items-center px-4 " + color7}
                  >
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                    <nav className="flex h-full flex-col">
                      <div className="space-y-1">
                        {navigationItems.map((item) => (
                          <div
                            role="button"
                            key={item.id}
                            className={classNames(
                              item.id === activeView
                                ? "text-white " + color8
                                : "text-indigo-100 hover:text-white " +
                                    colorHover8,
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )}
                            aria-current={
                              item.id === activeView ? "page" : undefined
                            }
                          >
                            <item.icon
                              className={classNames(
                                item.id === activeView
                                  ? "text-white"
                                  : "group-hover:text-white " + colorText3,
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </div>
                        ))}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <button
                type="button"
                className="border-r border-gray-200 px-4   + color7text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-between px-4   + color7sm:px-6">
                <div className="flex flex-1">
                  <form className="flex w-full md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search all files
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center h-5"></div>
                    </div>
                  </form>
                </div>
                <div className="ml-2 flex items-center space-x-4   + color7sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex justify-center overflow-hidden">
            <main className="overflow-y-auto flex-1">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last relative"
              >
                <div className="w-full min-h-screen flex justify-center items-center">
                  <RectangleGroupIcon
                    className="h-10 w-10 animate-spin text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </section>
            </main>

            {/* Secondary column (hidden on smaller screens) 
            <aside className="hidden w-96 min-h-screen overflow-y-auto border-l border-gray-200 lg:block bg-slate-50">
            </aside> 
          */}
          </div>
        </div>
      </div>
    </>
  );
}
