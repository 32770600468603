import React from "react";
import ListInvites from "../../components/shared/lists/ListInvites";
import ListMembers from "../../components/shared/lists/ListMembers";
import SectionHeading from "../../components/shared/SectionHeading";

function WorkspaceSectionTeam(props) {
  const { setIsCreateOpen, handleCancel, invites } = props;
  const members = props.members ?? [];
  return (
    <section>
      <SectionHeading
        heading={"Our Team"}
        subheading={"Invite and manage workspace members"}
        buttonMessage={"Invite Member"}
        handleClick={() => setIsCreateOpen("invite")}
      />
      {members.length > 0 && (
        <div>
          <ListMembers members={members} />
        </div>
      )}
      {invites.length > 0 && (
        <div>
          <h2 className="text-left p-5 pb-1 italic text-gray-500">
            Pending Invites
          </h2>
          <ListInvites handleCancel={handleCancel} invites={invites} />
        </div>
      )}
    </section>
  );
}

export default WorkspaceSectionTeam;
