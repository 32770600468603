import { useEffect, useState } from "react";
import {
  auth,
  checkSignupStatus,
  registerWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import InputSingle from "../../components/inputs/InputSingle";
import Button from "../../components/buttons/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Alert from "../../components/shared/alerts/Alert";
import googleLogo from "../../images/google-logo.svg";
import { Logo } from "../landing-page/components/Logo";

export default function LoginPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, authLoading] = useAuthState(auth);
  const [signupStatus, setSignupStatus] = useState("isSignedUp");
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);

  const navigate = useNavigate();
  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    navigate("/user");
  }, [user, authLoading, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (signupStatus === "isSignedUp") {
      signInWithEmailAndPassword(email, password).then((res) => {
        if (!res.isValid) {
          setAlert({ type: "error", message: res.message });
        }
      });
    } else if (signupStatus === "notSignedUp") {
      registerWithEmailAndPassword(name, email, password);
    } else {
      checkSignupStatus(email).then((res) => {
        if (res.doesExist && res.isValid) {
          setSignupStatus("isSignedUp");
          setAlert({
            type: "success",
            message: "We have found your account. Please sign in.",
          });
        } else if (res.doesExist && !res.isValid) {
          signInWithGoogle();
        } else {
          setSignupStatus("notSignedUp");
          setAlert({
            type: "error",
            message: res.message,
          });
        }
      });
    }
  };

  const getSignupMessage = () => {
    if (signupStatus === "isSignedUp") {
      return "Sign in";
    } else if (signupStatus === "notSignedUp") {
      return "Sign up";
    } else {
      return "Enter email";
    }
  };

  const isSignedUp = signupStatus === "isSignedUp";
  const buttonMessage = !isSignedUp ? "Go back to sign up" : "Create an account";
  const buttonProp = isSignedUp ? "notSignedUp" : "isSignedUp";
  const heading = isSignedUp
    ? "Sign in to your account"
    : "Sign up to get started";

  return (
    <>
      <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-50">
        {alert && (
          <Alert isTop={true} message={alert.message} type={alert.type} />
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Logo />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
              {signupStatus === "notSignedUp" && (
                <InputSingle
                  isRequired={true}
                  type="text"
                  name="name"
                  label="Full Name"
                  value={name}
                  setValue={setName}
                />
              )}
              <InputSingle
                isRequired={true}
                type="email"
                name="email"
                label="Email"
                value={email}
                setValue={setEmail}
              />
              {signupStatus && (
                <InputSingle
                  isRequired={true}
                  type="password"
                  name="password"
                  label="Password"
                  value={password}
                  setValue={setPassword}
                />
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="localhost:3000/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div>
                <Button type={"submit"} message={getSignupMessage()} />
              </div>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
              {/*<div className="mt-6 grid grid-cols-3 gap-3"> */}
              <div className="mt-6">
                <div>
                  <div
                    onClick={signInWithGoogle}
                    role={"button"}
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Google</span>
                    <img alt="Google logo" src={googleLogo} />
                  </div>
                  <div
                    onClick={() => setSignupStatus(buttonProp)}
                    role={"button"}
                    className="mt-2 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    <span className="">{buttonMessage}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
