export default function ActionPanel(props) {
  const { message, action, heading, handleClick, reject, handleReject } = props;
  return (
    <div className="shadow sm:rounded-lg border bg-slate-100 w-full lg:w-[98%] mx-auto my-2">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 text-left">
              {heading}
            </h3>
            <div className="text-left max-w-xl text-sm text-gray-500">
              <p>{message}</p>
            </div>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
            <button
              onClick={handleClick}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
              {action}
            </button>
            {reject && (
              <button
                onClick={handleReject}
                type="button"
                className="ml-2 inline-flex items-center bg-transparent rounded-md border-transparent px-4 py-2 font-medium text-red-900 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-ref focus:ring-offset-2 sm:text-sm"
              >
                {reject}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
