import React from "react";
import Notestream from "../../components/shared/notestream/Notestream";
import SectionHeading from "../../components/shared/SectionHeading";
import { filterData } from "../../utils/filterData";

function UserSectionTasks(props) {
  const {
    notes,
    handleUpdate,
    handleSelectNote,
    handleUpdateStream,
    handleRearrange,
    handleUpdateCollapsedStreams,
    collapsedStreams,
    setActiveStreamId,
    setIsCreateOpen,
    userInfo
  } = props;
  const streams = props.streams ?? [];
  const hideAssignees = props.hideAssignees ?? false;
  return (
    <section>
      <SectionHeading
        heading={"My Tasks"}
        subheading={`Keep track all of the tasks that you've been assigned`}
        buttonMessage={"Create stream"}
        handleClick={() => setIsCreateOpen("stream")}
      />
      {streams.length > 0 && (
        <div className="p-5">
          <div className="max-w-3xl m-auto">
            {streams.map((stream) => {
              const filteredNotes = filterData(notes, stream.conditions);
              const isCollapsed = collapsedStreams.includes(stream.id);
              return (
                <Notestream
                  key={stream.id}
                  stream={stream}
                  isCollapsed={isCollapsed}
                  notes={filteredNotes}
                  setActiveStreamId={setActiveStreamId}
                  handleUpdateCollapsedStreams={handleUpdateCollapsedStreams}
                  handleUpdate={handleUpdate}
                  handleUpdateStream={handleUpdateStream}
                  handleRearrange={handleRearrange}
                  handleSelectNote={handleSelectNote}
                  workspaces={userInfo.workspaces}
                  showWorkspace={true}
                  hideAssignees={hideAssignees}
                />
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
}

export default UserSectionTasks;
