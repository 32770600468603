import React from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CalendarSquare(props) {
  const { day, dayIdx, isActive } = props;

  const handleSelect = () => {
    if (day.dailyTasks.length > 0) {
      const taskDate = day.dailyTasks[0].dueDate;
      props.handleSelect(taskDate);
    } else {
      alert("No tasks for this day");
    }
  };

  return (
    <div
      className={classNames(
        dayIdx > 6 && "border-t border-gray-200",
        "py-2 relative"
      )}
    >
      <button
        onClick={() => handleSelect()}
        type="button"
        className={classNames(
          day.isCurrentMonth &&
            !day.isOverdue &&
            !day.isToday &&
            !isActive &&
            "text-gray-600",
          day.isOverdue &&
            !isActive &&
            "ring-2 ring-red-700 text-red-700 bg-red-50 font-bold",
          day.isToday &&
            !isActive &&
            "ring-2 ring-indigo-700 text-indigo-700 font-bold",
          day.hasTask && day.isToday && "bg-indigo-50",
          day.hasTask &&
            !day.isOverdue &&
            !day.isToday &&
            !day.isOutstanding &&
            !isActive &&
            "ring-2 ring-green-700 text-green-700 font-bold",
          day.hasTask &&
            !day.isOverdue &&
            !day.isToday &&
            day.isOutstanding &&
            !isActive &&
            "ring-2 ring-green-700 bg-green-100 text-green-700 font-bold",
          isActive && "bg-violet-500 text-white ring-0 font-bold",
          !day.isSelected &&
            !day.isToday &&
            !day.isSelected &&
            !day.isToday &&
            !day.isCurrentMonth &&
            "text-gray-400",
          day.isSelected && day.isToday && "bg-green-600",
          day.isSelected &&
            !day.isToday &&
            "ring-2 ring-green-600 text-green-900",
          (day.isSelected || day.isToday) && "font-semibold",
          "mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200"
        )}
      >
        <time dateTime={day.date}>
          {day.date.split("-").pop().replace(/^0/, "")}
        </time>
      </button>
    </div>
  );
}

export default CalendarSquare;
