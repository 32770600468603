import React, { useState } from "react";
import FilterSectionHeading from "./FilterSectionHeading";
import Fuse from "fuse.js";
import InputCheckbox from "../inputs/InputCheckboxStream";
import { getString } from "../../utils/getStrings";
import InputSingle from "../inputs/InputSingle";
import Button from "../buttons/ButtonText";

function FilterSectionHashtags(props) {
  const { isOpen, handleToggle, conditions, showAll, setShowAll } = props;
  const hashtags = props.hashtags ?? [];
  const [query, setQuery] = useState("");

  const hashtagFuzzy = new Fuse(hashtags, {
    keys: ["title", "description"],
    includeScore: true,
    threshold: 0.3,
  });

  let filteredHashtags =
    query === ""
      ? hashtags
      : hashtagFuzzy.search(query).map((item) => item.item);

  let hashtagInputs = filteredHashtags.map((hashtag) => ({
    name: hashtag.id,
    label: hashtag.title,
    description: hashtag.description,
    value: conditions.some((condition) => condition.value === hashtag.title),
    setValue: () => handleToggleCheckbox(hashtag.title),
  }));

  const handleToggleCheckbox = (value) => {
    const obj = { value, operation: "=", prop: "tags" };
    props.handleToggleCheckbox(obj);
  };

  hashtagInputs =
    hashtags.length > 3 && !showAll ? hashtagInputs.slice(0, 3) : hashtagInputs;

  return (
    <div className={`border-b ${isOpen && "pb-5"}`}>
      <FilterSectionHeading
        isOpen={isOpen}
        handleToggle={handleToggle}
        title="Hashtags"
      />
      {isOpen && (
        <div className="flex-col">
          {hashtags.length > 8 && (
            <InputSingle
              value={query}
              setValue={setQuery}
              placeholder={`Search...`}
              label={`Hashtag search`}
              isLabelHidden={true}
            />
          )}
          <div className="max-h-72 overflow-y-auto">
            <InputCheckbox
              entity={"Hashtags"}
              emptyMessage={getString("empty-hashtag")}
              inputs={hashtagInputs}
              paddingTop={"pt-0"}
            />
          </div>
          {hashtags.length > 3 && filteredHashtags.length > 0 && (
            <Button
              message={!showAll ? "Show all" : "Show fewer"}
              handleClick={() => setShowAll(!showAll)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default FilterSectionHashtags;
