import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import InputSingle from "../inputs/InputSingle";
import InputTextarea from "../inputs/InputTextarea";
import InputSelection from "../inputs/InputSelection";
import { getOperationOptions, streamTemplate } from "../../utils/getTemplates";
import Button from "../buttons/Button";
import ButtonDelete from "../buttons/ButtonDelete";
import ListConditions from "../shared/lists/ListConditions";
import { checkIfStringIsValid } from "../../utils/checkStrings";
import { useEffect } from "react";
import InputCombo from "../inputs/InputComboStream";
//import InputRadio from "../inputs/InputRadio";

export default function UpdateStreamSlideout(props) {
  const { isOpen, setIsOpen, activeId, tags } = props;
  const stream = props.stream ?? streamTemplate;
  const [name, setName] = useState(stream.title);
  const [description, setDescription] = useState(stream.description);
  const [conditions, setConditions] = useState(stream.conditions);
  const [prop, setProp] = useState("tags");
  const [operation, setOperation] = useState("=");
  const [value, setValue] = useState("");
  const users = props.users ?? [];
  const notespaces = props.notespaces ?? [];
  const meetings = props.meetings ?? [];
  const propOptions = [
    "tags",
    "assignees",
    "dueDate",
    "priority",
    "isDecision",
    "isComplete",
    "createdInNotespace",
    "createdInMeeting",
  ];

  useEffect(() => {
    setName(stream.title);
    setDescription(stream.description);
    setConditions(stream.conditions);
  }, [stream, activeId]);

  const priorityOptions = ["high", "medium", "low"];
  const allPriorityOptions = priorityOptions.map((item) => item);

  const allUserOptions = users.map((item) => item.name);
  const allNotespaceOptions = notespaces.map((item) => item.name);
  const allMeetingOptions = meetings.map((item) => item.name);
  const allOperationOptions = getOperationOptions(prop);

  let operationPlaceholder =
    prop === "dueDate" ? "is fewer than (<)" : "is equal to (=)";
  if (prop === "tags" || prop === "assignees") {
    operationPlaceholder = "includes (=)";
  }

  const handleClose = () => {
    setIsOpen(false);
    setConditions([]);
    setName("");
    setDescription("");
    setValue("");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const obj = { title: name, description, conditions };
    const isFull = true;
    props.handleUpdate(obj, stream.id, isFull).then(() => handleClose());
  };

  const handleDelete = () => {
    const obj = { prop: "isDeleted", value: true };
    props.handleUpdate(obj, stream.id).then(() => handleClose());
  };

  const handleAddCondition = () => {
    let conditionValue = value;
    if (!conditionValue && prop === "assignees") {
      conditionValue = users[0].userId;
    }
    if (!conditionValue && prop === "priority") {
      conditionValue = "high";
    }
    if (!conditionValue && (prop === "isDecision" || prop === "isComplete")) {
      conditionValue = "true";
    }
    const condition = {
      operation: operation,
      value: conditionValue,
      prop,
    };
    setConditions((current) => [...current, condition]);
  };

  const handleClearCondition = (index) => {
    const updatedConditions = conditions.filter((item, i) => index !== i);
    setConditions(updatedConditions);
  };

  const handleSetAssignee = (value) => {
    const assignee = users.find((item) => item.name === value);
    setValue(assignee.userId);
  };

  const handleSelectFromCombo = (item) => {
    const { title } = item;
    const titleWithoutHashtag = title.replace("#", "");
    setValue(titleWithoutHashtag);
  };

  const handleSetProp = (val) => {
    if (val === "assignees") {
      setValue(users[0].userId);
    }
    setProp(val);
  };

  const getValueInput = () => {
    switch (prop) {
      case "dueDate":
        return (
          <InputSingle
            type="number"
            value={value}
            setValue={setValue}
            placeholder={"days from now"}
            label=""
            name="dueDate"
          />
        );
      case "tags":
        return (
          <InputCombo
            query={value}
            setQuery={setValue}
            placeholder={"hashtag"}
            label="Add tag"
            name="tag"
            handleSelect={handleSelectFromCombo}
            hasNoHashtag={true}
            options={tags.map((item) => ({
              title: item.replace("#", ""),
            }))}
          />
        );
      case "priority":
        return (
          <InputSelection
            options={allPriorityOptions}
            value={value}
            setValue={setValue}
            label={""}
            name={"priority"}
          />
        );
      case "assignees":
        return (
          <InputSelection
            options={allUserOptions}
            value={users.find((item) => item.id === value)?.name}
            setValue={handleSetAssignee}
            label={""}
            name={"assignees"}
          />
        );
      case "isComplete":
        return (
          <InputSelection
            options={["true", "false"]}
            value={value}
            setValue={setValue}
            label={""}
            name={"isComplete"}
          />
        );
      case "isDecision":
        return (
          <InputSelection
            options={["true", "false"]}
            value={value}
            setValue={setValue}
            label={""}
            name={"isDecision"}
          />
        );
      case "createdInMeeting":
        return (
          <InputSelection
            options={allMeetingOptions}
            value={value}
            setValue={setValue}
            label={""}
            name={"createdInMeeting"}
          />
        );
      case "createdInNotespace":
        return (
          <InputSelection
            options={allNotespaceOptions}
            value={value}
            setValue={setValue}
            label={""}
            name={"createdInNotespace"}
          />
        );
      default:
        return null;
    }
  };

  const isSubmitDisabled = !checkIfStringIsValid(name) || conditions.length < 1;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div
            style={{
              background: isOpen ? "rgba(0,0,0,.3)" : "rgba(0,0,0,0)",
              transition: "all .5s",
            }}
            className="absolute inset-0 overflow-hidden"
          >
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form
                    onSubmit={(e) => handleUpdate(e)}
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            New Stream
                          </Dialog.Title>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new stream.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <InputSingle
                              required
                              label="Stream name"
                              name="stream-name"
                              value={name}
                              setValue={setName}
                            />
                            <InputTextarea
                              name="stream-description"
                              label="Description"
                              value={description}
                              setValue={setDescription}
                            />
                            <div>
                              <InputSelection
                                label="Condition"
                                name="stream-property"
                                options={propOptions}
                                value={prop}
                                setValue={handleSetProp}
                              />
                              <InputSelection
                                label=""
                                name="stream-operation"
                                options={allOperationOptions}
                                value={operation}
                                setValue={setOperation}
                                placeholder={operationPlaceholder}
                              />
                              {getValueInput()}
                              <div className="mt-1">
                                <Button
                                  message={"Add Condition"}
                                  handleClick={() => handleAddCondition()}
                                  color="violet"
                                />
                              </div>
                              {conditions.length > 0 && (
                                <ListConditions
                                  handleClear={handleClearCondition}
                                  conditions={conditions}
                                  users={users}
                                />
                              )}
                            </div>
                          </div>
                          <div className="pt-4 pb-6">
                            <div className="mt-4 flex text-sm">
                              <a
                                href="localhost:3000/privacy-policy"
                                className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                              >
                                <QuestionMarkCircleIcon
                                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="ml-2">
                                  Learn more about Streams
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                      <div className="flex ml-2">
                        <div>
                          <Button
                            message="Save"
                            disabled={isSubmitDisabled}
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <ButtonDelete handleClick={() => handleDelete()} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
