import React from "react";

function InputTextarea(props) {
  const { label, name, value, setValue, placeholder } = props;
  const rows = props.rows ?? 4;
  const margin = props.margin ?? "m-0";

  return (
    <div className={margin}>
      <label
        htmlFor="description"
        className="block text-sm text-left font-medium text-gray-900"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          name={name}
          rows={rows}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
}

export default InputTextarea;
