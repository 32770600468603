import React from "react";
import { getIcon } from "../../utils/getIcons";

function FilterSectionHeading(props) {
  const { title, isOpen } = props;
  const id = title.toLowerCase();

  const handleToggle = () => {
    props.handleToggle(id);
  };
  return (
    <div className="w-full py-3 text-gray-500">
      <button
        onClick={() => handleToggle()}
        className="flex items-center w-full"
      >
        <div className="flex-1 flex justify-start">
          <h3>{title}</h3>
        </div>
        <div>{getIcon(isOpen ? "minus" : "plus")}</div>
      </button>
    </div>
  );
}

export default FilterSectionHeading;
