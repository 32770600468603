import Button from "../../buttons/Button";

export default function ActionPanelDelete(props) {
  const { entity, handleDelete } = props;
  return (
    <div className="bg-slate-100 border border-gray-200 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Delete {entity}
        </h3>
        <div className="max-w-xl text-sm text-gray-500">
          <p>
            Once you delete your {entity}, you will lose all data associated
            with it.
          </p>
        </div>
        <div className="mt-5 flex">
          <div>
            <Button
              handleClick={handleDelete}
              bg="red"
              message={`Delete ${entity}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
