import React, { useEffect, useState } from "react";
import { auth, db, logout } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Slideout from "../../components/slideouts/SlideoutNewWorkpace";
import UserSectionSpace from "./UserSectionSpace";
import {
  acceptWorkspaceInvite,
  createWorkspace,
} from "../../services/WorkspaceService";
import { getNaviagtionItems } from "../../utils/getNavigationItems";
import PageShell from "../PageShell";
import Button from "../../components/buttons/Button";
import LoadingPageShell from "../../components/shared/loading/LoadingPageShell";
import UserSectionInfo from "./UserSectionInfo";
import { updateUser, updateUserForm } from "../../services/UserService";
import Toast from "../../components/shared/toasts/Toast";
import UserSectionTasks from "./UserSectionTasks";
import { updateNote } from "../../services/NoteService";
import { streamTemplate } from "../../utils/getTemplates";
import NewStreamSlideout from "../../components/slideouts/SlideoutNewStream";
import {
  createUserStream,
  updateUserStream,
  updateUserStreamFull,
} from "../../services/StreamService";
import UpdateStreamSlideout from "../../components/slideouts/SlideoutUpdateStream";
import ModalStreamPositions from "../../components/shared/modals/modal-stream-positions/ModalStreamPositions";
import Alert from "../../components/shared/alerts/Alert";
import LoadingSpinner from "../../components/shared/loading/LoadingSpinner";

function UserDashboard() {
  const [user, authLoading] = useAuthState(auth);
  const [activeView, setActiveView] = useState("pages");
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [invites, setInvites] = useState([]);
  const [notes, setNotes] = useState([]);
  const [streams, setStreams] = useState([]);
  const [pageInvites, setPageInvites] = useState([]);
  const [isNoteUpdated, setIsNoteUpdated] = useState(false);
  const [isStreamUpdated, setIsStreamUpdated] = useState(false);
  const [isRearrangeStreamOpen, setIsRearrangeStreamOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [activeStreamId, setActiveStreamId] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  let orderedStreamRefs = [];
  let orderedStreams = streams;

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        setToastMessage("");
      }, 3000);
    }
  }, [toastMessage]);

  useEffect(() => {
    if (alert) {
      setIsSpinning(false);
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && isNoteUpdated) {
      fetchNotes(userInfo.id);
      setIsNoteUpdated(false);
    }
    //eslint-disable-next-line
  }, [isNoteUpdated]);

  useEffect(() => {
    if (userInfo && isStreamUpdated) {
      fetchStreams(userInfo.id);
      setIsStreamUpdated(false);
    }
    //eslint-disable-next-line
  }, [isStreamUpdated]);

  const fetchUser = async () => {
    try {
      db.collection("users")
        .where("uid", "==", user?.uid)
        .onSnapshot((value) => {
          const doc = value.docs[0];
          const data = doc.data();
          data.ref = doc.id;
          data.id = doc.id;
          setUserInfo(data);
          fetchInvites();
          fetchPageInvites();
          fetchNotes(doc.id);
          fetchStreams(doc.id);
          //window.heap.identify(user.uid, "uid");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchInvites = async () => {
    let fetchedInvites = [];
    try {
      const query = await db
        .collection("invites")
        .doc(user.email)
        .collection("invites")
        .get();
      query.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.title = data.workspaceTitle;
        data.entityId = data.workspaceId;
        data.entityType = "workspace";
        fetchedInvites.push(data);
      });
      fetchedInvites = fetchedInvites.filter(
        (item) => item.status === "pending"
      );
      setInvites(fetchedInvites);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPageInvites = async () => {
    let fetchedInvites = [];
    try {
      const query = await db
        .collection("notespaceInvites")
        .doc(user.email)
        .collection("invites")
        .get();
      query.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        data.title = data.notespaceTitle;
        data.entityId = data.notespaceId;
        data.entityType = "notespace";
        fetchedInvites.push(data);
      });
      fetchedInvites = fetchedInvites.filter(
        (item) => item.status === "pending"
      );
      setPageInvites(fetchedInvites);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchNotes = async (userId) => {
    let notesArr = [];
    try {
      const query = await db
        .collection("notes")
        .where("assignees", "array-contains", userId)
        .where("isDeleted", "==", false)
        .get();
      query.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        notesArr.push(data);
      });
      setNotes(notesArr);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchStreams = async (userId) => {
    let arr = [];
    try {
      const query = await db
        .collection("users")
        .doc(userId)
        .collection("streams")
        .where("isDeleted", "==", false)
        .get();
      query.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        arr.push(data);
      });
      setStreams(arr);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFeedback = (toastMessage) => {
    setIsSpinning(false);
    setIsCreateOpen(false);
    setToastMessage(toastMessage);
  };

  const handleCreateWorkspace = (obj) => {
    try {
      return createWorkspace(userInfo, obj).then(() =>
        handleFeedback("Workspace created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleAcceptInvite = (obj) => {
    try {
      return acceptWorkspaceInvite(userInfo, obj).then(() =>
        fetchNotes(userInfo.id)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateUser = (obj) => {
    try {
      return updateUser(userInfo.id, obj).then(() =>
        handleFeedback("Profile updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateUserForm = (obj) => {
    try {
      return updateUserForm(userInfo.id, obj).then(() =>
        handleFeedback("Profile updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateNote = (noteId, obj, note) => {
    try {
      return updateNote(noteId, userInfo, obj, note).then(() =>
        setIsNoteUpdated(true)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateStream = (obj) => {
    try {
      return createUserStream(userInfo, obj).then(() =>
        setToastMessage("Stream created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateStream = (obj, streamId) => {
    try {
      return updateUserStream(streamId, userInfo, obj).then(() =>
        handleUpdateEntity(setIsStreamUpdated, "Stream deleted")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateStreamFull = (obj, streamId) => {
    try {
      return updateUserStreamFull(streamId, userInfo, obj).then(() =>
        handleUpdateEntity(setIsStreamUpdated, "Stream updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateEntity = (setUpdate, updateMessage) => {
    setUpdate(true);
    setToastMessage(updateMessage);
  };

  const handleUpdateCollapsedStreams = (streamId) => {
    let userStreams = userInfo.collapsedStreams ?? [];
    const isCollapsed = userStreams.includes(streamId);
    if (isCollapsed) {
      userStreams = userStreams.filter((item) => item !== streamId);
    } else {
      userStreams.push(streamId);
    }
    const obj = { prop: "collapsedStreams", value: userStreams };
    return updateUser(userInfo.id, obj);
  };

  const handleSelectNote = () => {
    alert(
      "Currently you can only set tasks to complete or incomplete in the User Tasks view. If you wish to see the full note, please go to the Workspace."
    );
  };

  const getActiveView = () => {
    switch (activeView) {
      case "pages":
        return (
          <UserSectionSpace
            workspaces={userInfo.workspaces.filter(
              (item) => item.isDeleted !== true
            )}
            setIsCreateOpen={setIsCreateOpen}
            invites={invites}
            handleAcceptInvite={handleAcceptInvite}
          />
        );
      case "tasks":
        return (
          <UserSectionTasks
            handleUpdate={handleUpdateNote}
            handleSelectNote={handleSelectNote}
            handleUpdateStream={handleUpdateStream}
            handleRearrange={setIsRearrangeStreamOpen}
            notes={notes}
            collapsedStreams={userInfo.collapsedStreams}
            handleUpdateCollapsedStreams={handleUpdateCollapsedStreams}
            streams={orderedStreams}
            setActiveStreamId={setActiveStreamId}
            setIsCreateOpen={setIsCreateOpen}
            userInfo={userInfo}
            hideAssignees={true}
          />
        );
      case "info":
        return (
          <UserSectionInfo
            user={userInfo}
            handleUpdateForm={handleUpdateUserForm}
          />
        );
      case "settings":
        return (
          <div>
            <Button message="Logout" handleClick={() => logout()} />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (authLoading) return;
    if (!user) {
      logout();
      return navigate("/login");
    }
    fetchUser();
    //eslint-disable-next-line
  }, [user, authLoading, navigate]);

  const isLoading = authLoading || !userInfo;

  return isLoading ? (
    <LoadingPageShell isAlt navigationItems={getNaviagtionItems("user")} />
  ) : (
    <div className="w-full min-h-screen">
      {toastMessage && <Toast message={toastMessage} />}
      {isSpinning && <LoadingSpinner />}
      {alert && <Alert isTop={true} message={alert} />}
      <Slideout
        setIsOpen={setIsCreateOpen}
        isOpen={isCreateOpen === "workspace"}
        handleCreate={handleCreateWorkspace}
      />
      <NewStreamSlideout
        isOpen={isCreateOpen === "stream"}
        setIsOpen={setIsCreateOpen}
        stream={streamTemplate}
        handleCreate={handleCreateStream}
        users={[]}
        tags={[]}
        isAlwaysPersonal={true}
      />
      <UpdateStreamSlideout
        activeId={activeStreamId}
        isOpen={activeStreamId !== false}
        setIsOpen={setActiveStreamId}
        stream={
          activeStreamId
            ? streams.find((item) => item.id === activeStreamId)
            : streamTemplate
        }
        handleUpdate={handleUpdateStreamFull}
        users={[]}
        tags={[]}
      />
      {isRearrangeStreamOpen && (
        <ModalStreamPositions
          isOpen={isRearrangeStreamOpen === true}
          orderedStreamRefs={orderedStreamRefs}
          handleUpdate={handleUpdateUser}
          handleClose={() => setIsRearrangeStreamOpen(false)}
          streams={streams}
          streamOrderRefs={userInfo.streamOrderRefs}
          title={userInfo.name}
        />
      )}
      <PageShell
        color={"violet"}
        userInfo={userInfo}
        mainView={getActiveView()}
        activeView={activeView}
        setActiveView={setActiveView}
        navigationItems={getNaviagtionItems("user")}
        type={"user"}
        isSearchVisible={false}
      />
    </div>
  );
}

export default UserDashboard;
