import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { getAnalytics } from "firebase/analytics";
import { linkWithPopup } from "@firebase/auth";
import { createUserStream } from "./services/StreamService";

const firebaseConfig = {
  apiKey: "AIzaSyCeDyaAh7_Bj7OOzO0jKOIvsLYugWULTZY",
  authDomain: "samepage-4821b.firebaseapp.com",
  projectId: "samepage-4821b",
  storageBucket: "samepage-4821b.appspot.com",
  messagingSenderId: "336282778956",
  appId: "1:336282778956:web:8e790841cac3c9d29ba3e9",
  measurementId: "G-0BG4MNVYQS",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = firebase.auth();
const db = app.firestore();
const functions = app.functions();
const storage = app.storage();

if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
  firebase
    .auth()
    .useEmulator("http://localhost:9099/", { disableWarnings: true });
  storage.useEmulator("localhost", 9199);
}

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("uid", "==", user.uid)
      .get();
    if (query.docs.length === 0) {
      await db
        .collection("users")
        .add({
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          bio: "",
          created: firebase.firestore.FieldValue.serverTimestamp(),
          createdTimestamp: Date.now(),
          workspaces: [],
          notespaces: [],
          streamOrderRefs: [],
          pages: [],
          collapsedStreams: [],
        })
        .then((docRef) => {
          const userInfo = {
            id: docRef.id,
            email: user.email,
            name: user.displayName,
          };
          return createUserStream(userInfo, userStreamObj);
        });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const checkSignupStatus = async (email) => {
  try {
    const query = await db
      .collection("users")
      .where("email", "==", email)
      .get();
    if (query.docs.length === 0) {
      return { doesExist: false };
    } else {
      const isValid = query.docs[0].data().authProvider === "local";
      const message = !isValid
        ? "You have signed up with Google so please sign in with Google"
        : "Success";
      return { doesExist: true, isValid, message };
    }
  } catch (err) {
    return { isValid: false, message: err.message };
  }
};

const signInWithGoogleFromAnonymous = async (userRef) => {
  return linkWithPopup(auth.currentUser, googleProvider)
    .then((result) => {
      const user = result.user;
      db.collection("users").doc(userRef).update({
        email: user.email,
        authProvider: "google",
      });
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

const logout = () => {
  localStorage.removeItem("emailForAuth");
  auth.signOut();
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await db
      .collection("users")
      .add({
        uid: user.uid,
        name,
        authProvider: "local",
        email,
        bio: "",
        created: firebase.firestore.FieldValue.serverTimestamp(),
        createdTimestamp: Date.now(),
        workspaces: [],
        notespaces: [],
        streamOrderRefs: [],
        pages: [],
        collapsedStreams: [],
      })
      .then((docRef) => {
        const userInfo = { id: docRef.id, email, name };
        return createUserStream(userInfo, userStreamObj);
      });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    return {
      isValid: false,
      message: "This password is not correct. Please try again.",
    };
  }
};

const signInAnonymously = async () => {
  try {
    const res = await auth.signInAnonymously();
    const user = res.user;
    return await db.collection("users").add({
      uid: user.uid,
      name: "Name not yet provided",
      authProvider: "anonymous",
      email: "",
      bio: "",
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      workspaces: [],
      notespaces: [],
      streamOrderRefs: [],
      pages: [],
      collapsedStreams: [],
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const linkAccount = (email, password, userRef) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  return auth.currentUser
    .linkWithCredential(credential)
    .then(() => {
      db.collection("users").doc(userRef).update({
        authProvider: "email",
      });
    })
    .catch((error) => {
      console.log("Error upgrading anonymous account", error);
    });
};

const registerUsername = async (username, userRef) => {
  try {
    const query = await db
      .collection("users")
      .where("username", "==", username)
      .get();
    if (query.docs.length === 0) {
      return await db.collection("users").doc(userRef).update({
        username,
      });
    } else {
      return { isError: true, message: "Username is already in use" };
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export {
  firebase,
  app,
  analytics,
  auth,
  db,
  functions,
  storage,
  signInWithGoogle,
  checkSignupStatus,
  signInWithGoogleFromAnonymous,
  registerWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  linkAccount,
  registerUsername,
  logout,
};

const userStreamObj = {
  title: "My Tasks",
  description: "A list of all tasks assigned to me",
  conditions: [{ operation: "=", prop: "isComplete", value: "false" }],
};
