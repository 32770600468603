import { FunnelIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { sortNotes } from "../../../utils/sortData";
import Dropdown from "../dropdowns/Dropdown";
import HeadingToggle from "../HeadingToggle";
import ListConditions from "../lists/ListConditions";
import ListConditionsHashtags from "../lists/ListConditionsHashtags";
import ListNotes from "../lists/ListNotes";

export default function Notestream(props) {
  const {
    stream,
    notes,
    handleUpdateStream,
    handleUpdate,
    handleSelectNote,
    setActiveStreamId,
    color,
    users,
    workspaces,
    showWorkspace,
    hideAssignees,
  } = props;
  const isNoOptions = props.isNoOptions || stream.isNoOptions;
  const [sort, setSort] = useState({
    action: "By date (desc)",
    id: "created-desc",
  });
  const defaultEnabled = props.defaultEnabled ?? true;
  const [enabled, setEnabled] = useState(defaultEnabled);
  //Remove the server based open/close
  //action: "Rearrange streams",
  //handleSelect: () => handleRearrange(true),

  const handleEnable = () => {
    props.handleEnable ? props.handleEnable() : setEnabled(!enabled);
  };

  const options = [
    {
      action: "Edit stream",
      handleSelect: () => setActiveStreamId(stream.id),
    },
    {
      action: "Delete stream",
      handleSelect: () =>
        handleUpdateStream({ prop: "isDeleted", value: true }, stream.id),
      color: "red",
    },
  ];

  const handleSort = (action) => {
    setSort(action);
  };

  const sortActions = [
    { action: "By date (desc)", id: "created-desc" },
    { action: "By date (asc)", id: "created-asc" },
    { action: "By due date (desc)", id: "date-desc" },
    { action: "By due date (asc)", id: "date-asc" },
    { action: "By priority", id: "priority" },
    { action: "By task (incomplete)", id: "task-incomplete" },
    { action: "By task (complete)", id: "task-complete" },
  ];

  const sortOptions = sortActions.map((action) => ({
    action: action.action,
    handleSelect: () => handleSort(action),
  }));

  sortOptions.push({
    action: "Clear sort",
    handleSelect: () => setSort(false),
    color: "red",
  });

  let sortedNotes = notes;
  if (sort) {
    sortedNotes = sortNotes(sort.id, notes);
  }

  const isInModal = props.isInModal ?? false;
  return (
    <div
      className={`rounded-md ${
        isInModal ? "w-full m-auto p-4" : "w-[98%] m-auto mt-2 shadow border"
      }`}
    >
      <div className="px-2">
        <HeadingToggle
          title={stream.title}
          description={stream.description}
          enabled={enabled}
          setEnabled={() => handleEnable()}
          options={isNoOptions ? false : options}
          margin={"mt-4"}
          color={color}
          isInModal={isInModal}
        />
        <div className="pb-2 flex items-center justify-start">
          <div className="">
            {stream.conditions &&
              (stream.isHashtagStream ? (
                <ListConditionsHashtags conditions={stream.conditions} />
              ) : (
                <ListConditions
                  isHashtagStream={stream.isHashtagStream}
                  users={users}
                  conditions={stream.conditions}
                />
              ))}
          </div>
          {enabled && (
            <div className="flex items-center flex-1 justify-start pr-3 pt-2 whitespace-nowrap">
              <Dropdown
                position="left-0"
                options={sortOptions}
                icon={
                  <FunnelIcon
                    className={`h-4 w-4 ${
                      !sort
                        ? "text-slate-300 hover:text-slate-500"
                        : "text-slate-500 hover:text-slate-700"
                    }`}
                  />
                }
              />
              {sort && (
                <span className="text-slate-700 ml-1 text-xs rounded-sm mr-1">
                  {sort.action}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="overflow- bg-white">
        {enabled && (
          <>
            <ListNotes
              handleSelect={handleSelectNote}
              handleUpdate={handleUpdate}
              notes={sortedNotes}
              workspaces={workspaces}
              showWorkspace={showWorkspace}
              users={users}
              hideAssignees={hideAssignees}
            />
          </>
        )}
      </div>
    </div>
  );
}
