import React from "react";
import { useState } from "react";
import Button from "../../components/buttons/Button";
import ButtonText from "../../components/buttons/ButtonText";
import EmptyState from "../../components/shared/empty-state/EmptyState";
import Notestream from "../../components/shared/notestream/Notestream";
import { filterData, filterDataByHashtags } from "../../utils/filterData";
import { getIcon } from "../../utils/getIcons";
import { getString, getUppercase } from "../../utils/getStrings";

function WorkspaceSectionStreams(props) {
  const {
    handleUpdateHiddenStreams,
    setIsCreateOpen,
    setIsCreateNoteOpen,
    handleSelectNote,
    handleRearrange,
    isAllShowing,
    notes,
    setActiveStreamId,
    handleUpdateStream,
    handleUpdateUserStream,
    handleUpdateNote,
    users,
    asideActive,
    setAlert,
    handleClearStreams,
    tempStreamConditions,
  } = props;
  const streams = props.streams ?? [];
  const [isDefaultEnabled] = useState(notes.length <= 7);

  const allStream = {
    id: "all-workspace-home",
    conditions: [{ prop: "notes", operation: "=", value: "any" }],
    title: "All Notes",
    description: "All notes that have been taken in this workspace",
  };

  const handleOpenCreate = () => {
    if (streams.length < 1) {
      setAlert("You must have at least 1 streams to make a collection");
    } else {
      setIsCreateOpen("collection");
    }
  };

  const showAllMessage = isAllShowing ? "Hide 'All Notes'" : "Show 'All Notes'";
  const isFilter = asideActive === "filter";
  const buttonAction = isFilter ? "Save as" : "Create";
  const buttonDisabled = isFilter ? tempStreamConditions.length < 1 : false;
  const asideActiveId = isFilter ? "stream" : asideActive;
  //Go through hashtags and turn them into one stream instead of a stream for each
  return (
    <section>
      <div className={`${streams.length > 0 && "pb-2"}`}>
        <div className="flex mt-2 mr-3">
          <div className="flex-1 flex justify-start">
            <div className="ml-2">
              <ButtonText
                handleClick={() => handleClearStreams()}
                disabled={streams.length < 1}
                color={"text-red-700"}
                message={"Clear all"}
              />
            </div>
          </div>
          {asideActiveId !== "collection" && asideActiveId !== "user" && (
            <div className="mr-2">
              <Button
                icon={getIcon(asideActiveId, "h-4 w-4 mr-2")}
                message={`${buttonAction} ${getUppercase(asideActiveId)}`}
                handleClick={() => setIsCreateOpen(asideActiveId)}
                bg="violet"
                disabled={buttonDisabled}
                hoverMessage={
                  "You must have at least 1 condition in the Stream Builder to create a Stream"
                }
              />
            </div>
          )}
          <div>
            <Button
              bg={"indigo"}
              disabled={streams.length < 2}
              icon={getIcon("collection", "h-4 w-4 mr-2")}
              message={"Save as Collection"}
              handleClick={() => handleOpenCreate()}
              hoverMessage={
                "You must have at least 2 streams in the Stream View to create a Collection"
              }
            />
          </div>
        </div>
        <ul className="">
          {streams.map((stream) => {
            const filteredNotes = stream.isHashtagStream
              ? filterDataByHashtags(notes, stream.conditions)
              : filterData(notes, stream.conditions);
            return (
              <Notestream
                handleRearrange={handleRearrange}
                handleSelectNote={handleSelectNote}
                stream={stream}
                key={stream.id}
                notes={filteredNotes}
                setActiveStreamId={setActiveStreamId}
                handleUpdateStream={
                  stream.streamType === "personal"
                    ? handleUpdateUserStream
                    : handleUpdateStream
                }
                handleUpdate={handleUpdateNote}
                users={users}
                color="violet"
              />
            );
          })}
          {notes.length <= 0 ? (
            <EmptyState
              entity={"Notes"}
              message={getString("empty-note")}
              buttonMessage={"Create Note"}
              handleClick={() => setIsCreateNoteOpen(true)}
              buttonColor={"blue"}
            />
          ) : (
            isAllShowing && (
              <Notestream
                stream={allStream}
                notes={notes}
                handleSelectNote={handleSelectNote}
                handleRearrange={handleRearrange}
                setActiveStreamId={setActiveStreamId}
                handleUpdate={handleUpdateNote}
                color="violet"
                defaultEnabled={isDefaultEnabled}
                users={users}
                isNoOptions={true}
              />
            )
          )}
        </ul>
        {notes.length > 0 && (
          <div className="flex justify-end m-2">
            <div>
              <ButtonText
                message={showAllMessage}
                handleClick={() => handleUpdateHiddenStreams()}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default WorkspaceSectionStreams;
