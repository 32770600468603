import React from "react";
import Button from "../../buttons/ButtonText";

function ListMembers(props) {
  const { members } = props;
  return (
    <ul className="w-full px-2">
      {members.map((member) => (
        <li
          key={member.userId}
          className="w-full flex items-center space-x-3 border-b py-2"
        >
          <h3 className="truncate text-md font-medium text-gray-700 pl-2">
            {member.name}{" "}
            <span className="text-gray-500 font-light"> | {member.email}</span>
          </h3>
          <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
            {member.type}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default ListMembers;
