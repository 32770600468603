export const streamTemplate = {
  conditions: [],
  description: "",
  title: "New Stream",
};

export const tempStreamTemplate = (conditions) => {
  return {
    conditions: conditions,
    description: "",
    title: "New Stream",
    isFromBuilder: true,
  };
};

export const noteTemplate = {
  note: "",
  isTask: false,
  isDecision: false,
  isComplete: false,
  status: false,
  priority: false,
  hashtags: [],
  assignees: [],
  dueDate: false,
};

export const getOperationOptions = (tag) => {
  switch (tag) {
    case "dueDate":
      return ["<", ">", "="];
    case "assignees":
    case "tags":
      return ["include"];
    case "assignee":
    case "isComplete":
    case "isDecision":
    case "priority":
    case "createdInMeeting":
    case "createdInNotespace":
      return ["="];
    default:
      return [];
  }
};

export function getOperationWord(prop, operation) {
  if ((prop === "tags" || prop === "assignees") && operation === "=") {
    return "include";
  } else return operation;
}

export function getValueWord(prop, value, users, notespaces, meetings) {
  if (prop === "assignee" || prop === "assignees") {
    value = users.find((item) => item.userId === value).name;
  }
  if (prop === "dueDate") {
    value = value + " days from now";
  }
  if (prop === "createdIn") {
    value = notespaces.find((item) => item.notespaceId === value).title;
  }
  if (prop === "meeting") {
    value = meetings.find((item) => item.id === value).title;
  }
  return value;
}
