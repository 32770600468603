import { Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/landing-page/LandingPage";
import LoginPage from "./pages/login-page/LoginPage";
import UserDashboard from "./pages/user-dashboard/UserDashboard";
import WorkspaceDashboard from "./pages/workspace-dashboard/WorkspaceDashboard";

function App() {
  return (
    <div className="App min-full-height">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user" element={<UserDashboard />} />
        <Route path="/workspace/:workspaceId" element={<WorkspaceDashboard />} />
      </Routes>
    </div>
  );
}

export default App;

//<EventListener target="window" onKeyDown={(e) => handleKeyDown(e)} />
