import React from "react";
import Avatar from "../../components/avatars/Avatar";
import AvatarBlank from "../../components/avatars/AvatarBlankCalendar";
import DateChip from "../../components/shared/chips/ChipDate";
import DecisionChip from "../../components/shared/chips/ChipDecision";
import SectionHeading from "../../components/shared/SectionHeading";

function NotespaceSectionDecisions(props) {
  const { notes, users, handleSelect } = props;
  const decisions = notes.filter((item) => item.isDecision);
  return (
    <section>
      <SectionHeading
        heading={"Our Decision Log"}
        subheading={`Track and review all decision made within the space`}
      />
      <div>
        <ol className="mt-4 text-sm leading-6 text-gray-500 max-w-6xl m-auto">
          {decisions.map((task, i) => (
            <DecisionListItem
              key={task.id}
              task={task}
              users={users}
              handleSelect={handleSelect}
              isStriped={i % 2 === 0}
            />
          ))}
        </ol>
      </div>
    </section>
  );
}

export default NotespaceSectionDecisions;

const DecisionListItem = ({ task, users, handleSelect, isStriped }) => {
  let assignees = [];
  if (task.assignees.length > 0) {
    assignees = task.assignees.map((assigneeId) =>
      users.find((user) => user.userId === assigneeId)
    );
  }
  return (
    <li
      onClick={() => handleSelect(task.id)}
      className={`pointer flex flex-wrap items-center space-x-4 rounded-xl py-2 px-4 focus-within:bg-gray-100 hover:bg-gray-100 ${
        isStriped && "bg-slate-50"
      }`}
    >
      <div className="flex items-center flex-1">
        <div className="flex justify-center items-center">
          <div
            className={`flex justify-center items-center mr-2 ${
              assignees.length > 0 && "pl-3"
            }`}
          >
            {assignees.length > 0 ? (
              assignees.map((assignee) => {
                return (
                  <Avatar
                    key={assignee.userId}
                    name={assignee.name}
                    margin={"ring-2 ring-white ml-[-1em]"}
                  />
                );
              })
            ) : (
              <AvatarBlank />
            )}
          </div>
        </div>
        <div className="text-left flex-1">
          <h3 className="text-sm font-medium text-gray-900 truncate">
            {task.note}
          </h3>
          {task.description && (
            <p className="text-xs text-gray-500 truncate">{task.description}</p>
          )}
        </div>
      </div>
      <div className="flex items-center min-w-xl p-2">
        {task.createdTimestamp && (
          <div>
            <DateChip action={"Decision made"} date={task.dueDate} />
          </div>
        )}
        <div>
          <DecisionChip />
        </div>
      </div>
    </li>
  );
};
