import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useState } from "react";

function ListConditionItem(props) {
  const { prop, operation, value, handleClear, index, suffix } = props;
  const [hover, setHover] = useState(false);

  const bg = hover ? "bg-red-100" : "bg-slate-100";
  const color = hover ? "text-red-700" : "text-slate-700";
  return (
    <>
      <li
        className={`py-1 pl-5 ${
          handleClear ? "pr-2" : "pr-5"
        } mx-1 mt-2 text-xs rounded flex items-center justify-center ${bg} ${color}`}
      >
        {`${prop} ${operation} `}
        <span className="font-bold italic ml-1">{value} </span>
        {handleClear && (
          <button
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => handleClear(index)}
            aria-label={"Clear condition"}
            className="ml-2"
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        )}
      </li>
      {suffix && <span className="text-gray-500 text-xs mt-3">{suffix}</span>}
    </>
  );
}

export default ListConditionItem;
