import { CalendarIcon, HandThumbUpIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Button from "../../components/buttons/Button";
import ButtonText from "../../components/buttons/ButtonText";
import InputSingle from "../../components/inputs/InputSingle";
import InputTextarea from "../../components/inputs/InputTextarea";
import ActionPanelDelete from "../../components/shared/action-panels/ActionPanelDelete";
import Dropdown from "../../components/shared/dropdowns/Dropdown";
import SettingsHeading from "../../components/shared/headings/HeadingSettings";
import ModalCreateHashtag from "../../components/shared/modals/ModalCreateHashtag";
import ModalUpdateHashtag from "../../components/shared/modals/ModalUpdateHashtag";
import SectionHeading from "../../components/shared/SectionHeading";
import SettingsToggle from "../../components/shared/settings-items/SettingsToggle";
import { checkIfStringIsValid } from "../../utils/checkStrings";
import { getString } from "../../utils/getStrings";

function WorkspaceSectionSettings(props) {
  const {
    workspace,
    handleDelete,
    handleUpdate,
    hashtags,
    handleUpdateHashtag,
    handleCreateHashtag,
  } = props;
  const [title, setTitle] = useState(workspace.title);
  const [description, setDescription] = useState(workspace.description);
  const [selectedHashtag, setSelectedHashtag] = useState(false);
  const [seeAllHashtags, setSeeAllHashtags] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = checkIfStringIsValid(title);
    if (isValid) {
      props.handleUpdateForm({ title, description });
    } else {
      alert("Please enter a valid title");
    }
    props.handleUpdateForm({ title, description });
  };

  const handleSubmitHashtag = (obj, id, isFull) => {
    if (isFull) {
      return handleUpdateHashtag(obj, id, isFull);
    } else {
      return handleUpdateHashtag(obj, id, false);
    }
  };

  const isSubmitDisabled =
    (title === workspace.title && description === workspace.description) ||
    title.trim() === "";

  let filteredHashtags = hashtags;
  if (filteredHashtags.length > 3) {
    filteredHashtags = !seeAllHashtags ? hashtags.slice(0, 5) : hashtags;
  }

  return (
    <>
      <ModalCreateHashtag
        isOpen={isCreateOpen === "hashtag"}
        handleClose={() => setIsCreateOpen(false)}
        handleCreate={handleCreateHashtag}
        hashtags={hashtags}
      />
      <ModalUpdateHashtag
        isOpen={selectedHashtag !== false}
        handleClose={() => setSelectedHashtag(false)}
        hashtag={selectedHashtag}
        handleUpdate={handleSubmitHashtag}
      />
      <section>
        <SectionHeading
          heading={"Our Settings"}
          subheading={`Configure your workspace settings to customize your team's experience`}
        />
        <div className="max-w-3xl m-auto px-2 py-5">
          <form onSubmit={(e) => handleSubmit(e)} className="mb-5">
            <InputSingle
              placeholder={"Workspace title"}
              label={"Workspace title"}
              name={"workspace-title"}
              value={title}
              setValue={setTitle}
              margin={"my-5"}
            />
            <InputTextarea
              placeholder={"Workspace description"}
              label={"Workspace description"}
              name={"workspace-description"}
              value={description}
              setValue={setDescription}
              margin={"my-5"}
            />
            <div className="flex justify-end">
              <div>
                <Button
                  type="submit"
                  message={"Update"}
                  disabled={isSubmitDisabled}
                />
              </div>
            </div>
          </form>
          <>
            <SettingsHeading heading={"Hashtags"} />
            {hashtags.length > 0 && (
              <div className="striped">
                {filteredHashtags.map((hashtag, i) => (
                  <HashtagMenuItem
                    key={hashtag.id}
                    hashtag={hashtag}
                    handleSelect={() => setSelectedHashtag(hashtag)}
                    handleUpdate={handleUpdateHashtag}
                    index={i}
                  />
                ))}
                {hashtags.length > 3 && (
                  <ButtonText
                    message={seeAllHashtags ? "See fewer" : "See all"}
                    handleClick={() => setSeeAllHashtags(!seeAllHashtags)}
                  />
                )}
              </div>
            )}
            <div className="flex justify-end pt-2">
              <div>
                <Button
                  bg={"indigo"}
                  message={"Create Hashtag"}
                  handleClick={() => setIsCreateOpen("hashtag")}
                />
              </div>
            </div>
          </>
          <>
            <SettingsHeading heading={"Views"} />
            <div>
              <SettingsToggle
                description={getString("settings-decision-log-view")}
                heading={"Decision Log"}
                handleUpdate={handleUpdate}
                prop={"isDecisionLogView"}
                enabled={workspace.isDecisionLogView}
                icon={
                  <HandThumbUpIcon className="h-6 w-6 text-slate-600 mr-4" />
                }
              />
              <SettingsToggle
                description={getString("settings-calendar-view")}
                heading={"Task Calendar"}
                handleUpdate={handleUpdate}
                prop={"isCalendarView"}
                enabled={workspace.isCalendarView}
                icon={<CalendarIcon className="h-6 w-6 text-slate-600 mr-4" />}
              />
            </div>
          </>
          <div className="p-2 text-left">
            <ActionPanelDelete
              handleDelete={() => handleDelete()}
              entity={"workspace"}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default WorkspaceSectionSettings;

const HashtagMenuItem = ({ hashtag, handleSelect, handleUpdate, index }) => {
  const options = [
    {
      action: "Edit hashtag",
      handleSelect: () => handleSelect(hashtag.id),
    },
    {
      action: "Delete hashtag",
      handleSelect: () =>
        handleUpdate({ prop: "isDeleted", value: true }, hashtag.id),
      color: "red",
    },
  ];
  const bg = index % 2 === 1 ? "bg-white" : "bg-slate-50";
  return (
    <div className={`px-5 py-2 text-sm text-gray-700 flex ${bg}`}>
      <div className="flex-1 text-left">
        <h3 className="font-medium">{hashtag.title}</h3>
        <p className="text-gray-500 text-sm">{hashtag.description}</p>
      </div>
      <Dropdown options={options} />
    </div>
  );
};
