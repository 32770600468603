import React from "react";
import Button from "../../buttons/ButtonText";

function ListInvite(props) {
  const { invites, handleCancel } = props;
  return (
    <ul className="w-full px-2">
      {invites.map((invite) => {
        const cancelObj = {
          inviteId: invite.inviteId,
          invitedEmail: invite.invited,
          status: "cancelled",
        };
        return (
          <li
            key={invite.inviteId}
            className="w-full flex items-center space-x-3 border-b"
          >
            <h3 className="truncate text-md text-gray-700 pl-2">
              {invite.invited}
            </h3>
            <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
              {invite.type}
            </span>
            <div className="flex-1 flex justify-end pr-2">
              <div className="flex">
                <Button message="Resend" />
                <Button
                  handleClick={() => handleCancel(cancelObj)}
                  color="text-red-700"
                  message="Cancel"
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default ListInvite;
