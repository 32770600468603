import React from "react";

function Button(props) {
  const { message, handleClick, disabled, icon } = props;
  const type = props.type ?? "button";
  const opacity = disabled ? "opacity-20" : "";
  const color = props.color ?? "text-gray-500";
  return (
    <button
      aria-label={props.label ?? message}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={`flex w-full items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 bg-none hover:bg-slate-100 ${color} ${opacity}`}
    >
      {icon && icon}
      {message}
    </button>
  );
}

export default Button;
