import { CalendarIcon, HandThumbUpIcon } from "@heroicons/react/20/solid";
import {
  BookOpenIcon,
  BriefcaseIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  CogIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export function getNaviagtionItems(page, views) {
  let allViews = views;
  if (!views) {
    allViews = {};
  }
  const { isCalendarView, isDecisionLogView } = allViews;
  switch (page) {
    case "user":
      return [
        { name: "Spaces", icon: BriefcaseIcon, id: "pages" },
        { name: "Tasks", icon: CheckCircleIcon, id: "tasks" },
        { name: "Info", icon: UserIcon, id: "info" },
      ];
    case "workspace":
      return [
        { name: "Streams", icon: ChatBubbleBottomCenterIcon, id: "streams" },
        {
          name: "Notespaces",
          icon: BookOpenIcon,
          id: "pages",
          //isHidden: !isNotespacesFeatureEnabled,
          isHidden: true,
        },
        {
          name: "Calendar",
          icon: CalendarIcon,
          id: "calendar",
          isHidden: !isCalendarView,
        },
        {
          name: "Decisions",
          icon: HandThumbUpIcon,
          id: "decision",
          isHidden: !isDecisionLogView,
        },
        //{ name: "Meetings", icon: ChatBubbleLeftRightIcon, id: "meetings" },
        { name: "Team", icon: UsersIcon, id: "team" },
        { name: "Settings", icon: CogIcon, id: "settings" },
      ];
    case "meetingspace":
      return [
        {
          name: "Streams",
          icon: ChatBubbleBottomCenterIcon,
          id: "streams",
        },
        {
          name: "Meetings",
          icon: ChatBubbleLeftRightIcon,
          id: "meetings",
        },
        {
          name: "Calendar",
          icon: CalendarIcon,
          id: "calendar",
          isHidden: !isCalendarView,
        },
        {
          name: "Decisions",
          icon: HandThumbUpIcon,
          id: "decision",
          isHidden: !isDecisionLogView,
        },
        { name: "Settings", icon: CogIcon, id: "settings" },
      ];
    case "notespace":
      return [
        {
          name: "Streams",
          icon: ChatBubbleBottomCenterIcon,
          id: "streams",
        },
        {
          name: "Calendar",
          icon: CalendarIcon,
          id: "calendar",
          isHidden: !isCalendarView,
        },
        {
          name: "Decisions",
          icon: HandThumbUpIcon,
          id: "decision",
          isHidden: !isDecisionLogView,
        },
        { name: "Settings", icon: CogIcon, id: "settings" },
      ];
    default:
      return null;
  }
}
