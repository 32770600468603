import { BriefcaseIcon, FlagIcon } from "@heroicons/react/20/solid";
import React from "react";
import { getPriorityColor } from "../../../utils/getColor";
import CompleteButton from "../../buttons/ButtonComplete";
import DateChip from "../chips/ChipDate";
import ChipDecision from "../chips/ChipDecision";
import TimeAgo from "react-timeago";
import Avatar from "../../avatars/Avatar";

function NoteItem(props) {
  const {
    note,
    isLast,
    handleSelect,
    handleUpdate,
    isStriped,
    workspace,
    showWorkspace,
    users,
    hideAssignees,
  } = props;
  const priorityColors = getPriorityColor(note.priority);
  const hashtags = note.hashtags.map((hashtag) => (
    <span className="text-indigo-700 p-1 bg-indigo-100 text-xs rounded-sm mr-1 px-2">
      {hashtag}
    </span>
  ));
  const border = isLast ? "" : "border-b";
  const isContext = hashtags.length > 0 || note.priority || note.isDecision;
  const bg = isStriped ? "bg-slate-50" : "bg-white";
  return (
    <li
      onClick={() => handleSelect(note.id)}
      role="button"
      aria-label="Select note"
      className={`p-2 text-gray-700 flex items-center text-sm hover:bg-slate-100 overflow-hidden ${border} ${bg}`}
    >
      {note.isTask && (
        <CompleteButton
          isComplete={note.isComplete}
          handleUpdate={handleUpdate}
          noteId={note.id}
          note={note}
        />
      )}
      <p className="whitespace-nowrap">
        <span className="truncate">{note.note} </span>
        {hashtags && (
          <>
            {isContext && <span className="text-gray-300 mr-1">|</span>}
            {hashtags}
          </>
        )}
      </p>
      {note.priority && (
        <span
          className={`${priorityColors.bg} p-1 text-xs rounded-sm mr-1 px-2 inline-flex items-center`}
        >
          <FlagIcon className={`w-3 h-3 mr-1 ${priorityColors.color}`} />
          <span className={priorityColors.color}>{note.priority}</span>
        </span>
      )}
      {note.isDecision && <ChipDecision />}
      {workspace && showWorkspace && (
        <span
          className={`p-1 text-xs text-slate-500 rounded-sm mr-1 px-2 inline-flex items-center`}
        >
          <BriefcaseIcon className={`w-3 h-3 mr-1`} />
          <span>{workspace.title}</span>
        </span>
      )}
      {note.assignees?.length > 0 &&
        !hideAssignees &&
        note.assignees.map((assigneeId) => {
          const assignee = users.find((user) => user.userId === assigneeId);
          return (
            <Avatar
              isMultiple={note.assignees.length > 1}
              textSize={"text-xs"}
              size={"h-5 w-5"}
              name={assignee?.name}
            />
          );
        })}
      <div className="flex-1 flex justify-end">
        {note.dueDate && <DateChip date={note.dueDate} />}
      </div>
      <div className="text-gray-500 text-xs whitespace-nowrap truncate">
        <TimeAgo date={note.createdTimestamp} />
      </div>
    </li>
  );
}

export default NoteItem;
