import React from "react";
import { CallToAction } from "./components/CallToAction";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Footer } from "./components/Footer";
import { useNavigate } from "react-router";

function LandingPage() {
  const isSignedIn = false;
  const headerButtonMessage = isSignedIn ? "Go to Home" : "Log in";
  const navigate = useNavigate();
  const headerButtonFunction = () => navigate("/login");
  return (
    <>
      <div className="bg-slate-50">
        <Header
          buttonMessage={headerButtonMessage}
          handleSignIn={headerButtonFunction}
        />
        <Hero />
        <CallToAction />
        {/* <SecondaryFeatures /> */}
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
