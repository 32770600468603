import {
  CalendarIcon,
  CheckCircleIcon,
  FlagIcon,
  HandThumbUpIcon,
  HashtagIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { getPriorityColor } from "../../../utils/getColor";
import Button from "../../buttons/ButtonToolbar";
import ButtonDate from "../../buttons/ButtonToolbarDate";
import Dropdown from "../../buttons/ButtonToolbarDropdown";
import "react-calendar/dist/Calendar.css";

function Toolbar(props) {
  const { handleToggle, handleToggleSet } = props;
  const note = props.note ? props.note : "";
  const users = props.users ? props.users : [];
  const tags = props.tags ? props.tags : [];
  const isTask = note.includes("--task");
  const isDecision = note.includes("--decision");
  const dueDate = note.includes("-d-");
  const priority = note.includes("!!")
    ? note.split("!!")[1].split(" ")[0]
    : false;

  const assignees = note.includes("@@");

  const words = note.split(" ");
  let dueDateTimestamp = words.find((word) => word.includes("-d-"));
  if (dueDateTimestamp) {
    dueDateTimestamp = dueDateTimestamp.split("-d-")[1];
  }

  const priorityOptions = [
    { label: "High", value: "!!high" },
    { label: "Medium", value: "!!medium" },
    { label: "Low", value: "!!low" },
  ];

  const userOptions = users.map((user) => ({
    label: user.name,
    value: `@@${user.tag}`,
  }));

  const tagOptions = tags.map((tag) => ({
    label: tag,
    value: `${tag}`,
  }));

  const hasHashtag = note.includes("#");

  return (
    <div className="flex justify-center">
      <Button
        handleClick={() => handleToggle("--task")}
        icon={
          <CheckCircleIcon
            className={`lg:mr-2 h-5 w-5 ${
              isTask ? "text-green-700" : "text-gray-400"
            } `}
          />
        }
        message={"Is a task"}
        isHighlighted={isTask}
        highlightColor={"text-green-700"}
      />
      <Button
        handleClick={() => handleToggle("--decision")}
        isMiddle
        icon={
          <HandThumbUpIcon
            className={`lg:mr-2 h-5 w-5 ${
              isDecision ? "text-violet-700" : "text-gray-400"
            } `}
          />
        }
        message={"Is a decision"}
        isHighlighted={isDecision}
        highlightColor={"text-violet-700"}
      />
      <ButtonDate
        isMiddle
        icon={
          <CalendarIcon
            className={`lg:mr-2 h-5 w-5 ${
              dueDate ? "text-indigo-700" : "text-gray-400"
            } `}
          />
        }
        handleSelect={handleToggleSet}
        message={dueDate ? dueDateTimestamp : "Set due date"}
        isHighlighted={dueDate}
        highlightColor={"text-indigo-700"}
        label={"Set due date"}
      />
      <Dropdown
        isMiddle
        icon={
          <FlagIcon
            className={`lg:mr-2 h-5 w-5 ${
              priority ? getPriorityColor(priority).color : "text-gray-400"
            } `}
          />
        }
        handleSelect={handleToggleSet}
        options={priorityOptions}
        message={priority ? priority : "Set priority"}
        isHighlighted={priority}
        highlightColor={getPriorityColor(priority).color}
      />
      <Dropdown
        isMiddle
        icon={
          <UserCircleIcon
            className={`lg:mr-2 h-5 w-5 ${
              assignees ? "text-teal-600" : "text-gray-400"
            } `}
          />
        }
        message={assignees ? "Set assignees" : "Set assignees"}
        options={userOptions}
        handleSelect={handleToggle}
        isHighlighted={assignees}
        highlightColor={"text-teal-600"}
      />
      <Dropdown
        isRight
        icon={
          <HashtagIcon
            className={`lg:mr-2 h-5 w-5 ${
              hasHashtag ? "text-violet-600" : "text-gray-400"
            } `}
          />
        }
        message={hasHashtag ? "Set hashtags" : "Set hashtags"}
        options={tagOptions}
        handleSelect={handleToggle}
        isHighlighted={hasHashtag}
        highlightColor={"text-violet-600"}
      />
    </div>
  );
}

export default Toolbar;

/*

<Dropdown
        isRight
        icon={
          <BriefcaseIcon
            className={`mr-2 h-5 w-5 ${
              status ? "text-orange-700" : "text-gray-400"
            } `}
          />
        }
        message={status ? status : "Set status"}
        options={statusOptions}
        handleSelect={handleToggleSet}
        isHighlighted={status}
        highlightColor={"text-orange-700"}
      />

      const status = note.includes("//")
    ? note.split("//")[1].split(" ")[0]
    : false;

      const statusOptions = [
    { label: "To-do", value: "//to-do" },
    { label: "In-progress", value: "//in-progress" },
    { label: "Done", value: "//done" },
  ];
*/
