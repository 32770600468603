import React from "react";
import NoteItem from "../notes/NoteListViewItem";

function ListNotes(props) {
  const { notes, handleUpdate, handleSelect, showWorkspace, hideAssignees } = props;
  const workspaces = props.workspaces ?? [];
  const users = props.users ?? [];
  return (
    <ul className="divide-y divide-gray-200">
      {notes.map((note, i) => {
        const isStriped = i % 2 === 0;
        const isWorkspace = workspaces.some(
          (item) => item.workspaceId === note.workspaceId
        );
        let workspace = false;
        if (isWorkspace) {
          workspace = workspaces.find((item) => item.workspaceId === note.workspaceId);
        }
        return (
          <NoteItem
            handleSelect={handleSelect}
            handleUpdate={handleUpdate}
            key={note.id}
            note={note}
            isLast={true}
            isStriped={isStriped}
            workspace={workspace}
            showWorkspace={showWorkspace}
            users={users}
            hideAssignees={hideAssignees}
          />
        );
      })}
    </ul>
  );
}

export default ListNotes;
