import { UsersIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";
import ActionPanel from "../../components/shared/action-panels/ActionPanel";
import EmptyState from "../../components/shared/empty-state/EmptyState";
import SectionHeading from "../../components/shared/SectionHeading";

function UserSectionSpace(props) {
  const { setIsCreateOpen, workspaces, invites, handleAcceptInvite } = props;
  return (
    <section>
      <SectionHeading
        color={"blue"}
        heading={"My Workspaces"}
        subheading={"Create and manage your workspaces"}
        buttonMessage={"Create Workspace"}
        handleClick={() => setIsCreateOpen("workspace")}
      />
      {invites &&
        invites.map((invite) => {
          const acceptObj = {
            inviteId: invite.id,
            workspaceId: invite.workspaceId,
            type: invite.type,
            workspaceTitle: invite.workspaceTitle,
          };
          return (
            <ActionPanel
              key={invite.id}
              heading={`You've been invited to join ${invite.workspaceTitle} 🎉`}
              message={`Amazing news! ${invite.inviter.name} wants you to join ${invite.workspaceTitle}.`}
              action={"Accept Invite"}
              reject={"Reject"}
              handleClick={() => handleAcceptInvite(acceptObj)}
            />
          );
        })}
      {workspaces.length > 0 ? (
        <ul className="">
          {workspaces.map((workspace) => (
            <li
              className="w-100 text-left p-5 text-gray-700 border-b border-b-slate-100 hover:bg-slate-100"
              key={workspace.workspaceId}
            >
              <Link to={`/workspace/${workspace.workspaceId}`}>
                <div className="ml-5 flex items-center">
                  <UsersIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {workspace.title}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <EmptyState
          handleClick={() => setIsCreateOpen("workspace")}
          entity={"Workspaces"}
          message={"Create a workspace to get started."}
          buttonMessage={"Create Workspace"}
          buttonColor={"blue"}
        />
      )}
    </section>
  );
}

export default UserSectionSpace;
