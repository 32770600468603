import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InputRadio(props) {
  const {
    info,
    infoLink,
    options,
    value,
    setValue,
    label,
    title,
    selectionInfo,
  } = props;

  const margin = props.margin ?? "m-0";

  return (
    <div className={margin}>
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-medium text-gray-900">{title}</h2>
        {info && (
          <a
            href={infoLink}
            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
          >
            {info}
          </a>
        )}
      </div>

      <RadioGroup value={value} onChange={setValue} className="mt-2">
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
        <div className="grid grid-cols-2 gap-1">
          {options.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className={({ active, checked }) =>
                classNames(
                  !option.isNotAvailable
                    ? "cursor-pointer focus:outline-none"
                    : "opacity-25 cursor-not-allowed",
                  active ? "ring-2 ring-offset-2 ring-violet-500" : "",
                  checked
                    ? "bg-violet-100 hover:bg-violet-100 text-violet-800"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-100",
                  "text-xs rounded-md p-2 flex items-center justify-center uppercase sm:flex-1"
                )
              }
              disabled={option.isNotAvailable}
            >
              <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {selectionInfo && (
        <p className="text-sm text-gray-500 mt-2">{selectionInfo}</p>
      )}
    </div>
  );
}
