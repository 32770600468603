import { db, firebase } from "../firebase";

export function updateUser(userId, obj) {
  const { prop, value } = obj;
  return db
    .collection("users")
    .doc(userId)
    .update({
      [prop]: value,
    });
}

export function updateUserForm(userId, obj) {
  const { name, bio } = obj;
  const updateMessage = `Updated name = ${name}. Updated bio = ${bio}`;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
  };
  return db
    .collection("users")
    .doc(userId)
    .update({
      name,
      bio,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}
