import { db, firebase } from "../firebase";

export function createNote(workspaceId, notespaceId, meetingId, userInfo, obj) {
  const {
    note,
    hashtags,
    assignees,
    dueDate,
    description,
    priority,
    isTask,
    isDecision,
  } = obj;
  const creator = {
    userRef: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const assigneeIds = assignees.map((assignee) => assignee.id);
  return db
    .collection("notes")
    .add({
      note,
      hashtags,
      assignees: assigneeIds,
      description,
      isComplete: false,
      dueDate,
      priority,
      isTask,
      isDecision,
      status: "to-do",
      workspaceId,
      notespaceId,
      meetingId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    })
    .then(() => {
      const assigneesToGetEmail = assignees.filter(
        (assignee) => assignee.id !== userInfo.id
      );
      if (assigneesToGetEmail.length > 0) {
        return createAssignedNoteEmailBatch(userInfo, note, assignees);
      } else {
        return true;
      }
    });
}

export function updateNote(noteId, userInfo, obj, note) {
  const { prop, value } = obj;
  const userId = userInfo.id;
  const updateMessage = obj.updateMessage ?? value;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  const isDifferentUpdater = note.creator.userRef !== userInfo.email;
  const propValue = prop === "isComplete" && value ? "complete" : "incomplete";
  return db
    .collection("notes")
    .doc(noteId)
    .update({
      [prop]: value,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    })
    .then(() => {
      if (isDifferentUpdater) {
        return db.collection("mail").add({
          to: note.creator.userRef,
          message: {
            subject: `${userInfo.name} has updated the status of a task you created in SamePage`,
            html: `<h1>Your note: ${note.note} has just been marked as ${propValue}</h1><p>${prop} --> ${value}</p><p>Visit SamePage now to check it out.</p><a href="https://www.samepage.tech">Click me to go to SamePage</a></p>`,
          },
        });
      } else {
        return true;
      }
    });
}

export function updateNoteFull(noteId, userInfo, obj) {
  const userId = userInfo.id;
  const updateMessage = "Note updated";
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  const isDifferentUpdater = obj.creator.userRef !== userInfo.email;
  const assigneeIds = obj.assignees.map((assignee) => assignee.id);
  return db
    .collection("notes")
    .doc(noteId)
    .update({
      ...obj,
      assignees: assigneeIds,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    })
    .then(() => {
      if (isDifferentUpdater) {
        return db.collection("mail").add({
          to: obj.creator.userRef,
          message: {
            subject: `${userInfo.name} has updated your note in SamePage`,
            html: `<h1>Your note: ${obj.note} has just been updated</h1><p>Visit SamePage now to check it out.</p><a href="https://www.samepage.tech">Click me to go to SamePage</a></p>`,
          },
        });
      } else {
        return true;
      }
    });
}

export function createAssignedNoteEmailBatch(userInfo, note, array) {
  //Create workspaceStream in WorkspaceService
  const batch = db.batch();
  array.forEach((assignee) => {
    var docRef = db.collection("mail").doc();
    const doc = {
      to: assignee.email,
      message: {
        subject: `${userInfo.name} has assigned a note to you in SamePage`,
        html: `<h1>You've been assigned to a note: ${note}</h1><p>Visit SamePage now to check it out.</p><a href="https://www.samepage.tech">Click me to go to SamePage</a></p>`,
      },
    };
    batch.set(docRef, doc);
  });
  return batch.commit();
}
